import { Actions, Text } from '../../../components/organisms/Tables/custom-columns';
import { ActionsTypes, AssessmentTag, ColumnProps, FilterProps } from '../../../interfaces';

export const columns: ColumnProps[] = [
  {
    accessor: 'pocName',
    sizing: { size: 15, minSize: 180 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 15 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'accountId',
    sizing: { size: 12, minSize: 140 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 12 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'salesAgency',
    sizing: { size: 13, minSize: 160 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 13 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'salesArea',
    sizing: { size: 13, minSize: 160 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 13 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'customerType',
    sizing: { size: 13, minSize: 180 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 13 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'creditLimit',
    sizing: { size: 12, minSize: 180 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="currency" params={{ value, sizing: { size: 12 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'term',
    sizing: { size: 8, minSize: 60 },
    sorting: { has: true },
    render: (value: string) => <Text type="days" params={{ value, sizing: { size: 8 } }} />,
  },
  {
    accessor: 'fee',
    sizing: { size: 8, minSize: 60 },
    sorting: { has: true },
    render: (value: string) => <Text type="fee" params={{ value, sizing: { size: 8 } }} />,
  },
  {
    accessor: 'recommendation',
    sizing: { size: 12, minSize: 140 },
    sorting: { has: false },
    render: (value: string) => <Text type="text" params={{ value, sizing: { size: 12 } }} />,
  },
  {
    accessor: 'score',
    sizing: { size: 10, minSize: 60 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 10 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'updatedAt',
    sizing: { size: 12, minSize: 200 },
    sorting: { has: true },
    render: ({ value, type }) => (
      <Text type="datetime" params={{ value, type, sizing: { size: 12 } }} />
    ),
  },
  {
    accessor: 'actions',
    sizing: { size: 12, minSize: 200 },
    sorting: { has: false },
    permissions: [
      'actions.recommendation.deny',
      'actions.recommendation.approve',
      'actions.recommendation.edit',
    ],
    render: ({ tag, selected, callbackFn }) => (
      <Actions
        data={{ selected, callbackFn }}
        type={
          tag === AssessmentTag.RECOMMENDED
            ? ActionsTypes.PENDING_RECOMMENDED
            : ActionsTypes.PENDING_NOT_RECOMMENDED
        }
      />
    ),
  },
];

export const filters: FilterProps[] = [
  {
    type: 'range-value',
    identifier: 'creditLimit',
    name: 'tables.filters.inputs.creditLimit.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'currency',
        identifier: 'min',
        label: 'tables.filters.inputs.creditLimit.min.label',
        placeholder: 'tables.filters.inputs.creditLimit.min.placeholder',
        affix: {
          type: 'currency',
          prefix: true,
          sufix: false,
        },
      },
      {
        type: 'text',
        format: 'currency',
        identifier: 'max',
        label: 'tables.filters.inputs.creditLimit.max.label',
        placeholder: 'tables.filters.inputs.creditLimit.max.placeholder',
        affix: {
          type: 'currency',
          prefix: true,
          sufix: false,
        },
      },
    ],
  },
  {
    type: 'range-value',
    identifier: 'term',
    name: 'tables.filters.inputs.term.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'days',
        identifier: 'min',
        label: 'tables.filters.inputs.term.min.label',
        placeholder: 'tables.filters.inputs.term.min.placeholder',
        affix: {
          type: 'text',
          prefix: false,
          sufix: true,
          label: 'tables.filters.inputs.term.min.sufix',
        },
      },
      {
        type: 'text',
        format: 'days',
        identifier: 'max',
        label: 'tables.filters.inputs.term.max.label',
        placeholder: 'tables.filters.inputs.term.max.placeholder',
        affix: {
          type: 'text',
          prefix: false,
          sufix: true,
          label: 'tables.filters.inputs.term.max.sufix',
        },
      },
    ],
  },
  {
    type: 'range-value',
    identifier: 'fee',
    name: 'tables.filters.inputs.fee.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'percentage',
        identifier: 'min',
        label: 'tables.filters.inputs.fee.min.label',
        placeholder: 'tables.filters.inputs.fee.min.placeholder',
        affix: {
          type: 'percentage',
          prefix: false,
          sufix: true,
        },
      },
      {
        type: 'text',
        format: 'percentage',
        identifier: 'max',
        label: 'tables.filters.inputs.fee.max.label',
        placeholder: 'tables.filters.inputs.fee.max.placeholder',
        affix: {
          type: 'percentage',
          prefix: false,
          sufix: true,
        },
      },
    ],
  },
  {
    type: 'range-value',
    identifier: 'score',
    name: 'tables.filters.inputs.score.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'score',
        identifier: 'min',
        label: 'tables.filters.inputs.score.min.label',
        placeholder: 'tables.filters.inputs.score.min.placeholder',
      },
      {
        type: 'text',
        format: 'score',
        identifier: 'max',
        label: 'tables.filters.inputs.score.max.label',
        placeholder: 'tables.filters.inputs.score.max.placeholder',
      },
    ],
  },
  {
    type: 'checkbox',
    name: 'tables.filters.inputs.recommendation.name',
    identifier: 'recommendation',
    title: 'tables.filters.inputs.recommendation.title',
    input: [
      {
        type: 'checkbox',
        format: 'text',
        identifier: 'creditOffer',
        label: 'tables.filters.inputs.recommendation.labels.creditOffer',
        value: 'CREDIT_OFFER',
      },
      {
        type: 'checkbox',
        format: 'text',
        identifier: 'creditIncrease',
        label: 'tables.filters.inputs.recommendation.labels.creditIncrease',
        value: 'CREDIT_INCREASE',
      },
      {
        type: 'checkbox',
        format: 'text',
        identifier: 'creditDecrease',
        label: 'tables.filters.inputs.recommendation.labels.creditDecrease',
        value: 'CREDIT_DECREASE',
      },
    ],
  },
  {
    type: 'range-date',
    identifier: 'updatedAt',
    name: 'tables.filters.inputs.updatedAt.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'text',
        identifier: 'startDate',
        label: 'tables.filters.inputs.updatedAt.min.label',
        placeholder: 'tables.filters.inputs.updatedAt.min.placeholder',
      },
      {
        type: 'text',
        format: 'text',
        identifier: 'endDate',
        label: 'tables.filters.inputs.updatedAt.max.label',
        placeholder: 'tables.filters.inputs.updatedAt.max.placeholder',
      },
    ],
  },
];
