import React, { useMemo } from 'react';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { FeatureToggleProps } from './FeatureToggle.types';

export const FeatureToggle: React.FC<FeatureToggleProps> = ({ keys, children, type = 'every' }) => {
  const { toggles } = useGetUserPreferences();

  const hasToggle = useMemo(
    () => ({
      some: keys.some((key) => toggles.has(key)),
      every: keys.every((key) => toggles.has(key)),
    }),
    [keys, toggles, type]
  );

  if (typeof children === 'function') {
    return children(hasToggle[type]);
  }

  return hasToggle[type] ? <React.Fragment>{children}</React.Fragment> : null;
};
