import { SearchField } from '@hexa-ui/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { excludeManagement } from '../../../constants';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../hooks/useRequester';
import { Tables } from '../../../interfaces';
import { getManagementSummary } from '../../../services';
import { ManagementTable } from '../../organisms/Tables/Management/ManagementTable';
import { ManagementProvider } from './Context/ManagementContext';
import {
  Container,
  Content,
  List,
  ManagementPageContainer,
  Root,
  SearchFieldContainer,
  Trigger,
} from './styles';

export const ManagementPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const { permissions } = useGetUserPreferences();
  const { get } = useRequester();

  const defaultTab = Tables.PROCESSING;

  const [search, setSearch] = useState<string>('');
  const [tab, setTab] = useState<Tables>(defaultTab);
  const [tabCounters, setTabCounters] = useState({
    [Tables.ACTIVE]: '0',
    [Tables.BLOCKED]: '0',
    [Tables.CANCELED]: '0',
    [Tables.PROCESSING]: '0',
  });

  const tablesMapping = useMemo(
    () => Object.values(Tables).filter((table) => !excludeManagement.includes(table)),
    [Tables, permissions]
  );

  const updateSummary = async () => {
    const { data } = await getManagementSummary({
      api: { get },
    });

    setTabCounters((tabCounters) => ({
      ...tabCounters,
      ...data,
    }));
  };

  useEffect(() => {
    updateSummary();
  }, []);

  return (
    <ManagementProvider>
      <ManagementPageContainer data-testid="management-page">
        <Root defaultValue={tab} value={tab}>
          <Container>
            <div>
              <List>
                {tablesMapping.map((table) => (
                  <Trigger
                    key={`tabs-trigger-${table}`}
                    value={table}
                    onClick={() => setTab(table)}
                  >
                    {formatMessage(
                      { id: `tables.${table}.tab.title` },
                      {
                        quantity: tabCounters[table],
                      }
                    )}
                  </Trigger>
                ))}
              </List>
            </div>

            <SearchFieldContainer>
              <SearchField.Root
                width={268}
                value={search}
                placeholder={formatMessage({ id: 'tables.search' })}
                onClear={() => setSearch('')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(event.target.value)
                }
              />
            </SearchFieldContainer>
          </Container>

          {tablesMapping.map((table) => (
            <Content key={`tabs-content-${table}`} value={table}>
              <ManagementTable type={table} />
            </Content>
          ))}
        </Root>
      </ManagementPageContainer>
    </ManagementProvider>
  );
};
