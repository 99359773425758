import { Button, Heading, Paragraph } from '@hexa-ui/components';
import { Unlock } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { useRequester } from '../../../hooks/useRequester';
import { useToast } from '../../../hooks/useToast';
import { ManagementModalData } from '../../../interfaces';
import { unblockCreditLine, unblockCreditLinesByFilter } from '../../../services';
import { Modal } from '../Modal/Modal';
import { AlertStyled, ModalContent } from './styles';
import { UnblockCreditLineModalProps } from './UnblockCreditLineModal.types';

export const UnblockCreditLineModal: React.FC<UnblockCreditLineModalProps> = ({
  mode,
  data,
  counter,
  trigger,
  onClose,
  callbackFn,
}) => {
  const { formatMessage } = useIntl();

  const { toast } = useToast();
  const { patch } = useRequester();

  const onClear = () => {
    if (onClose) onClose();
  };

  const onUnblock = async ({ id, pocName }: ManagementModalData) => {
    const response = await unblockCreditLine({
      api: { patch },
      params: { id },
    });

    if (!response?.success) {
      toast.error({ message: 'unblockCreditLine.single.toasts.error', attributes: { pocName } });

      onClear();
      return;
    }

    toast.success({ message: 'unblockCreditLine.single.toasts.success', attributes: { pocName } });

    onClear();
    if (callbackFn) callbackFn();
  };

  const onUnblockByFilter = async (data: ManagementModalData[]) => {
    const response = await unblockCreditLinesByFilter({
      api: { patch },
      params: { data },
    });

    if (!response?.success) {
      toast.error({ message: 'unblockCreditLine.multiple.toasts.error', attributes: { counter } });
      return;
    }

    toast.success({
      message: 'unblockCreditLine.multiple.toasts.success',
      attributes: { counter },
    });
    if (callbackFn) callbackFn();
  };

  const onApply = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (mode === 'single') await onUnblock(data);
    else await onUnblockByFilter(data);
  };
  return (
    <Modal
      {...(trigger ? { trigger } : { open: { value: true } })}
      title={
        <Heading size="H2">{formatMessage({ id: `unblockCreditLine.${mode}.title` })}</Heading>
      }
      onClose={onClear}
      onInteractOutside={onClear}
      headerHeight="4.5rem"
      actions={{
        buttons: {
          cancel: (
            <Button id="on-cancel" size="large" variant="secondary" onClick={onClear}>
              {formatMessage({ id: `unblockCreditLine.${mode}.buttons.cancel` })}
            </Button>
          ),
          confirm: (
            <Button
              id="on-apply"
              leading
              size="large"
              variant="primary"
              icon={Unlock}
              onClick={onApply}
            >
              {formatMessage({ id: `unblockCreditLine.${mode}.buttons.apply` })}
            </Button>
          ),
        },
      }}
    >
      <ModalContent>
        <Heading size="H4" css={{ marginTop: '8px', whiteSpace: 'pre' }}>
          {mode === 'single'
            ? formatMessage(
                { id: 'unblockCreditLine.single.subtitle' },
                { pocName: data.pocName, accountId: data.accountId }
              )
            : formatMessage({ id: 'unblockCreditLine.multiple.subtitle' }, { counter })}
        </Heading>

        <Paragraph>{formatMessage({ id: `unblockCreditLine.${mode}.description` })}</Paragraph>
        <AlertStyled
          type="info"
          message={formatMessage({ id: `unblockCreditLine.${mode}.alert` })}
        />
      </ModalContent>
    </Modal>
  );
};
