import { Actions, Text } from '../../../components/organisms/Tables/custom-columns';
import { ActionsTypes, ColumnProps, FilterProps } from '../../../interfaces';

export const columns: ColumnProps[] = [
  {
    accessor: 'pocName',
    sizing: { size: 15, minSize: 180 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 15 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'accountId',
    sizing: { size: 12, minSize: 140 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 12 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'creditLimit',
    sizing: { size: 12, minSize: 180 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="currency" params={{ value, sizing: { size: 12 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'term',
    sizing: { size: 8, minSize: 60 },
    sorting: { has: true },
    render: (value: string) => <Text type="days" params={{ value, sizing: { size: 8 } }} />,
  },
  {
    accessor: 'fee',
    sizing: { size: 8, minSize: 60 },
    sorting: { has: true },
    render: (value: string) => <Text type="fee" params={{ value, sizing: { size: 8 } }} />,
  },
  {
    accessor: 'score',
    sizing: { size: 8, minSize: 60 },
    sorting: { has: true },
    render: (value: string) => <Text type="text" params={{ value, sizing: { size: 8 } }} />,
  },
  {
    accessor: 'blockedBy',
    sizing: { size: 10, minSize: 60 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 10 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'blockedReason',
    sizing: { size: 10, minSize: 60 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="text" params={{ value, sizing: { size: 10 } }} hasEllipsis />
    ),
  },
  {
    accessor: 'blockedDate',
    sizing: { size: 10, minSize: 60 },
    sorting: { has: true },
    render: (value: string) => (
      <Text type="datetime" params={{ value, type: 'short', sizing: { size: 15 } }} />
    ),
  },
  {
    accessor: 'actions',
    sizing: { size: 8, minSize: 8 },
    sorting: { has: false },
    render: ({ selected, onOpenActionModals }) => (
      <Actions type={ActionsTypes.BLOCKED} data={{ selected, onOpenActionModals }} />
    ),
  },
];

export const filters: FilterProps[] = [
  {
    type: 'range-value',
    identifier: 'creditLimit',
    name: 'tables.filters.inputs.creditLimit.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'currency',
        identifier: 'min',
        label: 'tables.filters.inputs.creditLimit.min.label',
        placeholder: 'tables.filters.inputs.creditLimit.min.placeholder',
        affix: {
          type: 'currency',
          prefix: true,
          sufix: false,
        },
      },
      {
        type: 'text',
        format: 'currency',
        identifier: 'max',
        label: 'tables.filters.inputs.creditLimit.max.label',
        placeholder: 'tables.filters.inputs.creditLimit.max.placeholder',
        affix: {
          type: 'currency',
          prefix: true,
          sufix: false,
        },
      },
    ],
  },
  {
    type: 'range-value',
    identifier: 'term',
    name: 'tables.filters.inputs.maximumTerm.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'days',
        identifier: 'min',
        label: 'tables.filters.inputs.maximumTerm.min.label',
        placeholder: 'tables.filters.inputs.maximumTerm.min.placeholder',
        affix: {
          type: 'text',
          prefix: false,
          sufix: true,
          label: 'tables.filters.inputs.maximumTerm.min.sufix',
        },
      },
      {
        type: 'text',
        format: 'days',
        identifier: 'max',
        label: 'tables.filters.inputs.maximumTerm.max.label',
        placeholder: 'tables.filters.inputs.maximumTerm.max.placeholder',
        affix: {
          type: 'text',
          prefix: false,
          sufix: true,
          label: 'tables.filters.inputs.maximumTerm.max.sufix',
        },
      },
    ],
  },
  {
    type: 'range-value',
    identifier: 'fee',
    name: 'tables.filters.inputs.monthlyFee.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'percentage',
        identifier: 'min',
        label: 'tables.filters.inputs.monthlyFee.min.label',
        placeholder: 'tables.filters.inputs.monthlyFee.min.placeholder',
        affix: {
          type: 'percentage',
          prefix: false,
          sufix: true,
        },
      },
      {
        type: 'text',
        format: 'percentage',
        identifier: 'max',
        label: 'tables.filters.inputs.monthlyFee.max.label',
        placeholder: 'tables.filters.inputs.monthlyFee.max.placeholder',
        affix: {
          type: 'percentage',
          prefix: false,
          sufix: true,
        },
      },
    ],
  },
  {
    type: 'range-value',
    identifier: 'score',
    name: 'tables.filters.inputs.score.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'score',
        identifier: 'min',
        label: 'tables.filters.inputs.score.min.label',
        placeholder: 'tables.filters.inputs.score.min.placeholder',
      },
      {
        type: 'text',
        format: 'score',
        identifier: 'max',
        label: 'tables.filters.inputs.score.max.label',
        placeholder: 'tables.filters.inputs.score.max.placeholder',
      },
    ],
  },
  {
    type: 'checkbox',
    name: 'tables.filters.inputs.blockedReason.name',
    identifier: 'blockedReason',
    title: 'tables.filters.inputs.blockedReason.title',
    input: [
      {
        type: 'checkbox',
        format: 'text',
        identifier: 'current',
        label: 'tables.filters.inputs.blockedReason.labels.current',
        value: 'ON_TIME',
      },
      {
        type: 'checkbox',
        format: 'text',
        identifier: 'overdue',
        label: 'tables.filters.inputs.blockedReason.labels.overdue',
        value: 'OVERDUE',
      },
      {
        type: 'checkbox',
        format: 'text',
        identifier: 'other',
        label: 'tables.filters.inputs.blockedReason.labels.other',
        value: 'OTHER',
      },
    ],
  },
  {
    type: 'range-date',
    identifier: 'blockedDate',
    name: 'tables.filters.inputs.blockedDate.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'text',
        identifier: 'startDate',
        label: 'tables.filters.inputs.blockedDate.min.label',
        placeholder: 'tables.filters.inputs.blockedDate.min.placeholder',
      },
      {
        type: 'text',
        format: 'text',
        identifier: 'endDate',
        label: 'tables.filters.inputs.blockedDate.max.label',
        placeholder: 'tables.filters.inputs.blockedDate.max.placeholder',
      },
    ],
  },
];
