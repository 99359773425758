import styled from 'styled-components';

export const ChipsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  width: 100%;
  height: 64px;
  padding: 12px 16px;
`;
