import { Portal } from '@hexa-ui/components';
import React from 'react';
import { ModalProps } from './Modal.types';
import { ButtonContainer, Close, Container, Root } from './styles';

const ModalComponent: React.FC<Omit<ModalProps, 'portal'>> = ({
  variant = 'overlay',
  open = { value: false },
  title,
  actions,
  trigger,
  closeButton = true,
  css,
  children,
  onClose,
  onOpenChange,
  onInteractOutside,
  headerHeight,
}) => {
  return (
    <Container data-testid="modal-container" onClick={(e) => e.stopPropagation()}>
      <Root
        contentCss={css}
        variant={variant}
        title={title}
        closeButton={closeButton}
        onClose={onClose}
        onOpenChange={onOpenChange}
        onInteractOutside={onInteractOutside}
        headerHeight={headerHeight}
        {...(open.value ? { open: open.value } : { trigger })}
        actions={
          actions && (
            <ButtonContainer $direction={actions.direction ?? 'flex-end'}>
              {actions.buttons.cancel && <Close>{actions.buttons.cancel}</Close>}
              {actions.buttons.confirm && <Close>{actions.buttons.confirm}</Close>}
            </ButtonContainer>
          )
        }
      >
        {children}
      </Root>
    </Container>
  );
};

export const Modal: React.FC<ModalProps> = ({
  portal = { has: false },
  trigger,
  children,
  ...props
}) => {
  if (!portal.has)
    return (
      <ModalComponent trigger={trigger} {...props}>
        {children}
      </ModalComponent>
    );

  return (
    <React.Fragment>
      <Portal container={portal.container}>
        <ModalComponent {...props}>{children}</ModalComponent>
      </Portal>

      {trigger}
    </React.Fragment>
  );
};
