import { TypeToast } from 'admin-portal-shared-services';
import { Tables } from '..';

/**
 * @namespace Notifications
 */

export type ConditionsType = 'HAS' | 'HAS_NOT';
export type NotificationsActionType = 'REDIRECT';

export enum NotificationsOrigin {
  BU = 'BU',
  ASYNC_UPDATE = 'ASYNC_UPDATE',
}

interface ConditionsProps {
  key: string;
  type: ConditionsType;
}

export type ActionAttributes<T> = T extends 'REDIRECT' ? { to: string; params: string[] } : never;

interface ActionsProps<T extends NotificationsActionType = NotificationsActionType> {
  name: string;
  type: T;
  attributes?: ActionAttributes<T>;
}

interface NotificationProps {
  type: TypeToast;
  message: string;
  attributes?: string[];
  actions?: ActionsProps;
  condition?: ConditionsProps;
}

export interface NotificationsData {
  [key: string]: NotificationProps[];
}

export type Notifications = {
  [key in NotificationsOrigin]: NotificationsData;
};

export type FilterMapping = {
  [table in Tables]: { [key: string]: string };
};
