import { Button, Heading, Paragraph } from '@hexa-ui/components';
import { Check2 } from '@hexa-ui/icons';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Status } from '../../../interfaces';
import SegmentService from '../../../services/segment';
import { DenyRecommendationProps } from './DenyRecommendationProps.types';
import { ButtonContainer, Close, Container, ModalContent, Root } from './styles';

export const DenyRecommendation: React.FC<DenyRecommendationProps> = ({
  data,
  trigger,
  onApproval,
}) => {
  const { formatMessage } = useIntl();

  const { selected } = useGetUserPreferences();

  const onClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  const onDeny = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>, status: Status) => {
      event.stopPropagation();

      await onApproval(status);
    },
    [onApproval]
  );

  const buttonClicked = (buttonLabel: string, buttonName: string, event: string) => {
    SegmentService.paymentsButtonClicked(
      buttonLabel,
      buttonName,
      selected?.vendorId,
      event,
      'CREDIT_MANAGEMENT_PENDING_TAB'
    );
  };

  return (
    <Container data-testid="deny-container" onClick={(e) => e.stopPropagation()}>
      <Root
        trigger={trigger}
        contentCss={{ width: '572px' }}
        onClose={onClose}
        actions={
          <ButtonContainer>
            <Close>
              <Button id="on-back" size="medium" variant="secondary" onClick={onClose}>
                {formatMessage({ id: 'approveRecommendation.buttons.back' })}
              </Button>
            </Close>

            <Close>
              <Button
                id="on-deny"
                icon={Check2}
                size="medium"
                variant="primary"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  onDeny(event, Status.DENIED);
                  buttonClicked(
                    'Deny Selected',
                    'Deny Selected Button',
                    'Credit Management Mass Approval Deny Selected'
                  );
                }}
              >
                {formatMessage({ id: 'denyRecommendation.button' })}
              </Button>
            </Close>
          </ButtonContainer>
        }
      >
        <ModalContent data-testid="modal-content" onClick={(e) => e.stopPropagation()}>
          <Heading size="H2" css={{ fontSize: '32px' }}>
            {formatMessage({ id: 'denyRecommendation.title' }, { counter: data.counter })}
          </Heading>
          <Paragraph size="basis">
            {formatMessage({ id: 'denyRecommendation.description' }, { counter: data.counter })}
          </Paragraph>
        </ModalContent>
      </Root>
    </Container>
  );
};
