import { ManagementModalData } from '../../../interfaces';

export enum BlockReasonsTypes {
  LOW_SCORE = 'LOW_SCORE',
  PAYMENT_OVERDUE = 'PAYMENT_OVERDUE',
  OTHER = 'OTHER',
}

interface BaseBlockModalProps {
  trigger?: React.ReactNode;
  counter?: number;
  onClose?: () => void;
  callbackFn?: () => Promise<void>;
}

interface SingleBlockModalProps extends BaseBlockModalProps {
  mode: 'single';
  data: ManagementModalData;
}

interface MultipleBlockModalProps extends BaseBlockModalProps {
  mode: 'multiple';
  counter: number;
  data: ManagementModalData[];
}

export type BlockCreditLineModalProps = SingleBlockModalProps | MultipleBlockModalProps;
