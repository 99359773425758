import {
  CurrencyFn,
  DatetimeFn,
  GeneralFn,
  MappedFormatting,
  NumberFn,
  StatusFn,
  TermFeeFn,
} from '../interfaces';

const currency = ({ value, format }: CurrencyFn): MappedFormatting => {
  const replacedValue = value.replace(/\D/g, '');

  if (!replacedValue) {
    return { value: '', formatted: { simple: '', completed: '' } };
  }

  const formattedValue = format(parseFloat(replacedValue) / 100);

  return {
    value: replacedValue,
    formatted: {
      simple: formattedValue.replace(/[^0-9.,]/g, '').trim(),
      completed: formattedValue,
    },
  };
};

const percentage = ({ value, format }: GeneralFn): MappedFormatting => {
  const replaced = value.replace(/[^0-9,]/g, '').replace(',', '.');
  const replacedValue = replaced.replace(/([,.].*?\d{2}).*$/, '$1');

  const formattedSimple = parseFloat(replacedValue) > 100 ? '100' : replacedValue;
  const formattedValue = (parseFloat(formattedSimple) * 100).toFixed(2);

  return {
    value: isNaN(parseFloat(formattedValue)) ? '' : String(parseFloat(formattedValue)),
    formatted: {
      simple: formattedSimple.replace('.', ','),
      completed: format(
        { id: 'formatting.input.text.percentage' },
        { value: (parseFloat(formattedValue) / 100).toFixed(2).replace('.', ',') }
      ),
    },
  };
};

const days = ({ value, format }: GeneralFn): MappedFormatting => {
  const replacedValue = value.replace(/\D/g, '');

  return {
    value: replacedValue,
    formatted: {
      simple: replacedValue,
      completed: format({ id: 'formatting.input.text.days' }, { value: replacedValue }),
    },
  };
};

const datetime = ({ value, format, config }: DatetimeFn): MappedFormatting => {
  const newDate = new Date(value);

  return {
    value,
    formatted: {
      simple: newDate.toISOString().split('T')[0],
      completed: format(newDate.toISOString(), config?.datetimeType ?? 'short'),
    },
  };
};

const score = ({ value }: GeneralFn): MappedFormatting => {
  const replacedValue = value.replace(/[^0-9-]+/g, '');

  return {
    value: replacedValue,
    formatted: { simple: replacedValue, completed: replacedValue },
  };
};

const number = ({ value, locale }: NumberFn): MappedFormatting => {
  const replacedValue = value.replace(/\D/g, '');
  const formattedValue = Number(replacedValue).toLocaleString(locale);

  return {
    value: replacedValue,
    formatted: { simple: formattedValue, completed: formattedValue },
  };
};

const fee = ({ value, format }: GeneralFn): MappedFormatting => {
  const replacedValue = String(parseFloat(value) / 100).replace('.', ',');
  const { value: fee, formatted } = percentage({ value: replacedValue, format });

  return { value: fee, formatted: formatted };
};

const termFee = ({ value, format }: TermFeeFn): MappedFormatting => {
  const formattedValue = format(
    { id: 'formatting.term-fee' },
    { term: value.term, fee: fee({ value: value.fee, format }).formatted.completed }
  );

  return {
    value: `${value.term}:${value.fee}`,
    formatted: { simple: formattedValue, completed: formattedValue },
  };
};

const listItems = ({ value, format }: GeneralFn): MappedFormatting => {
  if (value.trim().includes(',')) {
    const values = value.split(',');

    return {
      value,
      formatted: {
        simple: `${values[0]}, +${values.length - 1}`,
        completed: format(
          { id: 'formatting.list-items' },
          { items: values.slice(0, -1).join(', '), last: values.slice(-1).join('') }
        ),
      },
    };
  }

  return { value, formatted: { simple: value, completed: value } };
};

const truncate = ({ value, config }: GeneralFn): MappedFormatting => {
  const regex = new RegExp(`^(.{${config?.length}})(.*)$`);
  const formattedValue = value.length > config?.length ? value.replace(regex, '$1...') : value;

  return {
    value,
    formatted: { simple: formattedValue, completed: formattedValue },
  };
};

const custom = ({ value, type, format }: StatusFn): MappedFormatting => {
  if (!value) {
    return { value, formatted: { simple: '', completed: '' } };
  }

  const formattedValue = format({ id: `formatting.${type}.${value}` });

  return {
    value,
    formatted: { simple: formattedValue, completed: formattedValue },
  };
};

export const format = {
  currency,
  datetime,
  days,
  fee,
  score,
  number,
  percentage,
  recommendation: custom,
  'approval-method': custom,
  'payment-method': custom,
  'term-fee': termFee,
  'list-items': listItems,
  'truncate-text': truncate,
};
