import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  row-gap: 24px;
  max-width: 624px;
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 16px;

  width: 100%;

  > * {
    align-self: end;
  }
`;
