import { Locales } from '../../interfaces';

const enUS: Locales = {
  countries: {
    AQ: 'Antarctica',
    AR: 'Argentina',
    BB: 'Barbados',
    BE: 'Belgium',
    BO: 'Bolivia',
    BR: 'Brazil',
    CA: 'Canada',
    IC: 'Canary Islands',
    CL: 'Chile',
    CO: 'Colombia',
    CZ: 'Czech Republic',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    SV: 'El Salvador',
    DE: 'Germany',
    HN: 'Honduras',
    IN: 'India',
    ID: 'Indonesia',
    MX: 'Mexico',
    NL: 'Netherlands',
    NG: 'Nigeria',
    PA: 'Panama',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    ZA: 'South Africa',
    KR: 'South Korea',
    TZ: 'Tanzania',
    TH: 'Thailand',
  },
  select: {
    country: 'Select a country',
    vendor: 'Select a vendor',
  },
  routes: {
    management: 'Credit management',
    recommendation: 'Credit recommendations',
    credit: 'Credit management',
    upload: 'Update credit conditions',
    progress: 'File progress',
    pocDetails: 'POC details',
    files: 'POCs files',
    views: 'Custom view',
  },
  pages: {
    management: {
      title: 'Credit management',
    },
    credit: {
      title: 'Credit management',
      summary: {
        total: 'Total POCs',
        pending: 'Pending approvals',
        inprogress: 'In progress',
        active: 'Active credit',
        denied: 'Credit denied',
      },
    },
    recommendation: {
      title: 'Credit recommendations',
      summary: {
        recommendations: 'Recommendations',
        capitalAllocation: 'Capital allocation',
      },
    },
    files: {
      title: 'POCs files',
    },
    upload: {
      title: 'Update credit conditions',
      uploader: {
        title: 'File upload',
        errors: {
          noFile: 'You must upload a file to continue.',
          maxFileSize: 'File exceeds the { maxFileSize }MB limit.',
          invalidFormat: 'Invalid file format. File must be in CSV or XLSX format.',
        },
        alertMessage: 'From this step on, you can leave the page without losing progress.',
        rulesAndConditions: {
          modalLink: ` rules and conditions.`,
          description:
            'Update POCs credit conditions by uploading a file. File must comply with the ',
          progress: {
            description:
              'Analyze the file to ensure all POCs comply to the <TextLink>rules and conditions</TextLink> for credit updates. ',
          },
          modal: {
            title: 'Rules and conditions',
            description:
              'These rules are valid for uploading files with new credit conditions. POCs with errors will need to be corrected and uploaded again.',
            files: {
              title: 'File rules',
              wontConsider: "It won't be considered:",
              items: {
                duplicatedPOCLines:
                  '<strong>Duplicated POC lines. </strong>If the file has two lines on the Account ID with the same information, only the first line will be considered.',
                newlyIncluded:
                  '<strong>Newly included POC lines. </strong>They will be shown as "POC non-existing".',

                removeLines: '<strong>Removed POC lines</strong>',
                blankFields: '<strong>Blank POC fields</strong>',
                newColumns: '<strong>Newly added columns</strong>',
              },
            },
            credit: {
              title: 'Credit rules',
              items: {
                creditLimit: '<strong>Credit limit: </strong>',
                creditTerm: '<strong>Credit term: </strong>',
                creditFee: '<strong>Credit fee: </strong>',
              },
            },
          },
        },
        fileUploader: {
          title: 'Drag your file here or ',
          label: 'Browse',
          description: 'Format must be XLSX or CSV. Maximum size of { maxFileSize }MB per file.',
        },
        fileDescription: {
          title: 'File description',
          subTitle: '(optional)',
          description: 'Make this file easier to identify by providing some details.',
        },
        steps: {
          fileUpload: 'File upload',
          fileAnalysis: 'File analysis',
          pocUpdate: 'POCs update',
        },
      },
      buttons: {
        back: 'Cancel',
        upload: 'Analyze file',
        apply: 'Update POCs',
        goToCredit: 'Go to credit',
        goToFiles: 'Go to POC files',
        uploadNewFile: 'Upload new file',
        toasts: {
          analysis: '{ validated } POCs were successfully updated.',
          processing: 'Processing file. { fileName }',
          success: 'File successfully uploaded! { fileName }',
          warning: 'File uploaded with errors. { fileName }',
          error: 'Upload error. { fileName }',
        },
      },
      progress: {
        title: 'File analysis',
        processingTitle: 'POCs update',
        processingDescription:
          'Update the POCs credit conditions. This step may take some time and you’ll be notified once it is completed.',
        meter: {
          starting: 'Starting',
          progress: '{ progressValue } out of { progressMaxValue } POCs analyzed.',
          progressUpload: '{ progressValue } out of { progressMaxValue } POCs updated.',
          description: 'Analysis time may be longer when the file contains many POCs.',
          descriptionProgressing:
            'Processing time may be longer when there are many POCs to be updated.',
          error: 'The file contains no valid POCs. Review the file and upload it again.',
          slow: 'The upload is taking longer than usual. Wait a moment.',
        },
        finished: {
          title: 'Analysis results',
          pocs: {
            total: 'POCs analyzed',
            success: 'POCs to be updated',
            error: 'POCs with errors',
            tooltip:
              "It's only possible to increase the credit limit to 200% of the suggested amount, and the maximum term allowed is 30 days.",
          },
          alert:
            'You can continue with the POCs to be updated. To correct errors, download the error list and upload a new file.',
          download: 'Download error list',
        },
        error: {
          analysis: 'Unable to complete the file analysis. Try uploading the file again.',
          processing: 'Unable to complete the POCs update. Try uploading the file again.',
        },
      },
      cancelModal: {
        title: 'Cancel file upload?',
        subtitle: 'Your progress will be lost and you will need to start it again.',
        buttons: { confirm: 'Yes, cancel', cancel: 'No, go back' },
      },
    },
    'poc-details': {
      recommendation: {
        title: 'Credit line recommendation',
        status: {
          pending: 'Pending',
          denied: 'Denied',
          waitingActivation: 'Waiting activation',
          waitingOnboarding: 'Waiting onboarding',
        },
        labels: {
          creditLimit: 'Credit limit',
          termFee: 'Term/fee',
          recommendation: 'Recommendation',
          updatedOn: 'Updated on',
          approvedBy: 'Edited by',
          approvalMethod: 'Edit method',
        },
        timeline: {
          title: 'Recommendation timeline',
          steps: {
            APPROVED: 'Approved',
            ONBOARDING: 'Onboarding',
            ACTIVATION: 'Activation process',
            ACTIVATED: 'Credit line activated',
          },
        },
      },
      information: {
        title: 'POC information',
        labels: {
          pocName: 'Name',
          pocDocument: 'ID',
          pocAddress: 'Address',
          pocEmail: 'Email',
          pocPhoneNumber: 'Phone number',
          pocAccount: 'Account ID',
        },
      },
      conditions: {
        title: 'Current credit line conditions',
        status: {
          active: 'Active',
          blocked: 'Blocked',
          overdue: 'Overdue',
          due: 'Due',
        },
        labels: {
          creditLimit: 'Credit limit',
          limitAvailable: 'Limit available',
          balance: 'Balance',
          overdue: 'Overdue',
          termFee: 'Term/fee',
          paymentMethod: 'Payment method',
          activatedOn: 'Activated on',
          approvedBy: 'Approved by',
          approvalMethod: 'Approval method',
          fileName: 'File name',
          fileDescription: 'File description',
        },
      },
      history: {
        credit: {
          title: 'Credit history',
          description: "All status changes made to the POC's credit conditions.",
          buttons: { trigger: 'Show credit history' },
          information: {
            resume: '{ limit } - { term } - { fee }',
            by: 'By { identifier }',
          },
          identifiers: {
            backend: 'backend',
            poc: 'POC',
          },
          labels: {
            byAndHour: 'by { identifier } at { hour }',
            creditLimit: 'Credit limit:',
            termFee: 'Term/fee:',
            method: {
              edit: 'Edit method:',
              approval: 'Approval method:',
              denial: 'Denial method:',
            },
          },
          status: {
            NEW_CREDIT_RECOMMENDATION: 'New credit recommendation',
            CREDIT_RECOMMENDATION_APPROVED: 'Credit recommendation approved',
            CREDIT_RECOMMENDATION_DENIED: 'Credit recommendation denied',
            WAITING_ONBOARDING_BYPASSED: 'Onboarding by passed',
            WAITING_ONBOARDING_COMPLETED: 'Onboarding completed',
            CREDIT_LINE_ACTIVATED: 'Credit line activated',
            CREDIT_LINE_BLOCKED: 'Credit line blocked by nonpayment',
            CREDIT_LINE_UNBLOCKED: 'Credit line unblocked',
          },
        },
      },
    },
    views: {
      title: 'Custom view',
      description:
        'Create customized visualizations in the table to suit your analysis requirements.',
      buttons: { back: 'Go back', save: 'Save and apply' },
      toasts: {
        warning: 'You can add a maximum of 6 columns to a table.',
        success: '{ name } view has been saved and applied to the table.',
      },
      inputs: {
        name: {
          label: 'View name',
          placeholder: 'Enter name',
        },
        type: {
          label: 'View type',
          options: {
            RECOMMENDED: {
              label: 'Credit recommendations',
              tooltip: 'POCs marked by the algorithm as good candidates for credit.',
            },
            NOT_RECOMMENDED: {
              label: 'POCs without credit recommendation',
              tooltip: 'POCs not marked by the algorithm as good candidates for credit.',
            },
          },
        },
      },
      columns: {
        select: { description: 'Select up to 6 columns to display in the table.' },
        order: {
          description: 'Sort the selected columns based in the order of your preference.',
        },
      },
    },
  },
  formatting: {
    datetime: { 'long-time': `{ date } at { time }` },
    input: {
      text: {
        percentage: '{ value } %',
        days: '{value, plural, =1 {# day} other {# days}}',
      },
      suffix: {
        percentage: '%',
        days: '{value, plural, =1 {day} other {days}}',
      },
    },
    recommendation: {
      NEW_CREDIT_OFFER: 'Credit offer',
      INCREASE: 'Credit increase',
      DECREASE: 'Credit decrease',
    },
    'approval-method': {
      FILE_BATCH_UPDATE: 'By file',
      SYSTEM: 'By system',
    },
    'payment-method': {
      CASH: 'Cash',
      CREDIT: 'Credit',
      BANK_SLIP: 'Bank slip',
      BANK_SLIP_INSTALLMENT: 'Bank slip installment',
    },
    'term-fee': '{term, plural, =1 {# day} other {# days}} / {fee}',
    'list-items': '{items} and {last}',
  },
  tables: {
    loading: 'Loading items...',
    empty: 'There are no POCs in this list yet.',
    error: {
      somethingWentWrong: 'Something went wrong.',
      tryReloading: 'Try reloading the page.',
    },
    search: 'Search by POC name or ID',
    collapseActionsMenu: {
      edit: 'Edit credit line',
      editAndUnblock: 'Edit and unblock',
      block: 'Block credit line',
      cancel: 'Cancel credit line',
      unblock: 'Unblock credit line',
    },
    pagination: {
      quantity: 'of',
      itensPerPage: 'Items per page: { numberPage }',
    },
    selectable: {
      selected: '{counter, plural, =1 {# client selected} other {# clients selected}}',
      deselect: 'Deselect all',
    },
    views: {
      selector: { label: 'View: { name }' },
      card: {
        custom: { title: 'Custom views' },
        alert: 'You have reached the limit of 10 custom views. Delete a view to create a new one.',
        standard: {
          title: 'Standard views',
          DEFAULT: 'Standard',
          RECOMMENDED: 'Credit recommendations',
          NOT_RECOMMENDED: 'POCs without credit recommendation',
        },
        buttons: { create: 'Create new custom view' },
        tooltips: { edit: 'Edit view', delete: 'Delete view' },
        modal: {
          title: 'Delete custom view?',
          description: 'This action cannot be undone.',
          buttons: { cancel: 'Não, voltar', delete: 'Yes, delete' },
          toasts: { success: 'Your custom view has been deleted.' },
        },
      },
    },
    filters: {
      empty: 'No filter results.',
      buttons: {
        filter: '{value, plural, =0 {Filter} one {Filter (1)} other {Filters (#)}}',
        cancel: 'Cancel',
        apply: 'Apply',
        clearAll: 'Clear all',
        clearDate: 'Clear date',
      },
      chips: {
        min: 'Minimum { min }',
        max: 'Maximum { max }',
        startDate: 'Start date { min }',
        endDate: 'End date { max }',
        to: '{ min } to { max }',
        range: '{ min } - { max }',
      },
      inputs: {
        creditLimit: {
          name: 'Credit limit',
          min: { label: 'Minimum limit', placeholder: 'Enter value' },
          max: { label: 'Maximum limit', placeholder: 'Enter value' },
        },
        term: {
          name: 'Term',
          min: {
            label: 'Minimum term',
            placeholder: '0',
            sufix: '{value, plural, =1 {day} other {days}}',
          },
          max: {
            label: 'Maximum term',
            placeholder: '0',
            sufix: '{value, plural, =1 {day} other {days}}',
          },
        },
        maximumTerm: {
          name: 'Maximum term',
          min: {
            label: 'Lowest maximum term',
            placeholder: '0',
            sufix: '{value, plural, =1 {day} other {days}}',
          },
          max: {
            label: 'Highest maximum term',
            placeholder: '0',
            sufix: '{value, plural, =1 {day} other {days}}',
          },
        },
        fee: {
          name: 'Fee',
          min: { label: 'Minimum fee', placeholder: '0' },
          max: { label: 'Maximum fee', placeholder: '0' },
        },
        monthlyFee: {
          name: 'Monthly fee',
          min: { label: 'Minimum fee', placeholder: '0' },
          max: { label: 'Maximum fee', placeholder: '0' },
        },
        score: {
          name: 'Score',
          min: { label: 'Minimum score', placeholder: '0 - 100' },
          max: { label: 'Maximum score', placeholder: '0 - 100' },
        },
        updatedAt: {
          name: 'Last update',
          min: { label: 'Start date', placeholder: 'Select date' },
          max: { label: 'End date', placeholder: 'Select date' },
        },
        blockedDate: {
          name: 'Block date',
          min: { label: 'Start date', placeholder: 'Select date' },
          max: { label: 'End date', placeholder: 'Select date' },
        },
        paymentStatus: {
          name: 'Payment status',
          title: 'Payment status',
          labels: {
            current: 'Current',
            overdue: 'Overdue',
          },
        },
        status: {
          name: 'Status',
          title: 'Status',
          labels: {
            processing: 'Processing',
            integration: 'Onboarding',
            activated: 'Active',
            denied: 'Denied',
          },
        },
        recommendation: {
          name: 'Recommendation',
          title: 'Recommendation',
          labels: {
            creditOffer: 'Credit offer',
            creditIncrease: 'Credit increase',
            creditDecrease: 'Credit decrease',
          },
        },
        blockedReason: {
          name: 'Block reason',
          title: 'Block reason',
          labels: {
            current: 'Low score',
            overdue: 'Payment overdue',
            other: 'Other'
          },
        }
      },
    },
    files: {
      tab: { title: 'Arquivos' },
      empty: {
        default: 'There are no files in this list yet.',
        search: 'No search results',
        error: 'Something went wrong. Try reload the page.',
      },
      search: 'Search by File name',
      status: {
        uploaded: 'Pending analysis',
        analyzing: 'Analysis in progress',
        analyzed: 'Pending update',
        processing: 'Update in progress',
        success: 'Completed',
      },
      columns: {
        fileName: 'File name',
        fileDescription: 'File description',
        total: 'POCs updated',
        uploadedBy: 'Updated by',
        uploadedAt: 'Updated on',
        status: 'Status',
        actions: 'Action',
      },
    },
    pending: {
      tab: { title: 'Pending' },
      columns: {
        pocName: 'POC',
        accountId: 'Account ID',
        creditLimit: 'Credit limit',
        term: 'Max. term',
        fee: 'Mon. fee',
        score: 'Score',
        recommendation: 'Recommendation',
        updatedAt: 'Last update',
        actions: 'Action',
        salesAgency: 'Sales agency',
        salesArea: 'Sales area',
        customerType: 'Customer type',
      },
    },
    inprogress: {
      tab: { title: 'In progress' },
      status: {
        processing: 'Processing',
        integration: 'Onboarding',
      },
      columns: {
        pocName: 'POC',
        accountId: 'Account ID',
        creditLimit: 'Credit limit',
        term: 'Term',
        fee: 'Fee',
        updatedAt: 'Last update',
        updatedBy: 'Updated by',
        status: 'Status',
      },
    },
    completed: {
      tab: { title: 'Completed' },
      status: {
        active: 'Active',
        denied: 'Denied',
      },
      columns: {
        pocName: 'POC',
        accountId: 'Account ID',
        creditLimit: 'Credit limit',
        term: 'Term',
        fee: 'Fee',
        updatedAt: 'Last update',
        updatedBy: 'Updated by',
        status: 'Credit status',
      },
    },
    processing: {
      tab: { title: 'Processing ({ quantity })' },
      columns: {},
    },
    active: {
      tab: { title: 'Active credit ({ quantity })' },
      columns: {
        accountId: 'Account ID',
        creditLimit: 'Credit limit',
        fee: 'Monthly fee',
        pocName: 'POC',
        score: 'Score',
        term: 'Max. term',
        paymentStatus: 'Payment status',
        actions: 'Actions',
        updatedAt: 'Last update',
      },
      status: {
        current: 'Current',
        overdue: 'Overdue',
      },
    },
    blocked: {
      tab: { title: 'Blocked credit ({ quantity })' },
      columns: {
        accountId: 'Account ID',
        creditLimit: 'Credit limit',
        fee: 'Monthly fee',
        pocName: 'POC',
        score: 'Score',
        term: 'Max. term',
        blockedBy: 'Blocked by',
        blockedReason: 'Block reason',
        blockedDate: 'Block date',
        actions: 'Actions',
      },
    },
    canceled: {
      tab: { title: 'Credit canceled ({ quantity })' },
      columns: {},
    },
  },
  files: {
    buttons: {
      upload: 'Update credit conditions',
      download: 'Download POCs',
    },
    modal: {
      title: 'Download file',
      description: 'Download files to approve or deny client credit lines. Files cannot contain:',
      itemsList: {
        first: 'Added or renamed columns',
        second: 'Added or renamed clients',
        third: 'Any information that has been added or modified except for approval status',
      },
      fileDetails: {
        title: 'File details',
        description: 'Select the information you want to include in the file.',
        subTitle: 'Client status:',
        subDescription: 'Select the statuses to include in the file.',
        checkboxes: {
          all: 'All',
          pending: 'Pending approval ',
          approved: 'Credit approved',
          denied: 'Credit denied',
        },
      },
      buttons: {
        back: 'Back',
        confirm: 'Generate and download file',
      },
      toasts: {
        info: 'File is generating and may take a few seconds. It will download automatically when it is finished.',
      },
    },
    errors: {
      412: {
        description:
          'Another file is already in process. You need to wait until its done before uploading a new file.',
      },
    },
  },
  editRecommendation: {
    title: 'Edit credit recommendation',
    description:
      'The recommendations are based on POC data analysis. When editing a credit recommendation, you must follow some conditions.',
    buttons: {
      cancel: 'Cancel',
      apply: 'Apply changes',
      tooltip: 'Edit status',
      edit: { recommendation: 'Edit recommendation', conditions: 'Edit conditions' },
    },
    toasts: { success: '{ value } credit conditions were changed.' },
    inputs: {
      creditLimit: {
        label: 'Credit limit',
        hint: 'Suggested: { value }.',
      },
      term: {
        label: 'Credit term',
        hint: 'Suggested: {value, plural, =1 {# day} other {# days}}.',
      },
      fee: {
        label: 'Credit fee',
      },
    },
  },
  approveRecommendation: {
    title: {
      single: 'Approve credit recommendation?',
      multiple: 'Approve { counter } credit recommendations selected?',
    },
    description: {
      single: 'Select the option for this POC.',
      multiple:
        'You will approve the credit recommendation for { counter } POCs. This action cannot be undone',
    },
    counter: '{ counter } POCs selected',
    info: {
      title: 'Request POC onboarding',
      description:
        'Phone number, POC ID, proof of address and the acceptance of terms and conditions will be requested.',
    },
    radios: { request: 'Request', doNotRequest: "Don't request" },
    buttons: { back: 'No, cancel', approve: 'Yes, approve' },
    warning: "If you don't request the onboarding, you will be responsible for this decision.",
  },
  denyRecommendation: {
    title: 'Deny { counter } credit recommendations selected?',
    description:
      'You will deny the credit recommendation for { counter } POCs. This action cannot be undone.',
    button: 'Yes, deny',
  },
  offerCredit: {
    title: 'Offer credit?',
    tooltip: 'Offer credit.',
    warning:
      'Based on the information available, there is no credit recommendation for this POC. Any credit decisions made will be undertaken at your own discretion.',
    info: {
      title: 'Request POC onboarding',
      description:
        'Phone number, POC ID, proof of address, and an acceptance of terms and conditions will be requested.',
    },
    radios: { request: 'Request', doNotRequest: "Don't request" },
    buttons: {
      cancel: 'Go back',
      apply: 'Offer credit',
    },

    toasts: { success: 'Credit offered to { value }.' },
    inputs: {
      creditLimit: {
        label: 'Credit limit',
        placeholder: 'Enter a value',
      },
      term: {
        label: 'Credit term',
        placeholder: 'days',
      },
      fee: {
        label: 'Credit fee',
      },
    },
  },
  editCreditLineModal: {
    title: 'Edit credit line',
    subtitle: '{ pocName }  |  ID: { accountId }',
    description:
      "Editing the current credit line of the POC requires careful consideration. Changes to the credit line directly impact the POC's orders, so proceed cautiously.",
    inputs: {
      creditLimit: 'Credit limit',
      maximumCrediTerm: 'Maximum credit term',
      creditFee: 'Monthly credit fee',
    },
    buttons: {
      back: 'Go back',
      apply: 'Apply changes',
    },
    alert:
      'Editing a credit line does not update it instantly. Change requests go through the Processing tab before they become effective.',
    toasts: {
      success: 'Credit line edited. Changes may take some time to process.',
      error: 'Error editing the credit line for { pocName }. Try again.',
    },
  },
  blockCreditLine: {
    single: {
      title: 'Block credit line?',
      subtitle: '{ pocName }  |  ID: { accountId }',
      description:
        'The POC will be unable to use the credit line for its orders until it resolves its pending issues or is manually unblocked.',
      dropdown: { hint: 'Select a reason for blocking this credit line.' },
      buttons: { cancel: 'Go back', apply: 'Block credit line' },
      toasts: {
        success:
          'The credit line for { pocName } is being blocked. This may take some time to process.',
        error: 'Error blocking the credit line for { pocName }. Try again.',
      },
    },
    multiple: {
      title: 'Block credit lines?',
      subtitle: '{ counter } POCs selected',
      description:
        'These POCs will be unable to use the credit line for their orders until they resolve their pending issues or are manually unblocked.',
      dropdown: { hint: 'Select a reason for blocking these credit lines.' },
      buttons: { cancel: 'Go back', apply: 'Block credit lines' },
      toasts: {
        success:
          'The credit lines for { counter } POCs are being blocked. This may take some time to process.',
        error: 'Error blocking the credit lines for { counter } POCs. Try again.',
      },
    },
    alert:
      'The block may take up to 24 hours to complete. Track the progress in the Processing tab.',
    dropdown: {
      label: 'Block reason',
      placeholder: 'Select reason',
      labels: {
        LOW_SCORE: 'Low score',
        PAYMENT_OVERDUE: 'Payment overdue',
        OTHER: 'Other',
      },
    },
  },
  unblockCreditLine: {
    single: {
      title: 'Unblock credit line?',
      subtitle: '{ pocName }  |  ID: { accountId }',
      description: 'The POC will be able to use the credit line normally again.',
      buttons: { cancel: 'Go back', apply: 'Unblock credit line' },
      toasts: {
        success:
          'The credit line for { pocName } is being unblocked. This may take some time to process.',
        error: 'Error unblocking the credit line for { pocName }. Try again.',
      },
      alert:
        "Unblocking a credit line isn't immediate. Change requests go through the Processing tab before they become effective.",
    },
    multiple: {
      title: 'Unblock credit lines?',
      subtitle: '{ counter } POCs selected',
      description: 'These POCs will be able to use the credit line normally again.',
      buttons: { cancel: 'Go back', apply: 'Unblock credit lines' },
      toasts: {
        success:
          'The credit lines for { counter } POCS are being unblocked. This may take some time to process.',
        error: 'Error unblocking the credit lines for { counter } POCs. Try again.',
      },
      alert:
        "Unblocking credit lines isn't immediate. Change requests go through the Processing tab before they become effective.",
    },
  },
  cancelCreditLine: {
    single: {
      title: 'Cancel credit line?',
      subtitle: '{ pocName }  |  ID: { accountId }',
      description:
        'The credit conditions will be deleted, and the POC will no longer have a credit line. This action cannot be undone.',
      dropdown: { hint: 'Select a reason to cancel this credit line.' },
      buttons: { cancel: 'Go back', apply: 'Cancel credit line' },
      toasts: {
        success:
          'The credit line for { pocName } is being canceled. This may take some time to process.',
        error: 'Error cancelling the credit line for { pocName }. Try again.',
      },
    },
    multiple: {
      title: 'Cancel credit lines?',
      subtitle: '{ counter } POCs selected',
      description:
        'The credit conditions will be deleted, and the POCs will no longer have a credit line. This action cannot be undone.',
      dropdown: { hint: 'Select a reason to cancel these credit lines.' },
      buttons: { cancel: 'Go back', apply: 'Cancel credit lines' },
      toasts: {
        success:
          'The credit lines for { counter } POCs are being canceled. This may take some time to process.',
        error: 'Error canceling the credit lines for { counter } POCs. Try again.',
      },
    },
    alert:
      'The cancellation may take up to 24 hours to be completed. Track the progress in the Processing tab.',
    dropdown: {
      label: 'Cancellation reason',
      placeholder: 'Select reason',
      labels: {
        INACTIVE_ACCOUNT: 'Inactive account',
        LATE_PAYMENT: 'Late payment',
        LEGAL_REASONS: 'Legal reasons',
        LOW_SCORE: 'Low score',
        OTHER: 'Other',
      },
    },
  },
  creditLineButtons: {
    cancel: 'Cancel credit lines',
    block: 'Block credit lines',
    unblock: 'Unblock credit lines',
  },
  loading: {
    default: 'We are taking you to the screen',
    optimizely: 'Preparing your experience',
  },
  errors: {
    default: 'Something went wrong.',
    generic: 'Something went wrong. Try reload the page.',
    403: {
      title: 'Hmm... Access denied!',
      description: 'Unfortunately, you do not have permission to access this page.',
      button: 'Back',
    },
    404: {
      title: 'Hmm… Looks like this page is gone!',
      description: "It looks like this page wasn't found or doesn't exist.",
      button: 'Back',
    },
    500: {
      title: 'Ooops! Something went wrong',
    },
    503: {
      title: 'Ooops! Something went wrong',
      description: "We're having some trouble loading this page. Please, try again.",
      info: '',
      button: 'Try again',
    },
    custom: {
      buttons: { back: 'Go back', reload: 'Reload page' },
      'not-enabled': {
        both: {
          title: 'Country and vendor not enabled',
          description:
            'You do not have access to the selected country and vendor. Change your selection or request access.',
        },
        country: {
          title: 'Country not enabled',
          description:
            'You do not have access to the selected country. Change your selection or request access.',
        },
        vendor: {
          title: 'Vendor not enabled',
          description:
            'You do not have access to the selected vendor. Change your selection or request access.',
        },
      },
      'not-available': {
        title: 'Country not available',
        description:
          'The selected country is not yet available in this application. Select another.',
      },
      'something-went-wrong': {
        title: 'Something went wrong',
        description: 'We had trouble loading this page. Try reloading.',
      },
    },
  },
  source: {
    validations: {
      errors: {
        'error:term:zero': 'Must be {value, plural, =1 {# day} other {# days}} minimum.',
        'error:term:small': 'Must be {value, plural, =1 {# day} other {# days}} maximum..',
        'error:term:fee:big': 'Must be { value }% maximum.',
        'error:term:fee:negative': 'Must be equal to or greater than { value }%.',
        'error:limit:big': 'Maximum { value }% of the recommended amount.',
        'error:limit:small': 'Must be greater than or equal to { value }.',
      },
    },
  },
  approval: {
    buttons: {
      APPROVED: {
        single: 'Approve',
        multiple: 'Approve selected',
        tooltip: 'Approve credit line',
      },
      DENIED: {
        single: 'Deny',
        multiple: 'Deny selected',
        tooltip: 'Deny credit line',
      },
    },
    toasts: {
      APPROVED: {
        single: '{ value } credit line is approved!',
        multiple: 'Credit line for { value } clients is approved!',
      },
      DENIED: {
        single: '{ value } credit line has been denied!',
        multiple: 'Credit line for { value } clients is denied!',
      },
    },
    errors: {
      412: {
        description:
          'An update is already in process. You need to wait until its done before making a new update.',
      },
    },
  },
  massApproval: {
    toasts: {
      APPROVED: "Approving { value } recommendations. You'll be informed when finished.",
      DENIED: "Denying { value } recommendations. You'll be informed when finished.",
      recommendationSuccess: 'Credit conditions updated for { value } POCs.',
      errorApproved: 'Error to update credit conditions for { value } POCs. Try again.',
      issueMessage:
        '{ value } out of { total } POCs had their credit conditions updated. { issue }  had issues, try again.',
    },
  },
  notifications: {
    analysis: {
      info: "{ fileName } is in analysis. This may take some time and you'll be notified once it is completed.",
      warning:
        'The analysis of { fileName } has been completed. Check results to update POCs and correct errors.',
      success: 'The analysis of { fileName } has been completed. Check results to update POCs.',
      failed: 'Unable to complete analysis of file { fileName }. Check results and try again.',
    },
    update: {
      info: "Updating { pocAmount } POC credit conditions. This may take some time and you'll be notified once it is completed.",
      success: '{ pocAmount } POCs credit conditions from { fileName } successfully updated.',
      failed:
        'Unable to update credit conditions from file { fileName }. Check results and try again.',
    },
    firstAction: 'Show results',
  },
};

export default enUS;
