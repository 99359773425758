import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FilterAction,
  MoveState,
  PaginationAction,
  SearchAction,
  SelectedAction,
  TablesState,
} from '../../interfaces';
import { getInitialTables } from '../../utils/getInitialTables';

const initialState = getInitialTables<TablesState>({
  pagination: { page: 1, size: 100, total: 0 },
  filters: {},
  search: '',
  selectable: {
    type: '',
    mode: '',
    selected: [],
    exclusions: [],
    rows: [],
  },
});

const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    reset: () => initialState,
    move: (state: TablesState, action: PayloadAction<MoveState>) => {
      const { table, move } = action.payload;

      const selectable = state[table].selectable;

      selectable[move.to] = selectable[move.to].concat(selectable[move.from]);
      selectable[move.from] = [];
      selectable.rows = [];
    },
    setPagination: (state: TablesState, action: PayloadAction<PaginationAction>) => {
      const { table, page, size, total } = action.payload;

      state[table].pagination = { page, size, total };
    },
    setFilters: (state: TablesState, action: PayloadAction<FilterAction>) => {
      const { table, filters } = action.payload;

      state[table].filters = filters;
    },
    setSearch: (state: TablesState, action: PayloadAction<SearchAction>) => {
      const { table, search } = action.payload;

      state[table].search = search;
    },
    setSelectable: (state: TablesState, action: PayloadAction<SelectedAction>) => {
      const { table, selected, exclusions, rows, isExclusions, identifierKey, type, mode } =
        action.payload;

      const selectable = state[table].selectable;

      selectable.type = type;
      selectable.mode = mode;
      selectable.selected = selected;
      selectable.exclusions = exclusions;

      if (isExclusions) {
        selectable.rows = selectable.rows.filter((row) => !exclusions.includes(row[identifierKey]));
        return;
      }

      if (!rows?.length) selectable.rows = [];
      else {
        selectable.rows = selectable.rows
          .concat(rows)
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t[identifierKey] === item[identifierKey])
          );
      }
    },
  },
});

export const { setPagination, setFilters, setSearch, setSelectable, reset, move } =
  tablesSlice.actions;

export default tablesSlice.reducer;
