import React from 'react';
import { useApplication } from '../../../hooks/useApplication';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useWebSocket } from '../../../hooks/useWebSocket';

export const WsNotification: React.FC = () => {
  const { authHeader } = useApplication();

  const { user, selected } = useGetUserPreferences();

  useWebSocket({
    path: '/ws',
    params: {
      status: 'PENDING,ANALYZING,PROCESSING',
      origin: 'BU,ASYNC_UPDATE',
      userId: user.id,
    },
    data: {
      token: authHeader,
      country: selected.country,
      vendorId: selected.vendorId,
    },
  });

  return null;
};
