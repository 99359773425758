import { useDispatch, useSelector } from 'react-redux';
import { FilterState } from '../../components/atoms/Table/Filter/Context/FilterContext.types';
import { AppState, Tables } from '../../interfaces';
import {
  setCompletedFilterSelected as setterCompletedFilterSelected,
  setCompletedSearchValue as setterCompletedSearchValue,
  setInProgressFilterSelected as setterInProgressFilterSelected,
  setInProgressSearchValue as setterInProgressSearchValue,
  setPendingFilterSelected as setterPendingFilterSelected,
  setPendingSearchValue as setterPendingSearchValue,
} from '../ducks/filterDuck';

export const useFilterStore = () => {
  const dispatch = useDispatch();

  const filterStored = useSelector((state: AppState) => state.filter.filter);
  const searchStored = useSelector((state: AppState) => state.filter.search);
  const setFilterStored = (tab: string, filter: FilterState) => {
    switch (tab) {
      case Tables.PENDING:
        dispatch(setterPendingFilterSelected(filter));
        break;
      case Tables.INPROGRESS:
        dispatch(setterInProgressFilterSelected(filter));
        break;
      case Tables.COMPLETED:
        dispatch(setterCompletedFilterSelected(filter));
        break;
      default:
        break;
    }
  };

  const setSearchStored = (tab: string, search: string) => {
    switch (tab) {
      case Tables.PENDING:
        dispatch(setterPendingSearchValue(search));
        break;
      case Tables.INPROGRESS:
        dispatch(setterInProgressSearchValue(search));
        break;
      case Tables.COMPLETED:
        dispatch(setterCompletedSearchValue(search));
        break;
      default:
        break;
    }
  };

  return { filterStored, setFilterStored, setSearchStored, searchStored };
};
