import { Tables } from '../../interfaces';

interface SelectedMapping {
  key?: string;
  identifier: { key: string; mapkey: string };
}

export const keys: { [key: string]: string } = {
  cm_configs: 'cm_configs',
  cm_configs_v2: 'cm_configs_v2',
  cm_has_not_recommended: 'cm_has_not_recommended',
  cm_has_custom_views: 'cm_has_custom_views',
  cm_has_async_approval: 'cm_has_async_approval',
  cm_has_ws_notifications: 'cm_has_ws_notifications',
  cm_has_management: 'cm_has_management',
  cm_has_massive_actions: 'cm_has_massive_actions',
};

export const selectedMapping: { [key: string]: SelectedMapping } = {
  [Tables.PENDING]: {
    key: keys.cm_has_async_approval,
    identifier: { key: 'beesAccountId', mapkey: 'bees_account_id' },
  },
  [Tables.ACTIVE]: {
    key: keys.cm_has_massive_actions,
    identifier: { key: 'beesAccountId', mapkey: 'bees_account_id' },
  },
  [Tables.BLOCKED]: {
    key: keys.cm_has_massive_actions,
    identifier: { key: 'beesAccountId', mapkey: 'bees_account_id' },
  },
};
