import { Alert } from '@hexa-ui/components';
import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 24px;
  max-width: 624px;
`;

export const AlertStyled = styled(Alert)`
  width: 100%;
  border: solid 1px rgba(161, 208, 255, 1);
  box-sizing: border-box;
  padding: 12px;

  div {
    font-size: 16px;
  }
`;
