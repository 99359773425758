import { Button, Divider, Heading, Paragraph, Select } from '@hexa-ui/components';
import { Lock } from '@hexa-ui/icons';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRequester } from '../../../hooks/useRequester';
import { useToast } from '../../../hooks/useToast';
import { ManagementItem } from '../../../interfaces';
import { blockCreditLine } from '../../../services';
import { Modal } from '../Modal/Modal';
import { AlertStyled, ModalContent } from './styles';

type BlockCreditLineModalProps = {
  creditLineData: Pick<ManagementItem, 'accountId' | 'pocName' | 'id'>;
  onClose?: () => void;
  mode?: 'single' | 'multiple';
};

const blockReasonsData = {
  LOW_SCORE: 'blockCredit.dropdown.reasonToBlock.lowScore',
  PAYMENT_OVERDUE: 'blockCredit.dropdown.reasonToBlock.paymentOverdue',
  OTHER: 'blockCredit.dropdown.reasonToBlock.other',
};

export const BlockCreditLineModal: React.FC<BlockCreditLineModalProps> = ({
  creditLineData,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const { toast } = useToast();
  const { post } = useRequester();
  const [selectedBlockReason, setSelectedBlockReason] = useState();

  const { pocName, accountId } = creditLineData;

  const blockReasons = Object.keys(blockReasonsData).map((key) => ({
    value: key,
    label: blockReasonsData[key],
  }));

  const handleReset = () => {
    setSelectedBlockReason(undefined);
    onClose();
  };

  const handleBlockCreditLine = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const response = await blockCreditLine({
      api: { post },
      params: {
        id: creditLineData.id,
        blockReason: selectedBlockReason,
      },
    });

    if (!response?.success) {
      toast.error({
        message: 'blockCredit.toasts.error',
        attributes: { pocName },
      });
      handleReset();
      return;
    }

    toast.success({
      message: 'blockCredit.toasts.success',
      attributes: { pocName },
    });
    handleReset();
  };

  return (
    <Modal
      portal={{ has: true, container: document.body }}
      open={{ value: true, setter: () => {} }}
      title={<Heading size="H2">{formatMessage({ id: 'blockCredit.title' })}</Heading>}
      actions={{
        buttons: {
          cancel: (
            <Button id="on-cancel" size="large" variant="secondary" onClick={handleReset}>
              {formatMessage({ id: 'blockCredit.buttons.cancel' })}
            </Button>
          ),
          confirm: (
            <Button
              id="on-apply"
              size="large"
              variant="primary"
              leading
              icon={Lock}
              disabled={!selectedBlockReason}
              onClick={handleBlockCreditLine}
            >
              {formatMessage({ id: 'blockCredit.buttons.apply' })}
            </Button>
          ),
        },
      }}
      onClose={handleReset}
      onInteractOutside={handleReset}
      headerHeight="4.5rem"
    >
      <ModalContent>
        <Heading size="H4" css={{ marginTop: '8px', whiteSpace: 'pre' }}>
          {`${pocName}  |  ID: ${accountId}`}
        </Heading>
        <Paragraph>{formatMessage({ id: 'blockCredit.description' })}</Paragraph>
        <Divider />
        <div>
          <Select.Root
            data-testid="block-reason-select"
            placeholder={formatMessage({ id: 'blockCredit.dropdown.reasonToBlock.placeholder' })}
            label={formatMessage({ id: 'blockCredit.dropdown.reasonToBlock.label' })}
            hint={formatMessage({ id: 'blockCredit.dropdown.reasonToBlock.hint' })}
            width="296px"
            value={selectedBlockReason}
            onChange={(value) => setSelectedBlockReason(value)}
          >
            {blockReasons?.map((option) => {
              return (
                <Select.Option key={option.value} value={option.value}>
                  {formatMessage({ id: option.label })}
                </Select.Option>
              );
            })}
          </Select.Root>
        </div>

        <AlertStyled type="info" message={formatMessage({ id: 'blockCredit.alert' })} />
      </ModalContent>
    </Modal>
  );
};
