import { ManagementModalData } from '../../../interfaces';

export enum CancelReasonsTypes {
  INACTIVE_ACCOUNT = 'INACTIVE_ACCOUNT',
  LATE_PAYMENT = 'LATE_PAYMENT',
  LEGAL_REASONS = 'LEGAL_REASONS',
  LOW_SCORE = 'LOW_SCORE',
  OTHER = 'other',
}

interface BaseCancelModalProps {
  trigger?: React.ReactNode;
  counter?: number;
  onClose?: () => void;
  callbackFn?: () => Promise<void>;
}

interface SingleCancelModalProps extends BaseCancelModalProps {
  mode: 'single';
  data: ManagementModalData;
}

interface MultipleCancelModalProps extends BaseCancelModalProps {
  mode: 'multiple';
  counter: number;
  data: ManagementModalData[];
}

export type CancelCreditLineModalProps = SingleCancelModalProps | MultipleCancelModalProps;
