import { ChevronDown, ChevronUp } from '@hexa-ui/icons';
import { CollapseButtonProps } from './CollapseButton.types';
import { Button, ButtonContent, Container, CustomParagraph } from './styles';

export const CollapseButton: React.FC<CollapseButtonProps> = ({
  open,
  label,
  icon,
  children,
  disabled = false,
  style,
}) => {
  const handleClick = () => {
    open.setter(!open.value);
  };

  return (
    <Container data-testid="collapse-button-container">
      <Button
        variant="secondary"
        leading
        icon={icon}
        disabled={disabled}
        onClick={handleClick}
        style={style}
      >
        <ButtonContent>
          <CustomParagraph size="basis" weight="medium" $width={style?.width}>
            {label}
          </CustomParagraph>

          {!open.value ? <ChevronDown size="medium" /> : <ChevronUp size="medium" />}
        </ButtonContent>
      </Button>

      {open.value && children}
    </Container>
  );
};
