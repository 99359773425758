import { Paragraph } from '@hexa-ui/components';
import { EmptyMessageProps } from './EmptyMessage.types';
import { EmptyMessageContainer, EmptyMessageWrapper } from './styles';

const normalizeMessage = (message: string | string[]) => {
  return typeof message === 'string' ? [message] : message;
};

export const EmptyMessage: React.FC<EmptyMessageProps> = ({ message, Icon, maxWidth }) => {
  const normalizedMessage = normalizeMessage(message);

  return (
    <EmptyMessageContainer data-testid="empty-message-container">
      <EmptyMessageWrapper $maxwidth={maxWidth}>
        {Icon && <Icon width="128px" />}

        <div>
          {normalizedMessage.map((text, index) => (
            <Paragraph
              size="small"
              alignment="center"
              css={{ lineHeight: '20px' }}
              key={`${text}-${index}`}
            >
              {text}
            </Paragraph>
          ))}
        </div>
      </EmptyMessageWrapper>
    </EmptyMessageContainer>
  );
};
