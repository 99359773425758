import React from 'react';

export const XIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      data-testid="x-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3646 7.05086C18.7551 6.66033 18.7551 6.02717 18.3646 5.63664C17.974 5.24612 17.3409 5.24612 16.9504 5.63664L12.0004 10.5866L7.05087 5.63698C6.66035 5.24646 6.02718 5.24646 5.63666 5.63698C5.24614 6.0275 5.24614 6.66067 5.63666 7.05119L10.5862 12.0008L5.63664 16.9504C5.24612 17.3409 5.24612 17.974 5.63664 18.3646C6.02717 18.7551 6.66033 18.7551 7.05086 18.3646L12.0004 13.415L16.9504 18.3649C17.3409 18.7554 17.9741 18.7554 18.3646 18.3649C18.7551 17.9744 18.7551 17.3412 18.3646 16.9507L13.4147 12.0008L18.3646 7.05086Z"
        fill="#141414"
      />
    </svg>
  )
);
