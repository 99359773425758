import { Button, IconButton, LoadingDots, Tooltip } from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { keys } from '../../../../constants';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { Status } from '../../../../interfaces';
import SegmentService from '../../../../services/segment';
import { DenyRecommendation } from '../../DenyRecommendation/DenyRecommendation';
import { ApprovalProps } from '../ApprovalButtons.types';

export const Deny: React.FC<ApprovalProps> = ({
  mode,
  type,
  button,
  isLoading,
  onApproval,
  selected,
}) => {
  const { formatMessage } = useIntl();
  const sellerSelected = useGetUserPreferences().selected;
  const { permissions, toggles } = useGetUserPreferences();

  const onDeny = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>, status: Status) => {
      event.stopPropagation();

      await onApproval(status);
    },
    [onApproval]
  );

  const buttonClicked = (buttonLabel: string, buttonName: string, event: string) => {
    SegmentService.paymentsButtonClicked(
      buttonLabel,
      buttonName,
      sellerSelected?.vendorId,
      event,
      'CREDIT_MANAGEMENT_PENDING_TAB'
    );
  };

  const buttonMapping = useMemo(() => {
    return {
      'icon-button': (
        <IconButton
          id="deny-icon-button"
          data-testid="deny-icon-button"
          icon={() => (
            <Tooltip
              placement="bottom"
              text={formatMessage({ id: 'approval.buttons.DENIED.tooltip' })}
            >
              <X size="medium" />
            </Tooltip>
          )}
          size={button.size}
          variant="tertiary"
          loading={isLoading}
          disabled={isLoading}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            if (toggles.has(keys.cm_has_async_approval) && mode === 'multiple') {
              return;
            }
            onDeny(event, Status.DENIED);
            buttonClicked('Deny Icon', 'Deny Icon Button', 'Credit Management Deny Recommendation');
          }}
        />
      ),
      button: (
        <Button
          id="deny-button"
          data-testid="deny-button"
          leading
          icon={() =>
            isLoading ? <LoadingDots data-testid="loading-dots" /> : <X size="medium" />
          }
          size={button.size}
          variant="secondary"
          disabled={isLoading}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            if (toggles.has(keys.cm_has_async_approval) && mode === 'multiple') {
              return;
            }
            onDeny(event, Status.DENIED);
            buttonClicked(
              'Deny Selected',
              'Deny Selected Button',
              'Credit Management Mass Approval Deny Selected'
            );
          }}
        >
          {formatMessage({ id: `approval.buttons.DENIED.${mode}` })}
        </Button>
      ),
    };
  }, [isLoading, button]);

  if (!permissions.has('actions.deny')) {
    return null;
  }

  if (toggles.has(keys.cm_has_async_approval) && mode === 'multiple') {
    return (
      <DenyRecommendation
        onApproval={onApproval}
        data={mode === 'multiple' ? { counter: selected.length } : selected}
        trigger={buttonMapping[type]}
      />
    );
  }

  return buttonMapping[type];
};
