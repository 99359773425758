import { Tables } from '../interfaces';

type InitialTables<T = any> = {
  [table in Tables]: T;
};

export const getInitialTables = <R = InitialTables>(initial = {}): R => {
  return Object.values(Tables).reduce((acc, table) => {
    acc[table] = initial;

    return acc;
  }, {} as R);
};
