import { EmptyState } from '@hexa-ui/illustrations';
import { RowSelectionState, SortingState, Updater } from '@tanstack/react-table';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGetTables } from '../../../../hooks/useGetTables';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { DataType, ManagementItem, SelectedData } from '../../../../interfaces';
import { getManagementData } from '../../../../services';
import SegmentService from '../../../../services/segment';
import { useTableStore } from '../../../../store/hooks/tablesHook';
import { EmptyMessageProps } from '../../../atoms/Table/Empty/EmptyMessage.types';
import { Table } from '../../../atoms/Table/Table';
import ActiveCreditLineModalsWrapper from '../../../molecules/ActiveCreditLineModalsWrapper/ActiveCreditLineModalsWrapper';
import { CreditLineButtons } from '../../../molecules/CreditLineButtons/CreditLineButtons';
import { useManagement } from '../../../pages/Management/Context/ManagementContext';
import { CallbackRef, ManagementTableProps } from './ManagementTable.types';

export const ManagementTable: React.FC<ManagementTableProps> = ({ type }) => {
  const { formatMessage } = useIntl();
  const { get } = useRequester();

  const navigate = useNavigate();

  const { filters, columns, reload, recommended } = useGetTables(type);
  const { configs, selected: selectedHeader } = useGetUserPreferences();
  const { tables } = configs;

  const { hasApproval } = useManagement();

  const { pagination: storedPagination, filters: storedFilters, selectable } = useTableStore(type);

  const isFirstRender = useRef<boolean>(true);
  const callbackFn = useRef<CallbackRef>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assessments, setAssessments] = useState<ManagementItem[]>([]);
  const [filtered, setFiltered] = useState<string>('');
  const [empty, setEmpty] = useState<EmptyMessageProps>({
    message: formatMessage({ id: 'tables.empty' }),
    Icon: EmptyState.NoDataYet,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    size: 100,
    total: 0,
  });

  const [selectedRow, setSelectedRow] = useState<ManagementItem>();
  const [openModal, setOpenModal] = useState({
    cancelCreditLine: false,
    blockCreditLine: false,
    editCreditLine: false,
  });

  const requestController = useRef(new AbortController());
  const selectableState = useRef<RowSelectionState>({});
  const sortingState = useRef<SortingState>([]);

  const onOpenActionModals = (selected: ManagementItem, modal: string) => {
    setSelectedRow(selected);
    setOpenModal((prev) => ({ ...prev, [modal]: true }));
  };

  const onCloseActionModals = () =>
    setOpenModal({ cancelCreditLine: false, blockCreditLine: false, editCreditLine: false });

  const onFilter = async (filters: string) => {
    setPagination((prev) => ({ ...prev, page: 1 }));
    setFiltered(filters);

    await getData({ filters, search: '' });
  };

  const onRow = (row: DataType[typeof type]) => {
    SegmentService.paymentsButtonClicked(
      'Credit Line',
      'Credit Line Row',
      selectedHeader.vendorId,
      'Credit Management Access POC Details',
      `CREDIT_MANAGEMENT_${type.toUpperCase()}_TAB`
    );

    navigate(
      `/bees-credit-management/poc-details/${row.assessmentId}?vendorId=${selectedHeader.vendorId}&country=${selectedHeader.country}&projection=RECOMMENDATION`
    );
  };

  const onPagination = (page: number, size?: number) => {
    setPagination((prev) => ({ ...prev, page, size: size ?? prev.size }));
  };

  const mappedRows = (rows: ManagementItem[]) =>
    rows.map((row) => ({ beesAccountId: row.beesAccountId, pocName: row.pocName }));

  const onSelected = (data: SelectedData<ManagementItem>) => {
    if (!data) {
      selectable.clear();
      return;
    }

    const { mode, checked, rows } = data;

    if (checked) {
      selectable.selected.add({
        mode,
        identifiers: rows.map((row) => row.beesAccountId),
        ...(mode === 'single' && { rows: mappedRows(rows) }),
      });

      if (mode === 'multiple' && rows.length > 1) {
        selectable.move({ from: 'exclusions', to: 'selected' });
      }

      return;
    }

    if (!mode && (rows.every((row) => !row) || selectable.selected.value.size === 1)) {
      Object.keys(selectableState.current).forEach((key) => {
        selectableState.current[key] = false;
      });

      selectable.clear();
      return;
    }

    const { beesAccountId, pocName } = rows[0];

    selectable.selected.remove({
      mode,
      identifiers: [beesAccountId],
      rows: [{ beesAccountId, pocName }],
    });
  };

  const onCallback = useCallback(async () => {
    await getData({ filters: filtered || '', search: '' });

    selectable.clear();
    hasApproval.setter(!hasApproval.value);
  }, [filtered, recommended, pagination.size, pagination.page]);

  const toolbarExtra = useCallback(
    () => (
      <>
        <CreditLineButtons
          mode="multiple"
          type="button"
          button={{ size: 'small' }}
          counter={selectable.counter}
          selected={selectable.rows}
          callbackFn={onCallback}
        />
      </>
    ),
    [selectable.rows, selectable.counter]
  );

  const getData = async ({ filters = '', search = '' }) => {
    setIsLoading(true);

    const response = await getManagementData({
      api: { get },
      params: {
        status: tables[type].status,
        search,
        filters,
        pagination,
        sort: sortingState.current,
      },
      config: { signal: requestController.current.signal },
    });

    if (!response?.success) {
      setAssessments([]);
      setIsLoading(false);
      setEmpty({
        message: [
          formatMessage({ id: 'tables.error.somethingWentWrong' }),
          formatMessage({ id: 'tables.error.tryReloading' }),
        ],
        Icon: EmptyState.SomethingWentWrong,
      });

      return;
    }

    if (response.data.length === 0) {
      setAssessments([]);
      setIsLoading(false);
      return;
    }

    const { data, pageable } = response.data;

    data.forEach((selected: ManagementItem) => {
      selected.actions = { selected, onOpenActionModals, callbackFn: callbackFn.current };
    });

    selectableState.current = data.reduce((acc, row) => {
      acc[row.beesAccountId] = selectable.mode === 'multiple';
      return acc;
    }, {});

    if (
      selectable.type === 'async' &&
      selectable.mode === 'multiple' &&
      !data.some((row) => selectable.exclusions.value.has(row.beesAccountId))
    ) {
      selectable.selected.add({
        mode: 'multiple',
        identifiers: data.map((row) => row.beesAccountId),
      });
    }

    setAssessments(data);
    setPagination((prev) => ({ ...prev, total: pageable.total }));

    storedPagination.setter(pagination.page, pagination.size, pageable.total);
    setIsLoading(false);
  };

  const onSelectedClear = (state: RowSelectionState) => {
    Object.keys(state).forEach((key) => (state[key] = false));

    onPagination(1);
  };

  const onSorting = (updater: Updater<SortingState>) => {
    const updatedValue = updater instanceof Function ? updater(sortingState.current) : updater;

    sortingState.current = updatedValue;
    getData({ filters: '', search: '' });
  };

  const selectableConfig = useMemo(() => {
    const state = selectableState.current;

    Array.from(selectable.selected.value).forEach((id) => (state[id] = true));
    Array.from(selectable.exclusions.value).forEach((id) => (state[id] = false));

    return {
      has: true,
      state,
      mode: selectable.mode,
      counter: selectable.counter,
      identifier: selectable.identifier,
      onSelected: onSelected,
      onClear: onSelectedClear,
    };
  }, [
    selectable.mode,
    selectable.counter,
    selectable.identifier,
    selectableState.current,
    selectable.selected.value,
    selectable.exclusions.value,
  ]);

  useEffect(() => {
    if (Object.keys(storedFilters?.value ?? {}).length > 0 && isFirstRender.current) {
      return;
    }

    getData({ filters: filtered || '', search: '' });
  }, [pagination.page, pagination.size]);

  useEffect(() => {
    if (!isFirstRender.current) {
      SegmentService.paymentsButtonClicked(
        `Items per page: ${pagination.size}`,
        `Items per page: ${pagination.size}`,
        selectedHeader.vendorId,
        'Credit Management User Per Page',
        `CREDIT_MANAGEMENT_${type?.toUpperCase()}_TAB`
      );
    }
  }, [pagination.size]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    getData({});
  }, [reload]);

  useEffect(() => {
    callbackFn.current = onCallback;
  }, [onCallback]);

  return (
    <React.Fragment>
      <ActiveCreditLineModalsWrapper
        isOpen={openModal}
        selected={selectedRow}
        onClose={onCloseActionModals}
      />

      <Table
        table={type}
        data={assessments}
        columns={columns}
        loading={isLoading}
        tableHeight={`min(100vh - ${filtered ? '258px' : '202px'})`}
        onRow={onRow}
        filters={{ has: true, chips: true, config: filters, onFilter, onClear: onPagination }}
        sorting={{ has: true, state: sortingState.current, onSorting }}
        messages={{ empty }}
        emptyStateMaxWidth="128px"
        selectable={selectableConfig}
        toolbarExtra={toolbarExtra}
        pagination={{
          page: pagination.page,
          size: pagination.size,
          total: pagination.total,
          onChange: onPagination,
        }}
      />
    </React.Fragment>
  );
};
