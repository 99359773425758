import React, { useEffect } from 'react';
import { RecommendationsPage as Page } from '../../components/pages';
import { useHeader } from '../../hooks/useHeader';

export const RecommendationsPage: React.FC = () => {
  const { setPageHeader } = useHeader();

  useEffect(() => {
    setPageHeader({ page: 'recommendation' });
  }, []);

  return <Page />;
};
