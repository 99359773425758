import React from 'react';
import { IntlProvider } from 'react-intl';
import { Page } from '../../components/pages/Page/Page';
import { useApplication } from '../../hooks/useApplication';
import { useTranslations } from '../../hooks/useTranslations';
import { flattenObject, messages } from '../../i18n';
import { ProviderProps } from '../../interfaces';

export const CopyDeckProvider: React.FC<ProviderProps & { locale: string }> = ({
  children,
  locale,
}) => {
  const { authHeader } = useApplication();

  const { isLoading, translations, copydeckLocale } = useTranslations({ locale, authHeader });

  const flattenedMessages = translations
    ? flattenObject(JSON.parse(JSON.stringify(translations)))
    : undefined;

  if (isLoading) {
    return <Page.Loading />;
  }

  return (
    <IntlProvider
      locale={copydeckLocale}
      defaultLocale={'en-US'}
      messages={flattenedMessages ?? messages[locale ?? 'en-US']}
    >
      {children}
    </IntlProvider>
  );
};
