import { IconButton } from '@hexa-ui/components';
import { IconProps } from '@hexa-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import { DisabledWrapper } from './styles';

interface CollapseActionsMenuProps {
  identifier?: string;
  disabled?: boolean;
  children: React.ReactNode;
  icon: React.FunctionComponent<IconProps>;
}

export const CollapseActionsMenu: React.FC<CollapseActionsMenuProps> = ({
  identifier,
  disabled,
  children,
  icon: Icon,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const clickOutsideDropdown =
      dropdownRef.current && !dropdownRef.current.contains(event.target as Node);

    if (clickOutsideDropdown) setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <React.Fragment>
      <Dropdown parentRef={buttonRef} isOpen={isOpen} ref={dropdownRef}>
        {children}
      </Dropdown>

      <DisabledWrapper $disabled={disabled} $isopen={isOpen}>
        <IconButton
          id="dots-icon-button"
          ref={buttonRef}
          variant="tertiary"
          size="small"
          disabled={disabled}
          data-testid="dots-icon-button"
          icon={() => <Icon size="medium" />}
          onClick={handleClick}
          {...(identifier && isOpen && { className: identifier })}
        />
      </DisabledWrapper>
    </React.Fragment>
  );
};
