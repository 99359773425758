import { Tabs } from '@hexa-ui/components';
import styled from 'styled-components';

export const ManagementPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  row-gap: 16px;
`;

export const Root = styled(Tabs.Root)``;

export const List = styled(Tabs.List)``;

export const Trigger = styled(Tabs.Trigger)``;

export const Content = styled(Tabs.Content)`
  padding: 0;
  margin-top: 16px;
  border-radius: 16px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SearchFieldContainer = styled.div`
  > div {
    height: 40px;
  }
`;
