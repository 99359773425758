import { CreditRecommended, CreditSettings, Files } from '../../components/icons';
import { SideBarItems } from '../../interfaces';

export const sidebar: SideBarItems = {
  management: {
    icon: CreditSettings,
    title: 'routes.management',
    path: '/bees-credit-management/management',
  },
  recommendation: {
    icon: CreditRecommended,
    title: 'routes.recommendation',
    path: '/bees-credit-management/recommendation',
    permission: 'pages.recommendation',
  },
  credit: {
    icon: CreditRecommended,
    title: 'routes.credit',
    path: '/bees-credit-management/credit',
    permission: 'pages.recommendation',
  },
  files: {
    icon: Files,
    title: 'routes.files',
    path: '/bees-credit-management/files',
    permission: 'tables.recommendation.files',
  },
};
