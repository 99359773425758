import { Button, Divider, Heading, Paragraph, Select } from '@hexa-ui/components';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRequester } from '../../../hooks/useRequester';
import { useToast } from '../../../hooks/useToast';
import { ManagementModalData } from '../../../interfaces';
import { cancelCreditLine, cancelCreditLinesByFilter } from '../../../services';
import { Modal } from '../Modal/Modal';
import { CancelCreditLineModalProps, CancelReasonsTypes } from './CancelCreditLineModal.types';
import { AlertStyled, ModalContent } from './styles';

export const CancelCreditLineModal: React.FC<CancelCreditLineModalProps> = ({
  mode,
  data,
  counter,
  trigger,
  onClose,
  callbackFn,
}) => {
  const { formatMessage } = useIntl();

  const { patch } = useRequester();
  const { toast } = useToast();

  const [selectedReason, setSelectedReason] = useState<CancelReasonsTypes>();

  const onClear = () => {
    setSelectedReason(undefined);
    if (onClose) onClose();
  };

  const onCancel = async ({ id, pocName }: ManagementModalData) => {
    const response = await cancelCreditLine({
      api: { patch },
      params: { id, reason: selectedReason },
    });

    if (!response?.success) {
      toast.error({ message: 'cancelCreditLine.single.toasts.error', attributes: { pocName } });

      onClear();
      return;
    }

    toast.success({ message: 'cancelCreditLine.single.toasts.success', attributes: { pocName } });

    onClear();
    if (callbackFn) callbackFn();
  };

  const onCancelByFilter = async (data: ManagementModalData[]) => {
    const response = await cancelCreditLinesByFilter({
      api: { patch },
      params: { data, reason: selectedReason },
    });

    if (!response?.success) {
      toast.error({ message: 'cancelCreditLine.multiple.toasts.error', attributes: { counter } });
      return;
    }

    toast.success({ message: 'cancelCreditLine.multiple.toasts.success', attributes: { counter } });
    if (callbackFn) callbackFn();
  };

  const onApply = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (mode === 'single') await onCancel(data);
    else await onCancelByFilter(data);
  };

  return (
    <Modal
      {...(trigger ? { trigger } : { open: { value: true } })}
      title={<Heading size="H2">{formatMessage({ id: `cancelCreditLine.${mode}.title` })}</Heading>}
      onClose={onClear}
      onInteractOutside={onClear}
      headerHeight="4.5rem"
      actions={{
        buttons: {
          cancel: (
            <Button
              id="on-apply"
              variant="destructive"
              size="large"
              disabled={!selectedReason}
              onClick={onApply}
            >
              {formatMessage({ id: `cancelCreditLine.${mode}.buttons.apply` })}
            </Button>
          ),
          confirm: (
            <Button id="on-cancel" onClick={onClear} size="large" variant="secondary">
              {formatMessage({ id: `cancelCreditLine.${mode}.buttons.cancel` })}
            </Button>
          ),
        },
      }}
    >
      <ModalContent data-testid="modal-content">
        <Heading size="H4" css={{ marginTop: '8px', whiteSpace: 'pre' }}>
          {mode === 'single'
            ? formatMessage(
                { id: 'cancelCreditLine.single.subtitle' },
                { pocName: data.pocName, accountId: data.accountId }
              )
            : formatMessage({ id: 'cancelCreditLine.multiple.subtitle' }, { counter })}
        </Heading>

        <Paragraph size="basis">
          {formatMessage({ id: `cancelCreditLine.${mode}.description` })}
        </Paragraph>

        <Divider />

        <div>
          <Select.Root
            id="select-cancel-reason"
            width="296px"
            label={formatMessage({ id: 'cancelCreditLine.dropdown.label' })}
            hint={formatMessage({ id: `cancelCreditLine.${mode}.dropdown.hint` })}
            placeholder={formatMessage({ id: 'cancelCreditLine.dropdown.placeholder' })}
            value={selectedReason}
            onChange={setSelectedReason}
          >
            {Object.entries(CancelReasonsTypes).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {formatMessage({ id: `cancelCreditLine.dropdown.labels.${key}` })}
              </Select.Option>
            ))}
          </Select.Root>
        </div>

        <AlertStyled type="info" message={formatMessage({ id: 'cancelCreditLine.alert' })} />
      </ModalContent>
    </Modal>
  );
};
