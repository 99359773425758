import { useEffect, useMemo } from 'react';
import { keys } from '../../constants';
import { useGetUserPreferences } from '../../hooks/useGetUserPreferences';
import { CustomViewType, CustomViewsMapping, ProviderProps, Tables } from '../../interfaces';
import { useViews } from '../../store/hooks/customViewsHook';
import { getInitialTables } from '../../utils/getInitialTables';

export const CustomViewsProvider: React.FC<ProviderProps> = ({ children }) => {
  const { toggles } = useGetUserPreferences();

  const { views } = useViews();

  const hasCustomTables = useMemo(() => {
    const initial = getInitialTables<CustomViewsMapping>();

    return Object.values(Tables).reduce((acc, value) => {
      const { structure, type } = views.has(value);
      acc[value] = { has: structure, type };

      return acc;
    }, initial);
  }, [Tables]);

  useEffect(() => {
    Object.entries(hasCustomTables).forEach(([tkey, { has, type }]) => {
      if (
        !has ||
        (!toggles.has(keys.cm_has_custom_views) && type === CustomViewType.CUSTOM) ||
        (!toggles.has(keys.cm_has_not_recommended) && type === CustomViewType.DEFAULT)
      ) {
        views.active([0, CustomViewType.DEFAULT], tkey as Tables);
      }
    });
  }, [hasCustomTables, toggles]);

  return children;
};
