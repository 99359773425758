import { ListItem, Paragraph } from '@hexa-ui/components';
import { MoreHorizontal, Unlock } from '@hexa-ui/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { ActionsTypes } from '../../../../../../interfaces';
import { XIcon } from '../../../../../icons/XIcon/Xicon.svg';
import { CollapseActionsMenu } from '../../../../../molecules/CollapseActionsMenu/CollapseActionsMenu';
import { Wrapper } from '../../../../../molecules/CollapseActionsMenu/styles';
import { ActionsDataProps } from '../Actions.types';
import { EventType, ModalTypes } from './Blocked.types';

export const Blocked: React.FC<ActionsDataProps[ActionsTypes.BLOCKED]> = ({
  selected,
  disabled,
  onOpenActionModals,
}) => {
  const { formatMessage } = useIntl();
  const onTrigger = (event: EventType, type: ModalTypes) => {
    event.stopPropagation();
    onOpenActionModals(selected, type);
  };

  return (
    <CollapseActionsMenu identifier="action-menu-blocked" disabled={disabled} icon={MoreHorizontal}>
      <ListItem
        id="unblock"
        variant="selectable"
        style={{ listStyle: 'none' }}
        onClick={(event: EventType) => onTrigger(event, ModalTypes.UNBLOCK_CREDIT_LINE)}
      >
        <Wrapper>
          <Unlock size="large" />
          <Paragraph>{formatMessage({ id: 'tables.collapseActionsMenu.unblock' })}</Paragraph>
        </Wrapper>
      </ListItem>

      <ListItem
        id="cancel"
        variant="selectable"
        style={{ listStyle: 'none' }}
        onClick={(event: EventType) => onTrigger(event, ModalTypes.CANCEL_CREDIT_LINE)}
      >
        <Wrapper>
          <XIcon />
          <Paragraph>{formatMessage({ id: 'tables.collapseActionsMenu.cancel' })}</Paragraph>
        </Wrapper>
      </ListItem>
    </CollapseActionsMenu>
  );
};
