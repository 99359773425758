import { Button, Paragraph } from '@hexa-ui/components';
import React from 'react';
import { useIntl } from 'react-intl';
import { BlockCreditLineModal } from '../BlockCreditLineModal/BlockCreditLineModal';
import { CancelCreditLineModal } from '../CancelCreditLineModal/CancelCreditLineModal';
import { UnblockCreditLineModal } from '../UnBlockCreditLineModal/UnblockCreditLineModal';
import { CreditLineProps } from './CreditLineButtons.types';
import { CreditLineButtonsContainer } from './styles';

export const CreditLineButtons: React.FC<CreditLineProps> = ({
  button,
  counter,
  selected,
  callbackFn,
  table,
}) => {
  const { formatMessage } = useIntl();
  return (
    <CreditLineButtonsContainer data-testid="credit-lines-buttons-container">
      <CancelCreditLineModal
        mode="multiple"
        data={selected}
        counter={counter}
        callbackFn={callbackFn}
        trigger={
          <Button id="cancel-button" size={button.size} variant="secondary">
            <Paragraph size="small">{formatMessage({ id: 'creditLineButtons.cancel' })}</Paragraph>
          </Button>
        }
      />
      {table === 'active' ? (
        <BlockCreditLineModal
          mode="multiple"
          data={selected}
          counter={counter}
          callbackFn={callbackFn}
          trigger={
            <Button id="block-button" variant="secondary" size={button.size}>
              <Paragraph size="small">{formatMessage({ id: 'creditLineButtons.block' })}</Paragraph>
            </Button>
          }
        />
      ) : (
        <UnblockCreditLineModal
          mode="multiple"
          data={selected}
          counter={counter}
          callbackFn={callbackFn}
          trigger={
            <Button id="unblock-button" variant="secondary" size={button.size}>
              <Paragraph size="small">
                {formatMessage({ id: 'creditLineButtons.unblock' })}
              </Paragraph>
            </Button>
          }
        />
      )}
    </CreditLineButtonsContainer>
  );
};
