import { createContext, useContext, useMemo, useState } from 'react';
import { GetSet } from '../../../../interfaces';

interface ManagementContext {
  hasApproval: GetSet<boolean>;
}

const ManagementContext = createContext<ManagementContext | null>(null);

export const ManagementProvider: React.FC = ({ children }) => {
  const [hasApproval, setHasApproval] = useState<boolean>(false);

  const value = useMemo(
    () => ({ hasApproval: { value: hasApproval, setter: setHasApproval } }),
    [hasApproval]
  );

  return <ManagementContext.Provider value={value}>{children}</ManagementContext.Provider>;
};

export const useManagement = () => useContext(ManagementContext);
