import { useNavigate } from 'react-router-dom';
import { maxLength, notifications } from '../constants';
import {
  NotificationData,
  NotificationsOrigin,
  NotifyActionsProps,
  NotifyProps,
} from '../interfaces';
import { useFormatting } from './useFormatting';
import { useToast } from './useToast';

export const useNotification = () => {
  const navigate = useNavigate();

  const { formatting } = useFormatting();
  const { toast } = useToast();

  const redirectFn = ({ data, attributes }: NotifyActionsProps<'REDIRECT'>) => {
    const { to, params } = attributes;

    const url = params.reduce<string>((acc, param) => {
      return acc.replace(`:${param}`, data[param]);
    }, to);

    return () => navigate(url);
  };

  const notify = (type: NotificationsOrigin, data: NotificationData): void => {
    if (!type || !data?.status || !notifications?.[type]?.[data?.status]) return;

    const fnMapping = { REDIRECT: redirectFn };

    const options = notifications[type][data.status].reduce<NotifyProps>((acc, notification) => {
      if (notification.condition) {
        const { key, type } = notification.condition;

        if (type === 'HAS_NOT' && data[key]) return acc;
        if (type === 'HAS' && !data[key]) return acc;
      }

      acc.toastType = notification.type;
      acc.params = {
        message: notification.message,
        ...(notification.attributes && {
          attributes: notification.attributes.reduce<Record<string, string>>((acc, attribute) => {
            const [key, value] = attribute.split(':');

            const { formatted } = formatting({
              type: 'truncate-text',
              value: data[value],
              config: { length: maxLength },
            });

            acc[key] = formatted.completed;
            return acc;
          }, {}),
        }),
        ...(notification.actions && {
          firstAction: {
            name: notification.actions.name,
            action: fnMapping[notification.actions.type]({
              data,
              attributes: notification.actions.attributes,
            }),
          },
        }),
      };

      return acc;
    }, {});

    if (!Object.keys(options).length) return;

    toast[options.toastType](options.params);
  };

  return { notify };
};
