import { OptimizelyFeature as Feature } from '@optimizely/react-sdk';
import { useCallback } from 'react';
import { NoConfigError } from '../../../components/icons/Error/NoConfig/NoConfig.svg';
import { Page } from '../../../components/pages/Page/Page';
import { keys } from '../../../constants';
import { ProviderProps } from '../../../interfaces';
import { useSelected } from '../../../store/hooks/selectedHook';
import { UserProvider } from './User/UserProvider';

export const OptimizelyFeature: React.FC<ProviderProps> = ({ children }) => {
  const { selected } = useSelected();

  const getUserProvider = useCallback(
    (isEnabled, variables) => {
      if (!isEnabled) {
        return (
          <Page.Error
            type="custom"
            icon={NoConfigError}
            title={'errors.custom.not-available.title'}
            description={'errors.custom.not-available.description'}
          />
        );
      }

      return <UserProvider variables={variables}>{children}</UserProvider>;
    },
    [children, selected.country, selected.vendorId]
  );

  return (
    <Feature feature={keys.cm_has_management} key={`feature-version-${selected.country}`}>
      {(isEnabled) => {
        return (
          <Feature
            feature={isEnabled ? keys.cm_configs_v2 : keys.cm_configs}
            key={`feature-${selected.country}`}
          >
            {getUserProvider}
          </Feature>
        );
      }}
    </Feature>
  );
};
