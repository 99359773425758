import Axios from 'axios';
import { RequestParams } from '../interfaces';
import { useApplication } from './useApplication';
import { useGetUserPreferences } from './useGetUserPreferences';

export const useRequester = () => {
  const { enhancedAxios } = useApplication();

  const { selected } = useGetUserPreferences();

  const headers: Record<string, any> = {
    noorders: localStorage.getItem('noOrders') || '',
    country: selected.country,
    vendorId: selected.vendorId,
  };

  const requestProvider = Axios.create({
    baseURL: process.env.REACT_APP_BFF_URL,
  });

  enhancedAxios(requestProvider, { headers: [headers] });

  const get = async ({ path, config }: RequestParams<'get'>): Promise<any> => {
    return requestProvider.get(path, config);
  };

  const post = async ({ path, body, config }: RequestParams<'post'>): Promise<any> => {
    return requestProvider.post(path, body, config);
  };

  const patch = async ({ path, body, config }: RequestParams<'patch'>): Promise<any> => {
    return requestProvider.patch(path, body, config);
  };

  const remove = async ({ path, config }: RequestParams<'delete'>): Promise<any> => {
    return requestProvider.delete(path, config);
  };

  const downloadFileFromBackend = async ({ config }: RequestParams<'get'>): Promise<any> => {
    return requestProvider.get(
      `${process.env.REACT_APP_CREDIT_ASSESSMENT_URL}/assessment-processes/${config.params.fileId}/download`,
      { ...config }
    );
  };

  return { get, post, patch, remove, downloadFileFromBackend };
};
