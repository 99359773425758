import styled, { css } from 'styled-components';

type EmptyMessageProps = {
  $maxwidth?: string;
};

export const EmptyMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
`;

export const EmptyMessageWrapper = styled.div<EmptyMessageProps>(
  (props) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 16px;

    max-width: ${props.$maxwidth || '100%'};
  `
);
