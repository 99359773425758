import { Button } from '@hexa-ui/components';
import { Upload } from '@hexa-ui/icons';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useHeader } from '../../../hooks/useHeader';
import SegmentService from '../../../services/segment';
import { Modal } from './Modal/Modal';
import { FileButtonsContainer } from './styles';

export const FileButtons: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { permissions, selected } = useGetUserPreferences();
  const { downloadModalOpened, setDownloadModalOpened } = useHeader();

  const buttonClicked = (buttonLabel: string, buttonName: string, event: string) => {
    SegmentService.paymentsButtonClicked(
      buttonLabel,
      buttonName,
      selected?.vendorId,
      event,
      'CREDIT_MANAGEMENT_FILE_PAGE'
    );
  };

  useEffect(() => {
    if (downloadModalOpened) {
      buttonClicked(
        'Download File',
        'Download File Button',
        'Credit Management Files Download Beggining'
      );
    }
  }, [downloadModalOpened]);

  if (
    !permissions.has('actions.recommendation.upload') &&
    !permissions.has('actions.recommendation.download.assessments')
  ) {
    return null;
  }

  return (
    <FileButtonsContainer data-testid="file-buttons-container">
      <Modal open={{ value: downloadModalOpened, setter: setDownloadModalOpened }} />
      {permissions.has('actions.recommendation.upload') && (
        <Button
          id="upload-button"
          icon={Upload}
          size="large"
          leading
          variant="primary"
          onClick={() => {
            navigate('/bees-credit-management/upload');
            buttonClicked(
              'Upload File',
              'Upload File Button',
              'Credit Management Files Upload Beggining'
            );
          }}
          css={{ minWidth: 'auto', whiteSpace: 'nowrap' }}
        >
          {formatMessage({ id: 'files.buttons.upload' })}
        </Button>
      )}
    </FileButtonsContainer>
  );
};
