import { Locales } from '../../interfaces';

const ptBR: Locales = {
  countries: {
    AQ: 'Antártica',
    AR: 'Argentina',
    BB: 'Barbados',
    BE: 'Bélgica',
    BO: 'Bolívia',
    BR: 'Brasil',
    CA: 'Canadá',
    IC: 'Ilhas Canárias',
    CL: 'Chile',
    CO: 'Colômbia',
    CZ: 'República Tcheca',
    DO: 'República Dominicana',
    EC: 'Equador',
    SV: 'El Salvador',
    DE: 'Alemanha',
    HN: 'Honduras',
    IN: 'Índia',
    ID: 'Indonésia',
    MX: 'México',
    NL: 'Países Baixos',
    NG: 'Nigéria',
    PA: 'Panamá',
    PY: 'Paraguai',
    PE: 'Peru',
    PH: 'Filipinas',
    ZA: 'África do Sul',
    KR: 'Coreia do Sul',
    TZ: 'Tanzânia',
    TH: 'Tailândia',
  },
  select: {
    country: 'Selecione um país',
    vendor: 'Selecione um fornecedor',
  },
  routes: {
    management: 'Gestão de crédito',
    recommendation: 'Recomendações de crédito',
    credit: 'Gestão de crédito',
    upload: 'Atualizar condições de crédito',
    progress: 'Progresso do arquivo',
    pocDetails: 'Detalhes do PDV',
    files: 'Arquivos PDVs',
    views: 'Visualização do cliente',
  },
  pages: {
    management: {
      title: 'Gestão de crédito',
    },
    credit: {
      title: 'Gestão de crédito',
      summary: {
        total: 'Total de PDVs',
        pending: 'Aprovações pendentes',
        inprogress: 'Em andamento',
        active: 'Crédito ativo',
        denied: 'Crédito negado',
      },
    },
    recommendation: {
      title: 'Recomendações de crédito',
      summary: {
        recommendations: 'Recomendações',
        capitalAllocation: 'Alocação de capital',
      },
    },
    files: {
      title: 'Arquivos PDVs',
    },
    upload: {
      title: 'Atualizar condições de crédito',
      uploader: {
        title: 'Envio de arquivo',
        errors: {
          noFile: 'Você deve carregar um arquivo para continuar.',
          maxFileSize: 'O arquivo excede o limite de { maxFileSize }MB.',
          invalidFormat:
            'Formato de arquivo inválido. O arquivo deve estar no formato CSV ou XLSX.',
        },
        alertMessage: 'A partir desta etapa, você poderá sair da página sem perder o progresso.',
        rulesAndConditions: {
          modalLink: `regras e condições.`,
          description: `
            Atualize as condições de crédito dos PDVs carregando um arquivo. O arquivo deve cumprir as
          `,
          progress: {
            description:
              'Analise o arquivo para garantir que todos os PDVs cumpram as <TextLink>regras e condições</TextLink> para atualizações de crédito. ',
          },
          modal: {
            title: 'Regras e condições',
            description:
              'Essas regras são válidas para carregar arquivos com novas condições de crédito. PDVs com erros precisarão ser corrigidos e carregados novamente.',
            files: {
              title: 'Regras de arquivo',
              wontConsider: 'Não será considerado:',
              items: {
                duplicatedPOCLines:
                  '<strong>Linhas PDV duplicadas. </strong>Se o arquivo tiver duas linhas na ID da conta com as mesmas informações, apenas a primeira linha será considerada.',
                newlyIncluded:
                  '<strong>Linhas PDV recém-adicionadas. </strong>Eles serão mostrados como “PDV não existente”.',
                removeLines: '<strong>Linhas PDV excluídas</strong>',
                blankFields: '<strong>Campos PDV em branco</strong>',
                newColumns: '<strong>Colunas recém-adicionadas </strong>',
              },
            },
            credit: {
              title: 'Regras de crédito',
              items: {
                creditLimit: '<strong>Limite de crédito: </strong>',
                creditTerm: '<strong>Prazo de crédito: </strong>',
                creditFee: '<strong>Taxa de crédito: </strong>',
              },
            },
          },
        },
        fileUploader: {
          title: 'Arraste seu arquivo aqui ou ',
          label: 'pesquise',
          description:
            'O formato deve ser XLSX ou CSV. Tamanho máximo de { maxFileSize }MB por arquivo.',
        },
        fileDescription: {
          title: 'Descrição do arquivo',
          subTitle: '(opcional)',
          description: 'Facilite a identificação desse arquivo apresentando alguns detalhes.',
        },
        steps: {
          fileUpload: 'Envio de arquivo',
          fileAnalysis: 'Análise do arquivo',
          pocUpdate: 'Atualização de PDVs',
        },
      },
      buttons: {
        back: 'Cancelar',
        upload: 'Analisar arquivo',
        apply: 'Atualizar PDVs',
        goToCredit: 'Ir para crédito',
        goToFiles: 'Ir para arquivos PDV',
        uploadNewFile: 'Carregar novo arquivo',
        toasts: {
          analysis: '{ validated } PDVs foram atualizados com sucesso.',
          processing: 'Processando arquivo. { fileName }',
          success: 'Arquivo enviado com sucesso! { fileName }',
          warning: 'Arquivo enviado com erros. { fileName }',
          error: 'Erro de envio. { fileName }',
        },
      },
      progress: {
        title: 'Análise do arquivo',
        processingTitle: 'Atualização de PDVs',
        processingDescription:
          'Atualizar as condições de crédito dos PDVs. Esta etapa pode levar algum tempo e você será notificado assim que for concluída.',
        meter: {
          starting: 'Iniciando',
          progress: '{ progressValue } de { progressMaxValue } PDVs analisados.',
          progressUpload: '{ progressValue } de { progressMaxValue } PDVs atualizados.',
          description: 'O tempo de análise pode ser maior quando o arquivo contém muitos PDVs.',
          descriptionProgressing:
            'O tempo de processamento pode ser maior quando há muitos POCs a serem atualizados.',
          error: 'O arquivo não contém PDVs válidos. Revise o arquivo e carregue-o novamente.',
          slow: 'O carregamento está demorando mais do que o normal. Aguarde um momento.',
        },
        finished: {
          title: 'Resultados da análise',
          pocs: {
            total: 'PDVs analisados',
            success: 'PDVs a serem atualizados',
            error: 'PDVs com erros',
            tooltip:
              'Só é possível aumentar o limite de crédito para 200% do valor sugerido, e o prazo máximo permitido é de 30 dias.',
          },
          alert:
            'Você pode continuar com os PDVs a serem atualizados. Para corrigir erros, baixe a lista de erros e carregue um novo arquivo.',
          download: 'Baixar lista de erros',
        },
        error: {
          analysis: 'Não foi possível concluir a análise do arquivo. Carregue o arquivo novamente.',
          processing:
            'Não foi possível concluir a atualização dos PDVs. Tente carregar o arquivo novamente.',
        },
      },
      cancelModal: {
        title: 'Cancelar atualização?',
        subtitle: 'Seu progresso será perdido e você precisará começar novamente.',
        buttons: { confirm: 'Sim, cancelar', cancel: 'Não, voltar' },
      },
    },
    'poc-details': {
      recommendation: {
        title: 'Recomendação de linha de crédito',
        status: {
          pending: 'Pendente',
          denied: 'Negado',
          waitingActivation: 'Aguardando ativação',
          waitingOnboarding: 'Aguardando onboarding',
        },
        labels: {
          creditLimit: 'Limite de crédito',
          termFee: 'Prazo/taxa',
          recommendation: 'Recomendação',
          updatedOn: 'Atualizado em',
          approvedBy: 'Editado por',
          approvalMethod: 'Método de edição',
        },
        timeline: {
          title: 'Cronograma de recomendação',
          steps: {
            APPROVED: 'Aprovado',
            ONBOARDING: 'Integração',
            ACTIVATION: 'Processo de ativação',
            ACTIVATED: 'Linha de crédito ativada',
          },
        },
      },
      information: {
        title: 'Informações do PDV',
        labels: {
          pocName: '​Nome',
          pocDocument: 'ID',
          pocAddress: 'Endereço',
          pocEmail: 'E-mail',
          pocPhoneNumber: 'Telefone',
          pocAccount: 'Código da conta',
        },
      },
      conditions: {
        title: 'Condições atuais da linha de crédito',
        status: {
          active: 'Ativo',
          blocked: 'Bloqueado',
          overdue: 'Atrasado',
          due: 'Vencimento',
        },
        labels: {
          creditLimit: 'Limite de crédito',
          limitAvailable: 'Limite disponível',
          balance: 'Utilizado',
          overdue: 'Atrasado',
          termFee: 'Prazo/taxa',
          paymentMethod: 'Método de pagamento',
          activatedOn: 'Ativado em',
          approvedBy: 'Aprovado por',
          approvalMethod: 'Método de aprovação',
          fileName: 'Nome do arquivo',
          fileDescription: 'Descrição do arquivo',
        },
      },
      history: {
        credit: {
          title: 'Histórico de crédito',
          description: 'Todas as alterações de status feitas nas condições de crédito do PDV.',
          buttons: { trigger: 'Mostrar histórico de crédito' },
          information: {
            resume: '{ limit } - { term } - { fee }',
            by: 'Por { identifier }',
          },
          identifiers: {
            backend: 'back-end',
            poc: 'PDV',
          },
          labels: {
            byAndHour: 'por { identifier } em { hour }',
            creditLimit: 'Limite de crédito:',
            termFee: 'Prazo/taxa:',
            method: {
              edit: 'Editar método:',
              approval: 'Método de aprovação:',
              denial: 'Método de negação:',
            },
          },
          status: {
            NEW_CREDIT_RECOMMENDATION: 'Nova recomendação de crédito',
            CREDIT_RECOMMENDATION_APPROVED: 'Recomendação de crédito aprovada',
            CREDIT_RECOMMENDATION_DENIED: 'Recomendação de crédito negada',
            WAITING_ONBOARDING_BYPASSED: 'Integração ignorada',
            WAITING_ONBOARDING_COMPLETED: 'Integração concluída',
            CREDIT_LINE_ACTIVATED: 'Linha de crédito ativada',
            CREDIT_LINE_BLOCKED: 'Linha de crédito bloqueada por falta de pagamento',
            CREDIT_LINE_UNBLOCKED: 'Linha de crédito desbloqueada',
          },
        },
      },
    },
    views: {
      title: 'Visualização do cliente',
      description:
        'Crie visualizações personalizadas na tabela para atender aos seus requisitos de análise.',
      buttons: { back: 'Voltar', save: 'Salvar e aplicar' },
      toasts: {
        warning: 'Você pode adicionar no máximo 6 colunas a uma tabela.',
        success: 'A visualização { name } foi salva e aplicada à tabela.',
      },
      inputs: {
        name: {
          label: 'Exibir nome',
          placeholder: 'Insira um nome',
        },
        type: {
          label: 'Tipo de visualização',
          options: {
            RECOMMENDED: {
              label: 'Recomendações de crédito',
              tooltip: 'PDVs marcados como bons candidatos para crédito.',
            },
            NOT_RECOMMENDED: {
              label: 'PDVs sem recomendação de crédito',
              tooltip: 'PDVs não marcados como bons candidatos para crédito.',
            },
          },
        },
      },
      columns: {
        select: { description: 'Selecione até 6 colunas para exibir na tabela.' },
        order: {
          description: 'Classifique as colunas selecionadas com base na ordem de sua preferência.',
        },
      },
    },
  },
  formatting: {
    datetime: { 'long-time': `{ date } em { time }` },
    input: {
      text: {
        percentage: '{ value } %',
        days: '{value, plural, =1 {# dia} other {# dias}}',
      },
      suffix: {
        percentage: '%',
        days: '{value, plural, =1 {dia} other {dias}}',
      },
    },
    recommendation: {
      NEW_CREDIT_OFFER: 'Oferta de crédito',
      INCREASE: 'Aumento de crédito',
      DECREASE: 'Diminuição de crédito',
    },
    'approval-method': {
      FILE_BATCH_UPDATE: 'Por arquivo',
      SYSTEM: 'Por sistema',
    },
    'payment-method': {
      CASH: 'Dinheiro',
      CREDIT: 'Crédito',
      BANK_SLIP: 'Boleto à vista',
      BANK_SLIP_INSTALLMENT: 'Boleto parcelado',
    },
    'term-fee': '{term, plural, =1 {# dia} other {# dias}} / {fee}',
    'list-items': '{items} e {last}',
  },
  tables: {
    loading: 'Carregando itens...',
    collapseActionsMenu: {
      edit: 'Editar linha de crédito',
      editAndUnblock: 'Edit and unblock',
      block: 'Bloquear linha de crédito',
      cancel: 'Cancelar linha de crédito',
      unblock: 'Desbloquear linha de crédito',
    },
    empty: 'Ainda não há PDVs nesta lista.',
    error: {
      somethingWentWrong: 'Algo deu errado.',
      tryReloading: 'Tente recarregar a página.',
    },
    search: 'Nome ou código do PDV',
    pagination: {
      quantity: 'de',
      itensPerPage: 'Itens por página: { numberPage }',
    },
    selectable: {
      selected: '{counter, plural, =1 {# cliente selecionado} other {# clientes selecionados}}',
      deselect: 'Deselecionar tudo',
    },
    views: {
      selector: { label: 'Visualizar: { name }' },
      card: {
        custom: { title: 'Visualizações do cliente' },
        alert:
          'Você atingiu o limite de 10 visualizações personalizadas. Exclua uma visualização para criar uma nova.',
        standard: {
          title: 'Visualizações padrões',
          DEFAULT: 'Padrão',
          RECOMMENDED: 'Recomendações de crédito',
          NOT_RECOMMENDED: 'PDVs sem recomendação de crédito',
        },
        buttons: { create: 'Criar nova visualização' },
        tooltips: { edit: 'Editar visualização', delete: 'Excluir visualização' },
        modal: {
          title: 'Excluir visualização personalizada?',
          description: 'Esta ação não pode ser desfeita.',
          buttons: { cancel: 'Não, voltar', delete: 'Sim, excluir' },
          toasts: { success: 'Sua visualização personalizada foi excluída.' },
        },
      },
    },
    filters: {
      empty: 'Nenhum resultado do filtro.',
      buttons: {
        filter: '{value, plural, =0 {Filtro} one {Filtro (1)} other {Filtros (#)}}',
        cancel: 'Cancelar',
        apply: 'Aplicar',
        clearAll: 'Limpar tudo',
        clearDate: 'Limpar data',
      },
      chips: {
        min: 'Mínimo { min }',
        max: 'Máximo { max }',
        startDate: 'Data de início { min }',
        endDate: 'Data de término { max }',
        to: '{ min } até { max }',
        range: '{ min } - { max }',
      },
      inputs: {
        creditLimit: {
          name: 'Limite de crédito',
          min: { label: 'Limite mínimo', placeholder: 'Inserir valor' },
          max: { label: 'Limite máximo', placeholder: 'Inserir valor' },
        },
        term: {
          name: 'Prazo',
          min: {
            label: 'Prazo mínimo',
            placeholder: '0',
            sufix: '{value, plural, =1 {dia} other {dias}}',
          },
          max: {
            label: 'Prazo máximo',
            placeholder: '0',
            sufix: '{value, plural, =1 {dia} other {dias}}',
          },
        },
        maximumTerm: {
          name: 'Prazo máximo',
          min: {
            label: 'Menor prazo máximo',
            placeholder: '0',
            sufix: '{value, plural, =1 {dia} other {dias}}',
          },
          max: {
            label: 'Maior prazo máximo',
            placeholder: '0',
            sufix: '{value, plural, =1 {dia} other {dias}}',
          },
        },
        fee: {
          name: 'Taxa',
          min: { label: 'Taxa mínima', placeholder: '0' },
          max: { label: 'Taxa máxima', placeholder: '0' },
        },
        monthlyFee: {
          name: 'Taxa mensal',
          min: { label: 'Taxa mínima', placeholder: '0' },
          max: { label: 'Taxa máxima', placeholder: '0' },
        },
        score: {
          name: 'Score',
          min: { label: 'Score mínimo', placeholder: '0 - 100' },
          max: { label: 'Score máximo', placeholder: '0 - 100' },
        },
        updatedAt: {
          name: 'Última atualização',
          min: { label: 'Data de início', placeholder: 'Selecionar data' },
          max: { label: 'Data de término', placeholder: 'Selecionar data' },
        },
        blockedDate: {
          name: 'Data do bloqueio',
          min: { label: 'Data de início', placeholder: 'Selecionar data' },
          max: { label: 'Data de término', placeholder: 'Selecionar data' },
        },
        paymentStatus: {
          name: 'Status do pagamento',
          title: 'Status do pagamento',
          labels: {
            current: 'Em dia',
            overdue: 'Atrasado',
          },
        },
        status: {
          name: 'Status',
          title: 'Status',
          labels: {
            processing: 'Processando',
            integration: 'Integração',
            activated: 'Ativo',
            denied: 'Negado',
          },
        },
        recommendation: {
          name: 'Recomendação',
          title: 'Recomendação',
          labels: {
            creditOffer: 'Oferta de crédito',
            creditIncrease: 'Aumento de crédito',
            creditDecrease: 'Diminuição de crédito',
          },
        },
        blockedReason: {
          name: 'Motivo do Bloqueio',
          title: 'Motivo do Bloqueio',
          labels: {
            current: 'Score baixo',
            overdue: 'Pagamento vencido',
            other: 'Outro'
          },
        }
      },
    },
    files: {
      tab: { title: 'Arquivos' },
      empty: {
        default: 'Ainda não há arquivos nesta lista.',
        search: 'Nenhum resultado da pesquisa',
        error: 'Algo deu errado. Tente recarregar a página.',
      },
      search: 'Pesquisar por nome do arquivo',
      status: {
        uploaded: 'Análise pendente',
        analyzing: 'Análise em andamento',
        analyzed: 'Atualização pendente',
        processing: 'Atualização em andamento',
        success: 'Concluído',
      },
      columns: {
        fileName: 'Nome do arquivo',
        fileDescription: 'Descrição do arquivo',
        total: 'PDVs atualizados',
        uploadedBy: 'Atualizado por',
        uploadedAt: 'Atualizado em',
        status: 'Status',
        actions: 'Ação',
      },
    },
    pending: {
      tab: { title: 'Pendente' },
      columns: {
        pocName: 'PDV',
        accountId: 'Código da conta',
        creditLimit: 'Limite de crédito',
        term: 'Prazo máx.',
        fee: 'Taxa mensal',
        score: 'Pontuação',
        recommendation: 'Recomendação',
        updatedAt: 'Última atualização',
        actions: 'Ação',
        salesAgency: 'Agência de vendas',
        salesArea: 'Área de vendas',
        customerType: 'Tipo de cliente',
      },
    },
    inprogress: {
      tab: { title: 'Em andamento' },
      status: {
        processing: 'Processando',
        integration: 'Integração',
      },
      columns: {
        pocName: 'PDV',
        accountId: 'Código da conta',
        creditLimit: 'Limite de crédito',
        term: 'Prazo',
        fee: 'Taxa',
        updatedAt: 'Última atualização',
        updatedBy: 'Atualizado por',
        status: 'Status',
      },
    },
    completed: {
      tab: { title: 'Concluída' },
      status: {
        active: 'Ativo',
        denied: 'Negado',
      },
      columns: {
        pocName: 'PDV',
        accountId: 'Código da conta',
        creditLimit: 'Limite de crédito',
        term: 'Prazo',
        fee: 'Taxa',
        updatedAt: 'Última atualização',
        updatedBy: 'Atualizado por',
        status: 'Status de crédito',
      },
    },
    processing: {
      tab: { title: 'Processando ({ quantity })' },
      columns: {},
    },
    active: {
      tab: { title: 'Crédito ativo ({ quantity })' },
      columns: {
        accountId: 'Código da conta',
        creditLimit: 'Limite de crédito',
        fee: 'Taxa mensal',
        pocName: 'PDV',
        score: 'Score',
        term: 'Prazo máx.',
        paymentStatus: 'Status do pagamento',
        actions: 'Ações',
        updatedAt: 'Última atualização',
      },
      status: {
        current: 'Em dia',
        overdue: 'Atrasado',
      },
    },
    blocked: {
      tab: { title: 'Crédito bloqueado ({ quantity })' },
      columns: {
        accountId: 'Código da conta',
        creditLimit: 'Limite de crédito',
        fee: 'Taxa mensal',
        pocName: 'PDV',
        score: 'Score',
        term: 'Prazo máx.',
        blockedBy: 'Bloqueado por',
        blockedReason: 'Motivo do bloqueio',
        blockedDate: 'Data do bloqueio',
        actions: 'Ações',
      },
    },
    canceled: {
      tab: { title: 'Crédito cancelado ({ quantity })' },
      columns: {},
    },
  },
  files: {
    buttons: {
      upload: 'Atualizar condições de crédito',
      download: 'Baixar PDVs',
    },
    modal: {
      title: 'Baixar arquivo',
      description:
        'Baixar arquivos para aprovar ou negar linhas de crédito do cliente. Os arquivos não podem conter:',
      itemsList: {
        first: 'Colunas adicionadas ou renomeadas',
        second: 'Clientes adicionados ou renomeados',
        third:
          'Qualquer informação que tenha sido adicionada ou modificada, exceto o status de aprovação',
      },
      fileDetails: {
        title: 'Detalhes do arquivo',
        description: 'Selecione as informações que deseja incluir no arquivo.',
        subTitle: 'Status do cliente:',
        subDescription: 'Selecione os status a serem incluídos no arquivo.',
        checkboxes: {
          all: 'Todos',
          pending: 'Aprovação pendente',
          approved: 'Crédito aprovado',
          denied: 'Crédito negado',
        },
      },
      buttons: {
        back: 'Voltar',
        confirm: 'Gerar e baixar arquivo',
      },
      toasts: {
        info: 'O arquivo está sendo gerado e pode levar alguns segundos. Ele será baixado automaticamente quando terminar.',
      },
    },
    errors: {
      412: {
        description:
          'Outro arquivo já está em processamento. Você precisa esperar até que ele esteja pronto antes de carregar um novo arquivo.',
      },
    },
  },
  editRecommendation: {
    title: 'Editar recomendação de crédito',
    description:
      'As recomendações se baseiam na análise de dados do PDV. Ao editar uma recomendação de crédito, você deve seguir algumas condições.',
    buttons: {
      cancel: 'Cancelar',
      apply: 'Aplicar alterações',
      tooltip: 'Editar status',
      edit: { recommendation: 'Editar recomendação', conditions: 'Editar condições' },
    },
    toasts: { success: 'As condições de crédito de { value } foram alteradas.' },
    inputs: {
      creditLimit: {
        label: 'Limite de crédito',
        hint: 'Sugerido: { value }.',
      },
      term: {
        label: 'Prazo de crédito',
        hint: 'Sugerido: {value, plural, =1 {# dia} other {# dias}}.',
      },
      fee: {
        label: 'Taxa de crédito',
      },
    },
  },
  approveRecommendation: {
    title: {
      single: 'Aprovar recomendação de crédito?',
      multiple: 'Aprovar { counter } recomendações de crédito selecionadas?',
    },
    description: {
      single: 'Selecione a opção para este PDV.',
      multiple:
        'Você aprovará a recomendação de crédito para { counter } PDVs. Esta ação não pode ser desfeita.',
    },
    counter: '{ counter } PDVs selecionados',
    info: {
      title: 'Solicitar integração PDV',
      description:
        'Número de telefone, código do PDV, comprovante de endereço e a aceitação dos termos e condições serão solicitados.',
    },
    radios: { request: 'Solicitar', doNotRequest: 'Não solicitar' },
    buttons: { back: 'Não, cancelar', approve: 'Sim, aprovar' },
    warning: 'Se você não solicitar a integração, será responsável por esta decisão.',
  },
  denyRecommendation: {
    title: 'Negar { counter } recomendações de crédito selecionadas?',
    description:
      'Você negará a recomendação de crédito para { counter } PDVs. Esta ação não pode ser desfeita. ',
    button: 'Sim, negar',
  },
  offerCredit: {
    title: 'Oferta de crédito?',
    tooltip: 'Oferta de crédito.',
    warning:
      'Com base nas informações disponíveis, não há recomendação de crédito para este PDV. Quaisquer decisões de crédito tomadas serão tomadas a seu próprio critério.',
    info: {
      title: 'Solicitar integração PDV',
      description:
        'Número de telefone, código do PDV, comprovante de endereço e a aceitação dos termos e condições serão solicitados.',
    },
    radios: { request: 'Solicitar', doNotRequest: 'Não solicitar' },
    buttons: {
      cancel: 'Voltar',
      apply: 'Oferta de crédito',
    },
    toasts: { success: 'Crédito oferecido a { value }.' },
    inputs: {
      creditLimit: {
        label: 'Limite de crédito',
        placeholder: 'Insira um valor',
      },
      term: {
        label: 'Prazo de crédito',
        placeholder: 'dias',
      },
      fee: {
        label: 'Taxa de crédito',
      },
    },
  },
  editCreditLineModal: {
    title: 'Editar linha de crédito',
    subtitle: '{ pocName }  |  ID: { accountId }',
    description:
      'A edição da linha de crédito do PDV em dia requer uma análise cuidadosa. As alterações na linha de crédito impactam diretamente os pedidos do PDV, portanto proceda com cuidado.',
    inputs: {
      creditLimit: 'Limite de crédito',
      maximumCrediTerm: 'Prazo máximo de crédito',
      creditFee: 'Taxa de crédito mensal',
    },
    buttons: {
      back: 'Voltar',
      apply: 'Aplicar alterações',
    },
    alert:
      'Editar uma linha de crédito não a atualiza instantaneamente. As solicitações de alteração passam pela aba Processando antes de entrar em vigor.',
    toasts: {
      success:
        'Linha de crédito editada. As alterações podem levar algum tempo para serem processadas.',
      error: 'Erro ao editar a linha de crédito de { pocName }. Tente novamente.',
    },
  },
  blockCreditLine: {
    single: {
      title: 'Bloquear linha de crédito?',
      subtitle: '{ pocName }  |  ID: { accountId }',
      description:
        'O PDV não poderá utilizar a linha de crédito para seus pedidos até que solucione suas pendências ou seja desbloqueado manualmente.',
      dropdown: { hint: 'Selecione um motivo para bloquear esta linha de crédito.' },
      buttons: { cancel: 'Voltar', apply: 'Bloquear linha de crédito' },
      toasts: {
        success:
          'A linha de crédito de { pocName } foi bloqueada. Essa ação pode levar algum tempo para ser processada.',
        error: 'Erro ao bloquear a linha de crédito de { pocName }. Tente novamente.',
      },
    },
    multiple: {
      title: 'Bloquear linhas de crédito?',
      subtitle: '{ counter } PDVs selecionados',
      description:
        'Estes PDVs não poderão utilizar a linha de crédito para seus pedidos até que solucionem suas pendências ou seja desbloqueados manualmente.',
      dropdown: { hint: 'Selecione um motivo para bloquear estas linhas de crédito.' },
      buttons: { cancel: 'Voltar', apply: 'Bloquear linhas de crédito' },
      toasts: {
        success:
          'As linhas de crédito de { counter } PDVs estão sendo bloqueadas. Essa ação pode levar algum tempo para ser processada.',
        error: 'Erro ao bloquear as linhas de crédito de { counter } PDVs. Tente novamente.',
      },
    },
    alert:
      'O bloqueio pode levar até 24 horas para ser concluído. Acompanhe o progresso na aba Processando.',
    dropdown: {
      label: 'Motivo do bloqueio',
      placeholder: 'Selecione um motivo',
      labels: {
        LOW_SCORE: 'Score baixo',
        PAYMENT_OVERDUE: 'Pagamento vencido',
        OTHER: 'Outro',
      },
    },
  },
  unblockCreditLine: {
    single: {
      title: 'Desbloquear linha de crédito?',
      subtitle: '{ pocName }  |  ID: { accountId }',
      description: 'O PDV poderá voltar a utilizar a linha de crédito normalmente.',
      buttons: { cancel: 'Voltar', apply: 'Desbloquear linha de crédito' },
      toasts: {
        success:
          'A linha de crédito de { pocName } foi desbloqueada. Essa ação pode levar algum tempo para ser processada.',
        error: 'Erro ao desbloquear a linha de crédito de { pocName }. Tente novamente.',
      },
      alert:
        'O desbloqueio de uma linha de crédito não é imediato. As solicitações de alteração passam pela aba Processando antes de entrar em vigor.',
    },
    multiple: {
      title: 'Desbloquear linhas de crédito?',
      subtitle: '{ counter } PDVs selecionados',
      description: 'Estes PDVs poderão voltar a utilizar a linha de crédito normalmente.',
      dropdown: { hint: 'Select a reason for blocking these credit lines.' },
      buttons: { cancel: 'Voltar', apply: 'Desbloquear linhas de crédito' },
      toasts: {
        success:
          'As linhas de crédito de { counter } PDVs estão sendo desbloqueadas. Essa ação pode levar algum tempo para ser processada.',
        error: 'Erro ao desbloquear as linhas de crédito de { counter } PDVs. Tente novamente.',
      },
      alert:
        'O desbloqueio das linhas de crédito não é imediato. As solicitações de alteração passam pela aba Processando antes de entrar em vigor.',
    },
  },
  cancelCreditLine: {
    single: {
      title: 'Cancelar linha de crédito?',
      subtitle: '{ pocName }  |  ID: { accountId }',
      description:
        'As condições de crédito serão excluídas e o PDV deixará de ter linha de crédito. Esta ação não pode ser desfeita.',
      dropdown: { hint: 'Selecione um motivo para cancelar esta linha de crédito.' },
      buttons: { cancel: 'Voltar', apply: 'Cancelar linha de crédito' },
      toasts: {
        success:
          'A linha de crédito de { pocName } foi cancelada. Essa ação pode levar algum tempo para ser processada.',
        error: 'Erro ao cancelar a linha de crédito de { pocName }. Tente novamente.',
      },
    },
    multiple: {
      title: 'Cancelar linhas de crédito?',
      subtitle: '{ counter } PDVs selecionados',
      description:
        'As condições de crédito serão excluídas e o PDVs deixarão de ter linha de crédito. Esta ação não pode ser desfeita.',
      dropdown: { hint: 'Selecione um motivo para cancelar estas linhas de crédito.' },
      buttons: { cancel: 'Voltar', apply: 'Cancelar linhas de crédito' },
      toasts: {
        success:
          'As linhas de crédito de { counter } PDVs estão sendo canceladas. Essa ação pode levar algum tempo para ser processada.',
        error: 'Erro ao cancelar as linhas de crédito de { counter } PDVs. Tente novamente.',
      },
    },
    alert:
      'O cancelamento pode levar até 24 horas para ser concluído. Acompanhe o progresso na aba Processando.',
    dropdown: {
      label: 'Motivo do cancelamento',
      placeholder: 'Selecione um motivo',
      labels: {
        INACTIVE_ACCOUNT: 'Conta inativa',
        LATE_PAYMENT: 'Pagamento vencido',
        LEGAL_REASONS: 'Motivos jurídicos',
        LOW_SCORE: 'Score baixo',
        OTHER: 'Outro',
      },
    },
  },
  creditLineButtons: {
    cancel: 'Cancelar linhas de crédito',
    block: 'Bloquear linhas de crédito',
    unblock: 'Desbloquear linhas de crédito',
  },
  loading: {
    default: 'Estamos te levando para tela',
    optimizely: 'Preparando sua experiência',
  },
  errors: {
    default: 'Algo deu errado.',
    generic: 'Algo deu errado. Tente recarregar a página.',
    403: {
      title: 'Hmm... Acesso negado!',
      description: 'Infelizmente, você não tem permissão para acessar esta página.',
      button: 'Voltar',
    },
    404: {
      title: 'Hmm… Parece que essa página sumiu!',
      description: 'Essa página não pôde ser encontrada ou não existe.',
      button: 'Voltar',
    },
    500: {
      title: 'Ooops! Parece que alguma coisa deu errada',
    },
    503: {
      title: 'Ooops! Parece que alguma coisa deu errada',
      description: 'Estamos com problemas para carregar esta página. Por favor, tente novamente.',
      info: '',
      button: 'Tente novamente',
    },
    custom: {
      buttons: { back: 'Voltar', reload: 'Recarregar página' },
      'not-enabled': {
        both: {
          title: 'País e fornecedor não habilitado',
          description:
            'Você não tem acesso ao país e fornecedor selecionado. Altere sua seleção ou solicite acesso.',
        },
        country: {
          title: 'País não habilitado',
          description:
            'Você não tem acesso ao país selecionado. Altere sua seleção ou solicite acesso.',
        },
        vendor: {
          title: 'Fornecedor não habilitado',
          description:
            'Você não tem acesso ao fornecedor selecionado. Altere sua seleção ou solicite acesso.',
        },
      },
      'not-available': {
        title: 'País não disponível',
        description:
          'O país selecionado ainda não está disponível neste aplicativo. Selecionar outro.',
      },
      'something-went-wrong': {
        title: 'Algo deu errado',
        description: 'Houve problemas para carregar esta página. Tente recarregar a página.',
      },
    },
  },
  source: {
    validations: {
      errors: {
        'error:term:zero': 'Deve ser no mínimo {value, plural, =1 {# dia} other {# dias}}.',
        'error:term:small': 'Deve ser no máximo {value, plural, =1 {# dia} other {# dias}}.',
        'error:term:fee:big': 'Deve ser no máximo { value }%.',
        'error:term:fee:negative': 'Deve ser maior ou igual a { value }%.',
        'error:limit:big': 'Máximo de { value }% do valor recomendado.',
        'error:limit:small': 'Deve ser maior ou igual a { value }.',
      },
    },
  },
  approval: {
    buttons: {
      APPROVED: {
        single: 'Aprovar',
        multiple: 'Aprovar selecionados',
        tooltip: 'Aprovar linha de crédito',
      },
      DENIED: {
        single: 'Negar',
        multiple: 'Negar selecionados',
        tooltip: 'Negar linha de crédito',
      },
    },
    toasts: {
      APPROVED: {
        single: 'A linha de crédito de { value } está aprovada!',
        multiple: 'A linha de crédito para { value } clientes está aprovada!',
      },
      DENIED: {
        single: 'A linha de crédito de { value } foi negada!',
        multiple: 'A linha de crédito para { value } clientes foi negada!',
      },
    },
    errors: {
      412: {
        description:
          'Uma atualização já está em andamento. Você precisa esperar até que ela seja concluída antes de fazer uma nova atualização.',
      },
    },
  },
  massApproval: {
    toasts: {
      APPROVED: 'Aprovando { value } recomendações. Informaremos quando terminar.',
      DENIED: 'Negando { value } recomendações. Informaremos quando terminar.',
      errorApproved: 'Erro ao atualizar Condições de crédito para { value } PDVs. Tente novamente.',
      recommendationSuccess: 'Condições de crédito atualizadas para { value } PDVs.',
      issueMessage:
        '{ value } de { total } PDVs tiveram suas condições de crédito atualizadas. { issue } apresentaram problemas, tente novamente.',
    },
  },
  notifications: {
    analysis: {
      info: '{ fileName } está em análise. Isso pode levar algum tempo e você será notificado assim que for concluída.',
      warning:
        'A análise do { fileName } foi concluída. Verifique os resultados para atualizar PDVs e corrigir erros.',
      success:
        'A análise do { fileName } foi concluída. Verifique os resultados para atualizar PDVs.',
      failed:
        'Não foi possível concluir a análise do arquivo { fileName }. Verifique os resultados e tente novamente.',
    },
    update: {
      info: 'Atualizando { pocAmount } condições de crédito PDV. Isso pode levar algum tempo e você será notificado assim que for concluída.',
      success: '{ pocAmount } Condições de crédito PDVs de { fileName } atualizadas com sucesso.',
      failed:
        'Não foi possível atualizar as condições de crédito do arquivo { fileName }. Verifique os resultados e tente novamente.',
    },
    firstAction: 'Mostrar resultados',
  },
};

export default ptBR;
