import {
  BlockCreditLineProps,
  BlockCreditLinesByFilterProps,
  CancelCreditLineProps,
  CancelCreditLinesByFilterProps,
  EditCreditLineProps,
  GetManagementProps,
  ServicesResponse,
  UnblockCreditLineProps,
  UnblockCreditLinesByFilterProps,
} from '../../interfaces';

const service = {
  getManagementData: async ({
    api,
    params,
  }: Partial<GetManagementProps>): Promise<ServicesResponse | null> => {
    const mappedFields = {
      id: 'data.id',
      beesAccountId: 'data.beesAccountId',
      accountId: 'data.accountId',
      pocName: 'data.pocName',
      creditLimit: 'data.creditLimit',
      term: 'data.term',
      fee: 'data.fee',
      score: 'data.score',
      paymentStatus: 'data.paymentStatus',
      updatedAt: 'data.updatedAt',
      updatedBy: 'data.updatedBy',
      blockedBy: 'blockedBy',
      blockedReason: 'blockedReason',
      blockedDate: 'blockedDate',
    };

    try {
      const { status, sort, filters } = params;

      const sortParams = sort
        .map(
          (sortParam) =>
            `sortField=${mappedFields[sortParam.id]}&sortDirection=${
              sortParam.desc ? 'desc' : 'asc'
            }`
        )
        .join('&');

      const queryParams: string[] = [
        ...(!filters.includes('status') ? [`status=${status}`] : []),
        ...(filters ? [filters] : []),
        ...(sortParams.length > 0 ? [sortParams] : []),
      ];

      const response = await api.get({ path: `/v1/management?${queryParams.join('&')}` });

      if (!response?.data) throw new Error(response.message);

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
  getManagementSummary: async ({
    api,
    params,
  }: Partial<GetManagementProps>): Promise<ServicesResponse | null> => {
    try {
      const response = await api.get({
        path: '/v1/management/summary',
        config: {
          params: {
            processing: 'PROCESSING',
            active: 'ACTIVE',
            blocked: 'BLOCKED',
            cancelled: 'CANCELLED',
          },
        },
      });

      if (!response?.data) throw new Error(response.message);

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
  editCreditLine: async ({
    api,
    params,
  }: EditCreditLineProps): Promise<ServicesResponse | null> => {
    try {
      const { id, body } = params;

      const response = await api.patch({
        path: `/v1/management/edit/${id}`,
        body,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
  blockCreditLine: async ({
    api,
    params,
  }: BlockCreditLineProps): Promise<ServicesResponse | null> => {
    try {
      const { id, reason } = params;

      const response = await api.patch({
        path: `/v1/management/block/${id}`,
        body: { reason },
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
  blockCreditLinesByFilter: async ({
    api,
    params,
  }: BlockCreditLinesByFilterProps): Promise<ServicesResponse | null> => {
    try {
      const response = await api.patch({
        path: '/v1/management/block-by-filter',
        body: params,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
  cancelCreditLine: async ({
    api,
    params,
  }: CancelCreditLineProps): Promise<ServicesResponse | null> => {
    try {
      const { id, reason } = params;

      const response = await api.patch({
        path: `/v1/management/cancel/${id}`,
        body: { reason },
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
  cancelCreditLinesByFilter: async ({
    api,
    params,
  }: CancelCreditLinesByFilterProps): Promise<ServicesResponse | null> => {
    try {
      const response = await api.patch({
        path: '/v1/management/cancel-by-filter',
        body: params,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
  unblockCreditLine: async ({
    api,
    params,
  }: UnblockCreditLineProps): Promise<ServicesResponse | null> => {
    try {
      const { id } = params;

      const response = await api.patch({
        path: `/v1/management/unblock/${id}`,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
  unblockCreditLinesByFilter: async ({
    api,
    params,
  }: UnblockCreditLinesByFilterProps): Promise<ServicesResponse | null> => {
    try {
      const response = await api.patch({
        path: '/v1/management/unblock-by-filter',
        body: params,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
};

export const {
  getManagementData,
  getManagementSummary,
  editCreditLine,
  blockCreditLine,
  blockCreditLinesByFilter,
  cancelCreditLine,
  cancelCreditLinesByFilter,
  unblockCreditLine,
  unblockCreditLinesByFilter,
} = service;
