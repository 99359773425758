import { ThemeProvider } from '@hexa-ui/components2';
import React, { forwardRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { StyledCard } from './styles';

interface DropdownProps {
  parentRef: React.RefObject<HTMLElement>;
  isOpen: boolean;
  children: React.ReactNode;
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ parentRef, isOpen, children }, ref) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [dropdownWidth, setDropdownWidth] = useState(0);
    const [dropdownHeight, setDropdownHeight] = useState(0);

    useEffect(() => {
      if (ref && 'current' in ref && ref.current) {
        setDropdownWidth(ref.current.offsetWidth);
        setDropdownHeight(ref.current.offsetHeight);
      }
    }, [ref, isOpen]);

    useEffect(() => {
      if (parentRef.current && isOpen) {
        const triggerButtonPosition = parentRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        const isNearBottom = triggerButtonPosition.bottom + dropdownHeight > viewportHeight; //check if dropdown trigger appears near bottom, and make it appear on top of button
        const isNearRight = triggerButtonPosition.left + dropdownWidth > viewportWidth; // same thing, but now with right

        const top = isNearBottom
          ? triggerButtonPosition.top + window.scrollY - dropdownHeight - 5
          : triggerButtonPosition.bottom + window.scrollY + 5;

        const left = isNearRight
          ? triggerButtonPosition.right + window.scrollX - dropdownWidth
          : triggerButtonPosition.left + window.scrollX;

        setPosition({
          top,
          left,
        });
      }
    }, [parentRef, isOpen, dropdownWidth, dropdownHeight]);

    if (!isOpen) {
      return null;
    }

    return ReactDOM.createPortal(
      <ThemeProvider>
        <StyledCard
          ref={ref}
          style={{ top: `${position.top}px`, left: `${position.left}px`, pointerEvents: 'all' }}
          elevated="small"
          border="small"
        >
          {children}
        </StyledCard>
      </ThemeProvider>,
      document.body
    );
  }
);

export default Dropdown;
