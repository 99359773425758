import styled from 'styled-components';

export const CreditLineButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: auto;
  column-gap: 8px;
`;
