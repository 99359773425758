import { Alert, Button, Heading, Radio } from '@hexa-ui/components';
import { Check2 } from '@hexa-ui/icons';
import { PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useMemo, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormatting } from '../../../hooks/useFormatting';
import { useGetSources } from '../../../hooks/useGetSources';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../hooks/useRequester';
import { useToast } from '../../../hooks/useToast';
import { Status } from '../../../interfaces';
import { updateStatus } from '../../../services';
import { Input } from '../../atoms/Input/Input';
import { Modal } from '../Modal/Modal';
import {
  InitialMapping,
  OfferCreditProps,
  OfferCreditState,
  PayloadOfferCredit,
} from './OfferCredit.types';
import {
  InfoDescription,
  InputContainer,
  ModalContent,
  RadioContainer,
  TextContainer,
} from './styles';

export const OfferCredit: React.FC<OfferCreditProps> = ({
  data,
  trigger,
  open,
  portal,
  callbackFn,
}) => {
  const { formatMessage } = useIntl();
  const { post } = useRequester();

  const { getValidation } = useGetSources();
  const { formatting } = useFormatting();
  const { toast } = useToast();

  const { user, currency, permissions, configs } = useGetUserPreferences();
  const { validations } = configs;

  const [onboardingByPass, setOnboardingByPass] = useState<boolean>(undefined);

  const onRadio = (value: string) => setOnboardingByPass(value === 'doNotRequest');

  const reducer = useCallback(
    (state: OfferCreditState, { type, payload }: PayloadAction<PayloadOfferCredit>) => {
      if (type === 'CLEAR' || !payload) return initial;

      const { has, message } = getValidation(
        payload.id,
        payload.state.value === '00' ? '' : payload.state.value,
        validations[payload.id]
      );

      if (type === 'SETTER' && payload.state.value && payload.state.value !== '00') {
        return {
          ...state,
          [payload.id]: {
            value: payload.state.value,
            formatted: payload.state.formatted,
            error: { has, message },
          },
        };
      }

      return {
        ...state,
        [payload.id]: {
          value: '',
          formatted: { simple: '', completed: '' },
          error: { has, message },
        },
      };
    },
    []
  );

  const initial = useMemo(() => {
    const initalMapping: InitialMapping = {
      limit: { type: 'currency', text: '' },
      term: { type: 'days', text: '' },
      'term.Fee': { type: 'percentage', text: '' },
    };

    return Object.entries(initalMapping).reduce((acc, [key, { type, text }]) => {
      const { value, formatted } = formatting({ type, value: text });

      acc[key] = {
        value,
        formatted: formatted,
        error: { has: false, message: '' },
      };

      return acc;
    }, {});
  }, []);

  const [state, dispatch] = useReducer(reducer, initial);

  const { isDisabled } = useMemo(() => {
    const keyMapping: { [key: string]: string } = {
      limit: 'creditLimit',
      term: 'term',
      'term.Fee': 'fee',
    };

    const { hasErrors, allEqual } = Object.entries(state as OfferCreditState).reduce(
      (acc, [key, { error, value, formatted }]) => {
        const newValue = key === 'term.Fee' ? formatted.simple : value;

        acc.hasErrors.push(error.has || !value || onboardingByPass === undefined);
        acc.allEqual.push(newValue === data[keyMapping[key]]);
        return acc;
      },
      { hasErrors: [], allEqual: [] }
    );

    return { isDisabled: hasErrors.some((error) => error) || allEqual.every((equal) => equal) };
  }, [state, onboardingByPass]);

  const onChange = useCallback(({ id, value, formatted }) => {
    dispatch({ type: 'SETTER', payload: { id, state: { value, formatted } } });
  }, []);

  const onClear = () => {
    dispatch({ type: 'CLEAR', payload: null });

    if (open?.value) open.setter(false);
  };

  const onCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClear();
  };

  const onApply = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const response = await updateStatus({
      api: { post },
      params: [
        {
          bees_account_id: data.beesAccountId,
          status: Status.APPROVED,
          user_id: user.name,
          limit: Number(state['limit'].value),
          term: Number(state['term'].value),
          fee: Number(state['term.Fee'].value) / 100,
          attributes: [
            { name: 'ONBOARDING_BYPASS', value: onboardingByPass.toString() },
            { name: 'POC_DOCUMENT', value: data.pocDocument },
          ],
        },
      ],
    });

    if (!response?.success) {
      toast.error({ message: 'errors.generic' });

      return;
    }

    toast.success({
      message: 'offerCredit.toasts.success',
      attributes: { value: data.pocName },
    });

    if (callbackFn) callbackFn();
  };

  if (!permissions.has('actions.recommendation.edit')) {
    return null;
  }

  return (
    <Modal
      open={open}
      portal={portal}
      trigger={trigger}
      title={<Heading size="H2">{formatMessage({ id: 'offerCredit.title' })}</Heading>}
      onClose={onCancel}
      onInteractOutside={onClear}
      actions={{
        buttons: {
          cancel: (
            <Button id="on-cancel" size="medium" variant="secondary" onClick={onCancel}>
              {formatMessage({ id: 'offerCredit.buttons.cancel' })}
            </Button>
          ),
          confirm: (
            <Button
              id="on-apply"
              size="medium"
              variant="primary"
              disabled={isDisabled}
              onClick={onApply}
              leading
              icon={Check2}
            >
              {formatMessage({ id: 'offerCredit.buttons.apply' })}
            </Button>
          ),
        },
      }}
    >
      <ModalContent data-testid="modal-content" onClick={(e) => e.stopPropagation()}>
        <Alert
          type="warning"
          message={formatMessage({ id: 'offerCredit.warning' })}
          css={{ width: '100%' }}
        />

        <Heading size="H4">{`${data.pocName} - ${data.accountId}`}</Heading>

        <InputContainer data-testid="input-container">
          <Input.Text
            id="limit"
            format="currency"
            size="large"
            width="100%"
            height="115px"
            prefix={currency.config.symbol}
            label={formatMessage({ id: 'offerCredit.inputs.creditLimit.label' })}
            placeholder={formatMessage({ id: 'offerCredit.inputs.creditLimit.placeholder' })}
            error={{ has: state['limit'].error.has, message: state['limit'].error.message }}
            value={state['limit'].formatted.simple}
            onChange={onChange}
          />

          <Input.Text
            id="term"
            format="days"
            size="large"
            width="100%"
            height="115px"
            suffix={formatMessage(
              { id: 'formatting.input.suffix.days' },
              { value: state['term'].formatted.simple }
            )}
            label={formatMessage({ id: 'offerCredit.inputs.term.label' })}
            placeholder="0"
            error={{ has: state['term'].error.has, message: state['term'].error.message }}
            value={state['term'].formatted.simple}
            onChange={onChange}
          />

          <Input.Text
            id="term.Fee"
            format="percentage"
            size="large"
            width="100%"
            height="115px"
            suffix={formatMessage({ id: 'formatting.input.suffix.percentage' })}
            label={formatMessage({ id: 'offerCredit.inputs.fee.label' })}
            placeholder={'0'}
            error={{ has: state['term.Fee'].error.has, message: state['term.Fee'].error.message }}
            value={state['term.Fee'].formatted.simple}
            onChange={onChange}
          />
        </InputContainer>

        <TextContainer data-testid="text-container">
          <Heading size="H5">{formatMessage({ id: 'offerCredit.info.title' })}</Heading>

          <InfoDescription size="small">
            {formatMessage({ id: 'offerCredit.info.description' })}
          </InfoDescription>
        </TextContainer>

        <Radio.Root onValueChange={onRadio}>
          <RadioContainer>
            <Radio.Item
              id="request"
              value="request"
              label={formatMessage({ id: 'offerCredit.radios.request' })}
            />

            <Radio.Item
              id="doNotRequest"
              value="doNotRequest"
              label={formatMessage({ id: 'offerCredit.radios.doNotRequest' })}
            />
          </RadioContainer>
        </Radio.Root>
      </ModalContent>
    </Modal>
  );
};
