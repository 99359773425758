import { Heading, LoadingDots, Pagination, Paragraph } from '@hexa-ui/components';
import { SortAscending, SortDefault, SortDescending } from '@hexa-ui/icons';
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { rowHeight } from '../../../constants';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useSelectable } from '../../../hooks/useSelectable';
import { DataType, GenericObject, TableProps, Tables } from '../../../interfaces';
import { Input } from '../Input/Input';
import { EmptyMessage } from './Empty/EmptyMessage';
import { TableExtra } from './Extra/TableExtra';
import {
  Container,
  Content,
  Footer,
  HeaderContainer,
  LoadingContainer,
  SortContent,
  Styled,
  Toolbar,
} from './styles';

export const Table: React.FC<TableProps<Tables>> = ({
  table: type,
  data,
  columns: configColumns,
  loading,
  messages,
  tableHeight = 'min(100vh - 350px)',
  search = { has: false },
  views = { has: false },
  sorting = { has: false, state: [] },
  filters = { has: false, chips: false },
  selectable = { has: false, counter: 0, state: {} },
  pagination = { page: 1, size: 100 },
  emptyStateMaxWidth,
  toolbarExtra,
  onRow,
}) => {
  const { formatMessage } = useIntl();

  const { configs } = useGetUserPreferences();
  const { pagination: paginationConfig } = configs;

  const { selection } = useSelectable({ selectable });

  const helper = createColumnHelper<DataType[typeof type]>();

  const { columns } = useMemo(() => {
    const mappedColumns: ColumnDef<DataType[typeof type], any>[] = [];

    configColumns.forEach((column) =>
      mappedColumns.push(
        helper.accessor(column.accessor, {
          size: column.sizing.size,
          minSize: column.sizing.minSize,
          header: formatMessage({ id: `tables.${type}.columns.${column.accessor}` }),
          enableSorting: column.sorting.has,
          ...(column.sorting.fn && { sortingFn: column.sorting.fn }),
          cell: ({ row }) => column.render(row.original[column.accessor]),
        })
      )
    );

    if (selectable.has && mappedColumns.length) {
      mappedColumns.unshift(
        helper.accessor('select', {
          size: 5,
          minSize: 80,
          enableSorting: false,
          header: ({ table }) => {
            const checked = table.getIsAllRowsSelected();
            const indeterminate =
              table.getIsSomePageRowsSelected() ||
              Object.values(selectable.state).some(
                (value) => value !== Object.values(selectable.state)[0]
              );

            return (
              <Input.Checkbox
                id="header-all-select"
                size="medium"
                name="header-select"
                checked={indeterminate ? 'indeterminate' : checked}
                value={String(indeterminate ? 'indeterminate' : checked)}
                onChange={({ value }) => selection.onSelected({ value, data: table })}
              />
            );
          },
          cell: ({ row }) => (
            <Input.Checkbox
              id={`row-${row.id}-select`}
              size="medium"
              name="row-select"
              checked={row.getIsSelected()}
              value={String(row.getIsSelected())}
              onChange={({ value }) => selection.onSelected({ value, data: row })}
            />
          ),
        })
      );
    }

    return { columns: mappedColumns };
  }, [configColumns, type, selectable, helper]);

  const table = useReactTable({
    data,
    columns,
    manualSorting: sorting.has,
    enableRowSelection: selectable.has,
    state: { sorting: sorting.state, rowSelection: selectable.state },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...(sorting.has && { onSortingChange: sorting.onSorting }),
    ...(selectable.has && { getRowId: (row) => row[selectable.identifier] }),
  });

  const { rows } = table.getRowModel();
  const tableRef = useRef<HTMLTableElement>(null);

  const virtualizer = useVirtualizer({
    count: rows.length,
    overscan: 15,
    estimateSize: () => rowHeight,
    getScrollElement: () => tableRef.current,
  });

  const { widthPercentage, minWidth } = table.getAllColumns().reduce(
    (acc, column) => {
      acc.widthPercentage += column.columnDef.size;
      acc.minWidth += column.columnDef.minSize;

      return acc;
    },
    { widthPercentage: 0, minWidth: 0 }
  );

  const onRowClick = useCallback(
    (rows: GenericObject) => ({ onClick: () => onRow(rows) }),
    [onRow]
  );

  const tablePagination = useMemo(() => {
    return {
      quantityIndicatorIntl: formatMessage({ id: 'tables.pagination.quantity' }),
      pageSizeOptionsIntl: (option: number) =>
        formatMessage({ id: 'tables.pagination.itensPerPage' }, { numberPage: option }),
      current: pagination.page,
      pageSize: pagination.size,
      pageSizeOptions: paginationConfig.sizeOptions,
      showPageSizeSelector: true,
      ...(pagination.total && { total: pagination.total }),
      ...(pagination.onChange && { onChange: pagination.onChange }),
    };
  }, [pagination]);

  return (
    <Container border="medium" elevated="small">
      <Toolbar data-testid="table-toolbar">
        <TableExtra
          table={type}
          views={views}
          search={search}
          filters={filters}
          toolbarExtra={toolbarExtra}
          selectable={{ ...selectable, onClear: selection.onClear?.(table.toggleAllRowsSelected) }}
        />
      </Toolbar>

      <Content ref={tableRef} $height={tableHeight} data-testid="table-content">
        <Styled.Table $minwidth={minWidth} data-testid="table">
          <Styled.Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Styled.TrHead key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Styled.Th
                    key={header.id}
                    data-testid={`header-${header.id}`}
                    $hassort={header.column.getCanSort()}
                    $showsort={!header.column.getIsSorted()}
                    $size={(header.column.columnDef.size * 100) / widthPercentage}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <HeaderContainer>
                      <Heading selectable="false" size="H5" css={{ margin: 0 }}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Heading>

                      {header.column.getCanSort() && (
                        <SortContent
                          $hassort={header.column.getCanSort()}
                          $showsort={!header.column.getIsSorted()}
                        >
                          {
                            {
                              asc: <SortAscending size="medium" />,
                              desc: <SortDescending size="medium" />,
                              false: <SortDefault size="medium" className="sort-icon" />,
                            }[header.column.getIsSorted() as string]
                          }
                        </SortContent>
                      )}
                    </HeaderContainer>
                  </Styled.Th>
                ))}
              </Styled.TrHead>
            ))}
          </Styled.Thead>

          <Styled.Tbody $height={!loading && data.length ? virtualizer.getTotalSize() : undefined}>
            {(() => {
              if (loading) {
                return (
                  <tr>
                    <td colSpan={columns.length}>
                      <LoadingContainer data-testid="loading-container">
                        <LoadingDots />

                        <Paragraph size="basis">{messages.loading}</Paragraph>
                      </LoadingContainer>
                    </td>
                  </tr>
                );
              }

              if (!loading && !data.length) {
                return (
                  <tr>
                    <td colSpan={columns.length}>
                      <EmptyMessage
                        message={messages.empty.message}
                        Icon={messages.empty.Icon}
                        maxWidth={emptyStateMaxWidth}
                      />
                    </td>
                  </tr>
                );
              }

              return virtualizer.getVirtualItems().map((virtual) => {
                const row = rows[virtual.index];

                return (
                  <Styled.TrBody
                    key={row.id}
                    data-testid={`row-${row.id}`}
                    data-index={virtual.index}
                    $start={virtual.start}
                    $hasclick={!!onRow}
                    $selected={row.getIsSelected()}
                    {...onRowClick(row.original)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Styled.Td
                        key={cell.id}
                        $size={(cell.column.columnDef.size * 100) / widthPercentage}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Styled.Td>
                    ))}
                  </Styled.TrBody>
                );
              });
            })()}
          </Styled.Tbody>
        </Styled.Table>
      </Content>

      <Footer>{pagination.total > 0 && <Pagination {...tablePagination} />}</Footer>
    </Container>
  );
};
