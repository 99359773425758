import { TypeToast } from 'admin-portal-shared-services';
import { FileStatus, NotificationsData } from '../../../interfaces';

export const notifications: NotificationsData = {
  [FileStatus.DONE]: [
    {
      condition: { key: 'failedCount', type: 'HAS_NOT' },
      type: TypeToast.SUCCESS,
      message: 'massApproval.toasts.recommendationSuccess',
      attributes: ['value:total'],
    },
    {
      condition: { key: 'failedCount', type: 'HAS' },
      type: TypeToast.WARNING,
      message: 'massApproval.toasts.issueMessage',
      attributes: ['value:processed', 'total:total', 'issue:failedCount'],
    },
  ],
  [FileStatus.FAILED]: [
    {
      type: TypeToast.ERROR,
      message: 'massApproval.toasts.errorApproved',
      attributes: ['value:total'],
    },
  ],
};
