import { Chip } from '@hexa-ui/components';
import styled from 'styled-components';

export const ChipRoot = styled(Chip.Root)`
  background-color: rgba(20, 20, 20, 0.04);
  border: 1px solid transparent;

  &:hover {
    border: 1px solid rgba(0, 110, 219, 1);
    background-color: rgba(20, 20, 20, 0.04);
  }
`;
