import { Button, IconButton, LoadingDots, Paragraph, Tooltip } from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { Status } from '../../../../interfaces';
import SegmentService from '../../../../services/segment';
import { DenyRecommendation } from '../../DenyRecommendation/DenyRecommendation';
import { ApprovalProps } from '../ApprovalButtons.types';

export const Deny: React.FC<ApprovalProps> = ({
  mode,
  type,
  button,
  counter,
  isLoading,
  portal,
  onApproval,
}) => {
  const { formatMessage } = useIntl();

  const { permissions, selected: sellerSelected } = useGetUserPreferences();

  const [open, setOpen] = useState<boolean>(false);

  const onDeny = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>, status: Status) => {
      event.stopPropagation();

      if (mode !== 'multiple') await onApproval(status);
    },
    [onApproval]
  );

  const buttonClicked = (buttonLabel: string, buttonName: string, eventName: string) => {
    SegmentService.paymentsButtonClicked(
      buttonLabel,
      buttonName,
      sellerSelected.vendorId,
      eventName,
      'CREDIT_MANAGEMENT_PENDING_TAB'
    );
  };

  const buttonMapping = useMemo(() => {
    return {
      'icon-button': (
        <IconButton
          id="deny-icon-button"
          data-testid="deny-icon-button"
          icon={() => (
            <Tooltip
              placement="bottom"
              text={formatMessage({ id: 'approval.buttons.DENIED.tooltip' })}
            >
              <X size="medium" />
            </Tooltip>
          )}
          size={button.size}
          variant="tertiary"
          loading={isLoading}
          disabled={isLoading}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            if (portal?.has) setOpen(true);

            onDeny(event, Status.DENIED);
            buttonClicked('Deny Icon', 'Deny Icon Button', 'Credit Management Deny Recommendation');
          }}
        />
      ),
      button: (
        <Button
          id="deny-button"
          data-testid="deny-button"
          leading
          icon={() =>
            isLoading ? <LoadingDots data-testid="loading-dots" /> : <X size="medium" />
          }
          size={button.size}
          variant="secondary"
          disabled={isLoading}
          css={{}}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            if (portal?.has) setOpen(true);

            onDeny(event, Status.DENIED);
            buttonClicked(
              'Deny Selected',
              'Deny Selected Button',
              'Credit Management Mass Approval Deny Selected'
            );
          }}
        >
          <Paragraph size="small">
            {formatMessage({ id: `approval.buttons.DENIED.${mode}` })}
          </Paragraph>
        </Button>
      ),
    };
  }, [isLoading, button]);

  if (!permissions.has('actions.recommendation.deny')) {
    return null;
  }

  if (mode === 'multiple') {
    return (
      <DenyRecommendation
        data={{ counter }}
        trigger={buttonMapping[type]}
        onApproval={onApproval}
        {...(portal?.has && { portal, open: { value: open, setter: setOpen } })}
      />
    );
  }

  return buttonMapping[type];
};
