import { Divider, Heading, LoadingDots, Paragraph } from '@hexa-ui/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { getSummaryV2 } from '../../../../services';
import { useCredit } from '../../Credit/Context/CreditContext';
import { SummaryContainer, SummaryItem } from './styles';
import { useFormatting } from '../../../../hooks/useFormatting';

export const SummaryV2: React.FC = () => {
  const { formatMessage } = useIntl();
  const { formatting } = useFormatting();
  const { get } = useRequester();
  const { language, configs, permissions } = useGetUserPreferences();
  const { status } = configs;

  const { hasApproval } = useCredit();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [summary, setSummary] = useState({
    pendingCount: '0',
    capitalAllocation: '0',
  });

  const getData = async () => {
    setIsLoading(true);

    const response = await getSummaryV2({
      api: { get },
      params: { status: status?.pending?.join(',') },
    });

    if (!response?.success) {
      setSummary({ pendingCount: '0', capitalAllocation: '0' });
      setIsLoading(false);

      return;
    }

    setSummary(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [hasApproval.value]);

  if (!permissions.has('sections.recommendation.summary')) {
    return <></>;
  }

  return (
    <SummaryContainer elevated="small" border="medium" $isloading={isLoading}>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <React.Fragment>
          <SummaryItem>
            <Paragraph size="small" css={{ lineHeight: '24px' }}>
              {formatMessage({ id: `pages.recommendation.summary.recommendations` })}
            </Paragraph>

            <Heading size="H4">
              {Number(summary.pendingCount).toLocaleString(language.locale)}
            </Heading>
          </SummaryItem>

          <Divider orientation="vertical" css={{ height: '52px !important' }} />

          <SummaryItem>
            <Paragraph size="small" css={{ lineHeight: '24px' }}>
              {formatMessage({ id: `pages.recommendation.summary.capitalAllocation` })}
            </Paragraph>

            <Heading size="H4">
              {
                formatting({ type: 'currency', value: summary.capitalAllocation }).formatted
                  .completed
              }
            </Heading>
          </SummaryItem>
        </React.Fragment>
      )}
    </SummaryContainer>
  );
};
