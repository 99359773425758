import { Locales } from '../../interfaces';

const esAL: Locales = {
  countries: {
    AQ: 'Antártida',
    AR: 'Argentina',
    BB: 'Barbados',
    BE: 'Bélgica',
    BO: 'Bolivia',
    BR: 'Brasil',
    CA: 'Canadá',
    IC: 'Islas Canarias',
    CL: 'Chile',
    CO: 'Colombia',
    CZ: 'República Checa',
    DO: 'República Dominicana',
    EC: 'Ecuador',
    SV: 'El Salvador',
    DE: 'Alemania',
    HN: 'Honduras',
    IN: 'India',
    ID: 'Indonesia',
    MX: 'México',
    NL: 'Países Bajos',
    NG: 'Nigeria',
    PA: 'Panamá',
    PY: 'Paraguay',
    PE: 'Perú',
    PH: 'Filipinas',
    ZA: 'Sudáfrica',
    KR: 'Corea del Sur',
    TZ: 'Tanzania',
    TH: 'Tailandia',
  },
  select: {
    country: 'Seleccione un país',
    vendor: 'Seleccione un proveedor',
  },
  routes: {
    management: 'Gestión de crédito',
    recommendation: 'Recomendaciones de crédito',
    credit: 'Gestión de crédito',
    upload: 'Actualizar condiciones de crédito',
    progress: 'Progreso del archivo',
    pocDetails: 'Detalles del PDV',
    files: 'Archivos de PDV',
    views: 'Vista del cliente',
  },
  pages: {
    management: {
      title: 'Gestión de crédito',
    },
    credit: {
      title: 'Gestión de crédito',
      summary: {
        total: 'PDVs totales',
        pending: 'Aprobaciones pendientes',
        inprogress: 'En curso',
        active: 'Crédito activo',
        denied: 'Crédito denegado',
      },
    },
    recommendation: {
      title: 'Recomendaciones de crédito',
      summary: {
        recommendations: 'Recomendaciones',
        capitalAllocation: 'Asignación de capital',
      },
    },
    files: {
      title: 'Archivos de PDV',
    },
    upload: {
      title: 'Actualizar condiciones de crédito',
      uploader: {
        title: 'Carga de archivo',
        errors: {
          noFile: 'Debes cargar un archivo para continuar.',
          maxFileSize: 'El archivo excede el límite de { maxFileSize }MB.',
          invalidFormat:
            'Formato de archivo no válido. El archivo debe estar en formato CSV o XLSX.',
        },
        alertMessage: 'A partir de este paso, puedes salir de esta página sin perder tu progreso.',
        rulesAndConditions: {
          modalLink: `  reglas y condiciones.`,
          description: `
            Actualiza las condiciones de crédito de los PDV cargando un archivo. El archivo debe cumplir con las `,
          progress: {
            description:
              'Analizar el archivo para garantizar que todos los PDV cumplan con las <TextLink>reglas y condiciones</TextLink> para las actualizaciones de crédito.',
          },
          modal: {
            title: 'Reglas y condiciones',
            description:
              'Estas reglas son válidas para cargar archivos con nuevas condiciones de crédito. Los PDV con errores deberán corregirse y cargarse nuevamente.',
            files: {
              title: 'Reglas de archivo',
              wontConsider: 'No se considerará:',
              items: {
                duplicatedPOCLines:
                  '<strong> Líneas PDV duplicadas. </strong>Si el archivo tiene dos líneas en el ID de cuenta con la misma información, solo se considerará la primera línea.',
                newlyIncluded:
                  '<strong> Líneas PDV recientemente incluidas. </strong>Se mostrarán como “PDV no existente”.',
                removeLines: '<strong>Líneas PDV eliminadas </strong>',
                blankFields: '<strong>Campos PDV en blanco</strong>',
                newColumns: '<strong>Columnas recientemente agregadas</strong> ',
              },
            },
            credit: {
              title: 'Reglas de crédito',
              items: {
                creditLimit: '<strong>Límite de crédito: </strong>',
                creditTerm: '<strong>Plazo de crédito: </strong>',
                creditFee: '<strong>Tasa por crédito: </strong>',
              },
            },
          },
        },
        fileUploader: {
          title: 'Arrastra tu archivo aquí o ',
          label: 'navega',
          description:
            'El formato debe ser XLSX o CSV. Tamaño máximo de { maxFileSize }MB por archivo.',
        },
        fileDescription: {
          title: 'Descripción del archivo',
          subTitle: '(opcional)',
          description:
            'Haz que este archivo sea más fácil de identificar proporcionando algunos detalles.',
        },
        steps: {
          fileUpload: 'Carga de archivo',
          fileAnalysis: 'Análisis de archivos',
          pocUpdate: 'Actualizar PDV',
        },
      },
      buttons: {
        back: 'Cancelar',
        upload: 'Analizar archivo',
        apply: 'Actualizar PDV',
        goToCredit: 'Ir al crédito',
        goToFiles: 'Ir a archivos PDV',
        uploadNewFile: 'Cargar un archivo nuevo',
        toasts: {
          analysis: 'Se actualizaron correctamente { validated } PDV.',
          processing: 'Archivo en procesamiento. { fileName }',
          success: '¡El archivo se cargó correctamente! { fileName }',
          warning: 'Archivo cargado con errores. { fileName }',
          error: 'Error de carga. { fileName }',
        },
      },
      progress: {
        title: 'Análisis de archivos',
        processingTitle: 'Actualizar PDV',
        processingDescription:
          'Actualizar las condiciones de crédito de los PDV. Este paso puede demorar un poco y se te notificará una vez que se complete.',
        meter: {
          starting: 'Inicio',
          progress: '{ progressValue } de { progressMaxValue } PDV analizados.',
          progressUpload: '{ progressValue } out of { progressMaxValue } PDV actualizados.',
          description:
            'El tiempo de análisis puede ser más largo cuando el archivo contiene muchos PDV.',
          descriptionProgressing:
            'El tiempo de procesamiento puede ser más largo cuando hay muchos PDV para actualizar.',
          error: 'El archivo no contiene PDV válidos. Revisa el archivo y cárgalo nuevamente.',
          slow: 'La carga está tardando más de lo normal. Espera un momento.',
        },
        finished: {
          title: 'Análisis de resultados',
          pocs: {
            total: 'PDV analizados',
            success: 'PDV a ser actualizados',
            error: 'PDV con errores',
            tooltip:
              'Solo es posible aumentar el límite de crédito al 200% del monto sugerido, y el plazo máximo permitido es de 30 días.',
          },
          alert:
            'Puedes continuar con los PDV que deseas actualizar. Para corregir errores, descarga la lista de errores y carga un archivo nuevo.',
          download: 'Descargar lista de errores',
        },
        error: {
          analysis:
            'No fue posible completar el análisis del archivo. Carga el archivo nuevamente.',
          processing:
            'No se puede completar la actualización de PDV. Intenta cargar el archivo nuevamente.',
        },
      },
      cancelModal: {
        title: '¿Cancelar actualización?',
        subtitle: 'Se perderá tu progreso y deberás comenzarlo de nuevo.',
        buttons: { confirm: 'Sí, cancelar', cancel: 'No, volver' },
      },
    },
    'poc-details': {
      recommendation: {
        title: 'Recomendación de línea de crédito',
        status: {
          pending: 'Pendiente',
          denied: 'Denegado',
          waitingActivation: 'En espera de activación',
          waitingOnboarding: 'En espera de onboarding',
        },
        labels: {
          creditLimit: 'Límite de crédito',
          termFee: 'Plazo/tasa',
          recommendation: 'Recomendación',
          updatedOn: 'Actualizado el',
          approvedBy: 'Editado por',
          approvalMethod: 'Editar método',
        },
        timeline: {
          title: 'Plazo de recomendación',
          steps: {
            APPROVED: 'Aprobado',
            ONBOARDING: 'Onboarding',
            ACTIVATION: 'Proceso de activación',
            ACTIVATED: 'Línea de crédito activada',
          },
        },
      },
      information: {
        title: 'Información de PDV',
        labels: {
          pocName: 'Nombre',
          pocDocument: 'ID',
          pocAddress: 'Dirección',
          pocEmail: 'Correo electrónico',
          pocPhoneNumber: 'Número de teléfono',
          pocAccount: 'ID de cuenta',
        },
      },
      conditions: {
        title: 'Condiciones actuales de la línea de crédito',
        status: {
          active: 'Activo',
          blocked: 'Bloqueado',
          overdue: 'Atrasado',
          due: 'Pendiente',
        },
        labels: {
          creditLimit: 'Límite de crédito',
          limitAvailable: 'Límite disponible',
          balance: 'Línea utilizada',
          overdue: 'Atrasado',
          termFee: 'Plazo/tarifa',
          paymentMethod: 'Forma de pago',
          activatedOn: 'Activado el',
          approvedBy: 'Aprobado por',
          approvalMethod: 'Método de aprobación',
          fileName: 'Nombre del archivo',
          fileDescription: 'Descripción del archivo',
        },
      },
      history: {
        credit: {
          title: 'Historial de crédito',
          description:
            'Todos los cambios de estado realizados a las condiciones de crédito del PDV.',
          buttons: { trigger: 'Mostrar historial de crédito' },
          information: {
            resume: '{ limit } - { term } - { fee }',
            by: 'Por { identifier }',
          },
          identifiers: {
            backend: 'backend',
            poc: 'PDV',
          },
          labels: {
            byAndHour: 'por { identifier } en { hour }',
            creditLimit: 'Límite de crédito:',
            termFee: 'Plazo/tarifa:',
            method: {
              edit: 'Editar método:',
              approval: 'Método de aprobación:',
              denial: 'Método de denegación:',
            },
          },
          status: {
            NEW_CREDIT_RECOMMENDATION: 'Nueva recomendación crediticia',
            CREDIT_RECOMMENDATION_APPROVED: 'Recomendación de crédito aprobada',
            CREDIT_RECOMMENDATION_DENIED: 'Recomendación de crédito denegada',
            WAITING_ONBOARDING_BYPASSED: 'Onboarding omitido',
            WAITING_ONBOARDING_COMPLETED: 'Onboarding completado',
            CREDIT_LINE_ACTIVATED: 'Línea de crédito activada',
            CREDIT_LINE_BLOCKED: 'Línea de crédito bloqueada por falta de pago',
            CREDIT_LINE_UNBLOCKED: 'Línea de crédito desbloqueada',
          },
        },
      },
    },
    views: {
      title: 'Vista del cliente',
      description:
        'Crea visualizaciones personalizadas en la tabla según tus requisitos de análisis.',
      buttons: { back: 'Voltar atrás', save: 'Guardar y aplicar' },
      toasts: {
        warning: 'Puedes agregar un máximo de 6 columnas a una tabla.',
        success: '{ name } se ha guardado y aplicado a la tabla.',
      },
      inputs: {
        name: {
          label: 'Ver nombre',
          placeholder: 'Ingresar nombre',
        },
        type: {
          label: 'Tipo de vista',
          options: {
            RECOMMENDED: {
              label: 'Recomendaciones de crédito',
              tooltip: 'PDV marcados por el algoritmo como buenos candidatos para crédito.',
            },
            NOT_RECOMMENDED: {
              label: 'PDV sin recomendación de crédito',
              tooltip: 'PDV no marcados por el algoritmo como buenos candidatos para crédito.',
            },
          },
        },
      },
      columns: {
        select: { description: 'Selecciona hasta 6 columnas para mostrar en la tabla.' },
        order: {
          description: 'Ordena las columnas seleccionadas según el orden de tu preferencia.',
        },
      },
    },
  },
  formatting: {
    datetime: { 'long-time': `{ date } en { time }` },
    input: {
      text: {
        percentage: '{ value } %',
        days: '{value, plural, =1 {# día} other {# días}}',
      },
      suffix: {
        percentage: '%',
        days: '{value, plural, =1 {día} other {días}}',
      },
    },
    recommendation: {
      NEW_CREDIT_OFFER: 'Oferta de crédito',
      INCREASE: 'Aumento de crédito',
      DECREASE: 'Disminución del crédito',
    },
    'approval-method': {
      FILE_BATCH_UPDATE: 'Por archivo',
      SYSTEM: 'Por sistema',
    },
    'payment-method': {
      CASH: 'Efectivo',
      CREDIT: 'Línea disponible',
      BANK_SLIP: 'Orden de pago',
      BANK_SLIP_INSTALLMENT: 'Orden de pago cuota',
    },
    'term-fee': '{term, plural, =1 {# día} other {# días}} / {fee}',
    'list-items': '{items} y {last}',
  },
  tables: {
    loading: 'Cargando artículos...',
    empty: 'Aún no hay PDVs en esta lista.',
    error: {
      somethingWentWrong: 'Algo salió mal.',
      tryReloading: 'Intenta volver a cargar la página.',
    },
    collapseActionsMenu: {
      edit: 'Editar línea de crédito',
      editAndUnblock: 'Edit and unblock',
      unblock: 'Desbloquear línea de crédito',
      block: 'Bloquear línea de crédito',
      cancel: 'Cancelar línea de crédito',
    },
    search: 'ID o nombre de PDV',
    pagination: {
      quantity: 'de',
      itensPerPage: 'Artículos por página: { numberPage }',
    },
    selectable: {
      selected: '{counter, plural, =1 {# cliente seleccionado} other {# clientes seleccionados}}',
      deselect: 'Anular la selección de todo',
    },
    views: {
      selector: { label: 'Visualización: { name }' },
      card: {
        custom: { title: 'Vistas del cliente' },
        alert:
          'Has alcanzado el límite de 10 vistas personalizadas. Elimina una vista para crear una nueva.',
        standard: {
          title: 'Vistas estándar',
          DEFAULT: 'Estándar',
          RECOMMENDED: 'Recomendaciones de crédito',
          NOT_RECOMMENDED: 'PDV sin recomendación de crédito',
        },
        buttons: { create: 'Crear nueva vista personalizada' },
        tooltips: { edit: 'Editar la vista', delete: 'Eliminar la vista' },
        modal: {
          title: '¿Eliminar vista personalizada?',
          description: 'Esta acción no se puede deshacer.',
          buttons: { cancel: 'No, volver', delete: 'Sí, eliminar' },
          toasts: { success: 'Tu vista personalizada ha sido eliminada.' },
        },
      },
    },
    filters: {
      empty: 'Sin resultados de filtro.',
      buttons: {
        filter: '{value, plural, =0 {Filtro} one {Filtro (1)} other {Filtros (#)}}',
        cancel: 'Cancelar',
        apply: 'Aplicar',
        clearAll: 'Borrar todo',
        clearDate: 'Borrar fecha',
      },
      chips: {
        min: 'Mínimo { min }',
        max: 'Máximo { max }',
        startDate: 'Fecha de inicio { min }',
        endDate: 'Fecha de finalización { max }',
        to: '{ min } a { max }',
        range: '{ min } - { max }',
      },
      inputs: {
        creditLimit: {
          name: 'Límite de crédito',
          min: { label: 'Límite mínimo', placeholder: 'Ingresar valor' },
          max: { label: 'Límite máximo', placeholder: 'Ingresar valor' },
        },
        term: {
          name: 'Plazo',
          min: {
            label: 'Plazo mínimo',
            placeholder: '0',
            sufix: '{value, plural, =1 {día} other {días}}',
          },
          max: {
            label: 'Plazo máximo',
            placeholder: '0',
            sufix: '{value, plural, =1 {día} other {días}}',
          },
        },
        maximumTerm: {
          name: 'Plazo máximo',
          min: {
            label: 'Plazo máximo menor',
            placeholder: '0',
            sufix: '{value, plural, =1 {día} other {días}}',
          },
          max: {
            label: 'Plazo máximo mayor',
            placeholder: '0',
            sufix: '{value, plural, =1 {día} other {días}}',
          },
        },
        fee: {
          name: 'Tasa',
          min: { label: 'Tasa mínima', placeholder: '0' },
          max: { label: 'Tasa máxima', placeholder: '0' },
        },
        monthlyFee: {
          name: 'Tasa mensual',
          min: { label: 'Tasa mínima', placeholder: '0' },
          max: { label: 'Tasa máxima', placeholder: '0' },
        },
        score: {
          name: 'Score',
          min: { label: 'Score mínimo', placeholder: '0 - 100' },
          max: { label: 'Score máximo', placeholder: '0 - 100' },
        },
        updatedAt: {
          name: 'Última actualización',
          min: { label: 'Fecha de inicio', placeholder: 'Seleccionar fecha' },
          max: { label: 'Fecha de finalización', placeholder: 'Seleccionar fecha' },
        },
        blockedDate: {
          name: 'Fecha de bloqueo',
          min: { label: 'Fecha de inicio', placeholder: 'Seleccionar fecha' },
          max: { label: 'Fecha de finalización', placeholder: 'Seleccionar fecha' },
        },
        paymentStatus: {
          name: 'Estado del pago',
          title: 'Estado del pago',
          labels: {
            current: 'En día',
            overdue: 'Atrasado',
          },
        },
        status: {
          name: 'Estado',
          title: 'Estado',
          labels: {
            processing: 'Procesando',
            integration: 'Onboarding',
            activated: 'Activo',
            denied: 'Denegado',
          },
        },
        recommendation: {
          name: 'Recomendación',
          title: 'Recomendación',
          labels: {
            creditOffer: 'Oferta de crédito',
            creditIncrease: 'Aumento de crédito',
            creditDecrease: 'Disminución del crédito',
          },
        },
        blockedReason: {
          name: 'Motivo de bloqueo',
          title: 'Motivo de bloqueo',
          labels: {
            current: 'Score bajo',
            overdue: 'Pago vencido',
            other: 'Otro'
          },
        }
      },
    },
    files: {
      tab: { title: 'Arquivos' },
      empty: {
        default: 'Aún no hay archivos en esta lista.',
        search: 'Sin resultados de búsqueda',
        error: 'Algo salió mal. Intenta volver a cargar la página.',
      },
      search: 'Buscar por nombre de archivo',
      status: {
        uploaded: 'Análisis pendiente',
        analyzing: 'Análisis en curso',
        analyzed: 'Actualización pendiente',
        processing: 'Actualización en curso',
        success: 'Completados',
      },
      columns: {
        fileName: 'Nombre del archivo',
        fileDescription: 'Descripción del archivo',
        total: 'PDVs actualizados',
        uploadedBy: 'Cargado por',
        uploadedAt: 'Actualizado el',
        status: 'Estado',
        actions: 'Acción',
      },
    },
    pending: {
      tab: { title: 'Pendiente' },
      columns: {
        pocName: 'PDV',
        accountId: 'ID de cuenta',
        creditLimit: 'Límite de crédito',
        term: 'Plazo máx.',
        fee: 'Tasa mensual',
        score: 'Score',
        recommendation: 'Recomendación',
        updatedAt: 'Última actualización',
        actions: 'Acción',
        salesAgency: 'Agencia de ventas',
        salesArea: 'Área de ventas',
        customerType: 'Tipo de cliente',
      },
    },
    inprogress: {
      tab: { title: 'En curso' },
      status: {
        processing: 'Procesando',
        integration: 'Onboarding',
      },
      columns: {
        pocName: 'PDV',
        accountId: 'ID de cuenta',
        creditLimit: 'Límite de crédito',
        term: 'Plazo',
        fee: 'Tasa',
        updatedAt: 'Última actualización',
        updatedBy: 'Actualizado por',
        status: 'Estado',
      },
    },
    completed: {
      tab: { title: 'Completado' },
      status: {
        active: 'Activo',
        denied: 'Denegado',
      },
      columns: {
        pocName: 'PDV',
        accountId: 'ID de cuenta',
        creditLimit: 'Límite de crédito',
        term: 'Plazo',
        fee: 'Tasa',
        updatedAt: 'Última actualización',
        updatedBy: 'Actualizado por',
        status: 'Estado de crédito',
      },
    },
    processing: {
      tab: { title: 'En procesamiento ({ quantity })' },
      columns: {},
    },
    active: {
      tab: { title: 'Crédito activo ({ quantity })' },
      columns: {
        accountId: 'ID de cuenta',
        creditLimit: 'Límite de crédito',
        fee: 'Tasa mensual',
        pocName: 'PDV',
        score: 'Score',
        term: 'Plazo máx.',
        paymentStatus: 'Estado del pago',
        actions: 'Acciones',
        updatedAt: 'Última actualización',
      },
      status: {
        current: 'En día',
        overdue: 'Atrasado',
      },
    },
    blocked: {
      tab: { title: 'Crédito bloqueado ({ quantity })' },
      columns: {
        accountId: 'ID de cuenta',
        creditLimit: 'Límite de crédito',
        fee: 'Taxa mensual',
        pocName: 'PDV',
        score: 'Score',
        term: 'Plazo máx.',
        blockedBy: 'Bloqueado por',
        blockedReason: 'Motivo de bloqueo',
        blockedDate: 'Fecha de bloqueo',
        actions: 'Acciones',
      },
    },
    canceled: {
      tab: { title: 'Crédito cancelado ({ quantity })' },
      columns: {},
    },
  },
  files: {
    buttons: {
      upload: 'Actualizar condiciones de crédito',
      download: 'Descargar PDV',
    },
    modal: {
      title: 'Descargar archivo',
      description:
        'Descarga los archivos para aprobar o denegar líneas de crédito de clientes. Los archivos no pueden contener:',
      itemsList: {
        first: 'Columnas agregadas o renombradas',
        second: 'Clientes agregados o renombrados',
        third:
          'Cualquier información que se haya agregado o modificado, excepto el estado de aprobación',
      },
      fileDetails: {
        title: 'Detalles del archivo',
        description: 'Selecciona la información que desea incluir en el archivo.',
        subTitle: 'Estado del cliente:',
        subDescription: 'Selecciona los estados que desea incluir en el archivo.',
        checkboxes: {
          all: 'Todos',
          pending: 'Aprobación pendiente  ',
          approved: 'Crédito aprobado',
          denied: 'Crédito denegado',
        },
      },
      buttons: {
        back: 'Volver',
        confirm: 'Generar y descargar archivo',
      },
      toasts: {
        info: 'El archivo se está generando y puede tardar unos segundos. Se descargará automáticamente cuando termine.',
      },
    },
    errors: {
      412: {
        description:
          'Ya hay otro archivo en proceso. Debes esperar hasta que termine antes de cargar un nuevo archivo.',
      },
    },
  },
  editRecommendation: {
    title: 'Editar recomendación de crédito',
    description:
      'Las recomendaciones se basan en el análisis de datos de PDV. Al editar una recomendación de crédito, debes seguir ciertas condiciones.',
    buttons: {
      cancel: 'Cancelar',
      apply: 'Aplicar cambios',
      tooltip: 'Editar estado',
      edit: { recommendation: 'Editar recomendación', conditions: 'Editar condiciones' },
    },
    toasts: { success: 'Se cambiaron las condiciones crediticias de { value }.' },
    inputs: {
      creditLimit: {
        label: 'Límite de crédito',
        hint: 'Sugerido: { value }.',
      },
      term: {
        label: 'Plazo de crédito',
        hint: 'Sugerido: {value, plural, =1 {# día} other {# días}}.',
      },
      fee: {
        label: 'Tasa por crédito',
      },
    },
  },
  approveRecommendation: {
    title: {
      single: '¿Aprobar la recomendación crediticia?',
      multiple: '¿Deseas aprobar las { counter } recomendaciones crediticias seleccionadas?',
    },
    description: {
      single: 'Selecciona la opción para este PDV.',
      multiple:
        'Aprobarás las recomendaciones crediticias para { counter } PDV. Esta acción no se puede deshacer.',
    },
    counter: 'Se seleccionaron { counter } PDV',
    info: {
      title: 'Solicitar incorporación de PDV',
      description:
        'Se solicitará el número de teléfono, la identificación del PDV, el comprobante de domicilio y la aceptación de los términos y condiciones.',
    },
    radios: { request: 'Solicitud', doNotRequest: 'No solicitar' },
    buttons: { back: 'No, cancelar', approve: 'Sí, aprobar' },
    warning: 'Si no solicitas la incorporación, asumirás la responsabilidad de esta decisión.',
  },
  denyRecommendation: {
    title: '¿Deseas denegar las { counter } recomendaciones crediticias seleccionadas?',
    description:
      'Denegarás las recomendaciones crediticias para { counter } PDV. Esta acción no se puede deshacer.',
    button: 'Sí, denegar',
  },
  offerCredit: {
    title: '¿Ofrecer crédito?',
    tooltip: 'Ofrece crédito.',
    warning:
      'En función de la información disponible, no hay recomendación de crédito para este PDV. Cualquier decisión de crédito que se tome se tomará a tu propia discreción.',
    info: {
      title: 'Solicitar incorporación de PDV',
      description:
        'Se solicitará el número de teléfono, la identificación del PDV, el comprobante de domicilio y la aceptación de los términos y condiciones.',
    },
    radios: { request: 'Solicitud', doNotRequest: 'No solicitar' },
    buttons: {
      cancel: 'Regresar',
      apply: 'Ofrecer crédito',
    },
    toasts: { success: 'Crédito ofrecido a { value }.' },
    inputs: {
      creditLimit: {
        label: 'Límite de crédito',
        placeholder: 'Ingresar un valor',
      },
      term: {
        label: 'Plazo de crédito',
        placeholder: 'días',
      },
      fee: {
        label: 'Tasa por crédito',
      },
    },
  },
  editCreditLineModal: {
    title: 'Editar línea de crédito',
    subtitle: '{ pocName }  |  ID: { accountId }',
    description:
      'Editar la línea de crédito en día del PDV requiere especial consideración. Los cambios a la línea de crédito impactan directamente los pedidos del PDV, así que procede con cautela.',
    inputs: {
      creditLimit: 'Límite de crédito',
      maximumCrediTerm: 'Plazo de crédito máximo',
      creditFee: 'Tasa por crédito mensual',
    },
    buttons: {
      back: 'Volver',
      apply: 'Aplicar cambios',
    },
    alert:
      'La edición de una línea de crédito no la actualiza de forma inmediata. Las solicitudes de cambio pasan por la pestaña En procesamiento antes de entrar en vigencia.',
    toasts: {
      success: 'Línea de crédito editada. Los cambios pueden tomar algo de tiempo en procesarse.',
      error: 'Error al editar la línea de crédito para { pocName }. Inténtalo de nuevo.',
    },
  },
  blockCreditLine: {
    single: {
      title: '¿Bloquear línea de crédito?',
      subtitle: '{ pocName }  |  ID: { accountId }',
      description:
        'El PDV no podrá usar la línea de crédito para sus pedidos hasta que resuelva sus asuntos pendientes o hasta que se lo desbloquee manualmente.',
      dropdown: { hint: 'Selecciona un motivo para bloquear esta línea de crédito.' },
      buttons: { cancel: 'Volver', apply: 'Bloquear línea de crédito' },
      toasts: {
        success:
          'La línea de crédito para { pocName } se está bloqueando. Esto puede tomar algo de tiempo en procesarse.',
        error: 'Error al bloquear la línea de crédito para { pocName }. Inténtalo de nuevo.',
      },
    },
    multiple: {
      title: '¿Bloquear líneas de crédito?',
      subtitle: 'Se seleccionaron { counter } PDV',
      description:
        'Estos PDV no podrán usar la línea de crédito para sus pedidos hasta que resuelvan sus asuntos pendientes o hasta que se los desbloquee manualmente.',
      dropdown: { hint: 'Selecciona un motivo para bloquear estas líneas de crédito.' },
      buttons: { cancel: 'Volver', apply: 'Bloquear líneas de crédito' },
      toasts: {
        success:
          'Las líneas de crédito para { counter } PDV se están bloqueando. Esto puede tomar algo de tiempo en procesarse.',
        error: 'Error al bloquear las líneas de crédito para { counter } PDV. Inténtalo de nuevo.',
      },
    },
    alert:
      'El bloqueo puede tomar hasta 24 horas en completarse. Haz un seguimiento del progreso en la pestaña En procesamiento.',
    dropdown: {
      label: 'Motivo de bloqueo',
      placeholder: 'Seleccionar motivo',
      labels: {
        LOW_SCORE: 'Score bajo',
        PAYMENT_OVERDUE: 'Pago vencido',
        OTHER: 'Otro',
      },
    },
  },
  unblockCreditLine: {
    single: {
      title: '¿Desbloquear línea de crédito?',
      subtitle: '{ pocName }  |  ID: { accountId }',
      description: 'El PDV podrá usar la línea de crédito con normalidad nuevamente.',
      buttons: { cancel: 'Volver', apply: 'Desbloquear línea de crédito' },
      toasts: {
        success:
          'La línea de crédito para { pocName } se está desbloqueando. Esto puede tomar algo de tiempo en procesarse.',
        error: 'Error al desbloquear la línea de crédito para { pocName }. Inténtalo de nuevo.',
      },
      alert:
        'Desbloquear una línea de crédito no es algo inmediato. Las solicitudes de cambio pasan por la pestaña En procesamiento antes de entrar en vigencia.',
    },
    multiple: {
      title: '¿Desbloquear líneas de crédito?',
      subtitle: 'Se seleccionaron { counter } PDV',
      description: 'Estos PDV podrán usar la línea de crédito con normalidad nuevamente.',
      buttons: { cancel: 'Volver', apply: 'Desbloquear líneas de crédito' },
      toasts: {
        success:
          'Se están desbloqueando las líneas de crédito para { counter } PDV. Esto puede tomar algo de tiempo en procesarse.',
        error:
          'Error al desbloquear las líneas de crédito para { counter } PDV. Inténtalo de nuevo.',
      },
      alert:
        'Desbloquear líneas de crédito no es algo inmediato. Las solicitudes de cambio pasan por la pestaña En procesamiento antes de entrar en vigencia.',
    },
  },
  cancelCreditLine: {
    single: {
      title: '¿Cancelar línea de crédito?',
      subtitle: '{ pocName }  |  ID: { accountId }',
      description:
        'Las condiciones de crédito se eliminarán y el PDV ya no tendrá una línea de crédito. Esta acción no se puede deshacer.',
      dropdown: { hint: 'Selecciona un motivo para cancelar esta línea de crédito.' },
      buttons: { cancel: 'Volver', apply: 'Cancelar línea de crédito' },
      toasts: {
        success:
          'La línea de crédito para { pocName } se está cancelando. Esto puede tomar algo de tiempo en procesarse.',
        error: 'Error al cancelar la línea de crédito para { pocName }. Inténtalo de nuevo.',
      },
    },
    multiple: {
      title: '¿Cancelar líneas de crédito?',
      subtitle: 'Se seleccionaron { counter } PDV',
      description:
        'Las condiciones de crédito se eliminarán y los PDV ya no tendrá una línea de crédito. Esta acción no se puede deshacer.',
      dropdown: { hint: 'Selecciona un motivo para cancelar estas líneas de crédito.' },
      buttons: { cancel: 'Volver', apply: 'Cancelar líneas de crédito' },
      toasts: {
        success:
          'Se están cancelando las líneas de crédito para { counter } PDV. Esto puede tomar algo de tiempo en procesarse.',
        error: 'Error al cancelar las líneas de crédito para { counter } PDV. Inténtalo de nuevo.',
      },
    },
    alert:
      'La cancelación puede tomar hasta 24 horas en completarse. Haz un seguimiento del progreso en la pestaña En procesamiento.',
    dropdown: {
      label: 'Motivo de cancelación',
      placeholder: 'Seleccionar motivo',
      labels: {
        INACTIVE_ACCOUNT: 'Cuenta inactiva',
        LATE_PAYMENT: 'Pago atrasado',
        LEGAL_REASONS: 'Motivos legales',
        LOW_SCORE: 'Score bajo',
        OTHER: 'Otro',
      },
    },
  },
  creditLineButtons: {
    cancel: 'Cancelar líneas de crédito',
    block: 'Bloquear líneas de crédito',
    unblock: 'Desbloquear líneas de crédito',
  },
  loading: {
    default: 'Te llevamos a la pantalla',
    optimizely: 'Preparando tu experiencia',
  },
  errors: {
    default: 'Algo salió mal.',
    generic: 'Algo salió mal. Intenta volver a cargar la página.',
    403: {
      title: 'Hmm... Acceso denegado!',
      description: 'Lamentablemente, no tienes permiso para acceder a esta página.',
      button: 'Volver',
    },
    404: {
      title: 'Hmm… Esta página parece haber desaparecido!',
      description: 'Esta página no se pudo encontrar o no existe.',
      button: 'Volver',
    },
    500: {
      title: 'Ooops! Parece que algo salió mal',
    },
    503: {
      title: 'Ooops! Parece que algo salió mal',
      description:
        'Estamos teniendo algunos problemas para cargar esta página. Inténtalo de nuevo.',
      info: '',
      button: 'Inténtalo de nuevo',
    },
    custom: {
      buttons: { back: 'Volver', reload: 'Volver a cargar la página' },
      'not-enabled': {
        both: {
          title: 'País y proveedor no habilitado',
          description:
            'No tienes acceso al país y proveedor seleccionado. Cambia tu selección o solicita acceso.',
        },
        country: {
          title: 'País no habilitado',
          description:
            'No tienes acceso al país seleccionado. Cambia tu selección o solicita acceso.',
        },
        vendor: {
          title: 'Proveedor no habilitado',
          description:
            'No tienes acceso al proveedor seleccionado. Cambia tu selección o solicita acceso.',
        },
      },
      'not-available': {
        title: 'País no disponible',
        description:
          'El país seleccionado aún no está disponible en esta aplicación. Selecciona otro.',
      },
      'something-went-wrong': {
        title: 'Algo salió mal',
        description: 'Tuvimos problemas para cargar esta página. Intenta volver a cargarla.',
      },
    },
  },
  source: {
    validations: {
      errors: {
        'error:term:zero': 'Debe ser de {value, plural, =1 {# día} other {# días}} como mínimo.',
        'error:term:small': 'Debe tener un máximo de {value, plural, =1 {# día} other {# días}}.',
        'error:term:fee:big': 'Debe ser del { value }% como máximo.',
        'error:term:fee:negative': 'Debe ser igual o mayor que el { value }%.',
        'error:limit:big': 'Máximo del { value }% del monto recomendado.',
        'error:limit:small': 'Debe ser igual o mayor que el { value }.',
      },
    },
  },
  approval: {
    buttons: {
      APPROVED: {
        single: 'Aprobar',
        multiple: 'Aprobar seleccionados',
        tooltip: 'Aprobar línea de crédito',
      },
      DENIED: {
        single: 'Denegar',
        multiple: 'Denegar seleccionados',
        tooltip: 'Denegar',
      },
    },
    toasts: {
      APPROVED: {
        single: 'La línea de crédito de { value } está aprobada.',
        multiple: '¡La línea de crédito para el { value } cliente está aprobada!',
      },
      DENIED: {
        single: 'La línea de crédito de { value } ha sido denegada.',
        multiple: '¡Se deniega la línea de crédito para { value } clientes!',
      },
    },
    errors: {
      412: {
        description:
          'Ya hay una actualización en proceso. Debes esperar hasta que finalice antes de realizar una nueva actualización.',
      },
    },
  },
  massApproval: {
    toasts: {
      APPROVED: 'Aprobando { value } recomendaciones. Recibirás una notificación al finalizar.',
      DENIED: 'Denegando { value } recomendaciones. Recibirás una notificación al finalizar.',
      recommendationSuccess: 'Condiciones de crédito actualizadas para { value } PDVs.',
      errorApproved:
        'Error al actualizar Condiciones de crédito para  { value } POCs. Intente nuevamente.',
      issueMessage:
        '{ value } de { total } POCs tuvieron sus condiciones de crédito actualizadas. { issue } presentaron errorer, intente nuevamente.',
    },
  },
  notifications: {
    analysis: {
      info: '{ fileName } está en análisis. Esto puede demorar un poco y se te notificará una vez que se complete.',
      warning:
        'Se ha completado el análisis de { fileName }. Verifica los resultados para actualizar los PDV y corregir errores.',
      success:
        'Se ha completado el análisis de { fileName }. Verifica los resultados para actualizar los PDV.',
      failed:
        'No fue posible completar el análisis del archivo { fileName }. Verifica los resultados e inténtalo de nuevo.',
    },
    update: {
      info: 'Actualizando { pocAmount } condiciones de crédito de los PDV. Esto puede demorar un poco y se te notificará una vez que se complete.',
      success:
        '{ pocAmount } condiciones de crédito de PDV de { fileName } actualizadas correctamente.',
      failed:
        'No se pueden actualizar las condiciones de crédito del archivo { fileName }. Verifica los resultados e inténtalo de nuevo.',
    },
    firstAction: 'Mostrar resultados',
  },
};

export default esAL;
