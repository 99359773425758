import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../../../hooks/useGetUserPreferences';
import { Input } from '../../../../Input/Input';
import { useFilter } from '../../Context/FilterContext';
import { AffixMapping, FilterOptionsProps } from './FilterOptions.types';
import { FilterOptionsItem } from './styles';

export const FilterOptions: React.FC<FilterOptionsProps> = ({ id, type, input, filtered }) => {
  const { formatMessage } = useIntl();

  const { currency, language } = useGetUserPreferences();

  const { onClearDate } = useFilter();

  const affix = useMemo(() => {
    if (!input.affix) return { prefix: '', sufix: '' };

    const { type: affixType, prefix: isPrefix, sufix: isSufix, label } = input.affix;

    const value = String(filtered.value[`${id}:${input.identifier}:${type}`]?.value ?? '');

    const affixMapping: AffixMapping = {
      currency: currency.config.symbol,
      percentage: '%',
      text: label ? formatMessage({ id: label }, { value }) : '',
    };

    const symbol = affixMapping[affixType];

    return { prefix: isPrefix ? symbol : '', sufix: isSufix ? symbol : '' };
  }, [input.affix, filtered.value]);

  const onChange = useCallback(
    ({ id, value, formatted }) => filtered.setter(id, { value, formatted }),
    [id, filtered.setter]
  );

  return (
    <React.Fragment>
      {(() => {
        switch (type) {
          case 'range-value':
            return (
              <FilterOptionsItem $align="center">
                <Input.Text
                  id={`${id}:${input.identifier}:${type}`}
                  format={input.format}
                  size="large"
                  width={'100%'}
                  label={formatMessage({ id: input.label })}
                  placeholder={formatMessage({ id: input.placeholder })}
                  prefix={affix.prefix}
                  suffix={affix.sufix}
                  value={
                    filtered.value[`${id}:${input.identifier}:${type}`]?.formatted?.simple ?? ''
                  }
                  onChange={onChange}
                />
              </FilterOptionsItem>
            );
          case 'range-date':
            return (
              <FilterOptionsItem $align="center">
                <Input.Date
                  id={`${id}:${input.identifier}:${type}`}
                  size="small"
                  mode="single"
                  width={'100%'}
                  customLocale={language.custom}
                  label={formatMessage({ id: input.label })}
                  placeholder={formatMessage({ id: input.placeholder })}
                  value={
                    (filtered.value[`${id}:${input.identifier}:${type}`]?.value as Date[]) ?? []
                  }
                  onClear={onClearDate}
                  onChange={onChange}
                />
              </FilterOptionsItem>
            );
          case 'checkbox':
            return (
              <FilterOptionsItem $align="flex-start">
                <Input.Checkbox
                  id={`${id}:${input.identifier}:${type}`}
                  size="medium"
                  name={input.identifier}
                  value={input.identifier}
                  label={formatMessage({ id: input.label })}
                  checked={Boolean(filtered.value[`${id}:${input.identifier}:${type}`]?.value)}
                  formatted={`tables.filters.inputs.${id}.labels.${input.identifier}`}
                  onChange={({ id, value, formatted }) => {
                    onChange({
                      id,
                      value: value,
                      formatted: { simple: input.value, completed: formatted.completed },
                    });
                  }}
                />
              </FilterOptionsItem>
            );
        }
      })()}
    </React.Fragment>
  );
};
