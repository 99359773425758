import { TypeToast } from 'admin-portal-shared-services';
import { FileStatus, NotificationsData } from '../../../interfaces';

export const notifications: NotificationsData = {
  [FileStatus.ANALYZING]: [
    {
      type: TypeToast.INFO,
      message: 'notifications.analysis.info',
      attributes: ['fileName:referenceName'],
    },
  ],
  [FileStatus.ANALYZED]: [
    {
      condition: { key: 'failed', type: 'HAS_NOT' },
      type: TypeToast.SUCCESS,
      message: 'notifications.analysis.success',
      attributes: ['fileName:referenceName'],
      actions: {
        name: 'notifications.firstAction',
        type: 'REDIRECT',
        attributes: {
          to: '/bees-credit-management/upload/progress/:id/:referenceName',
          params: ['id', 'referenceName'],
        },
      },
    },
    {
      condition: { key: 'failed', type: 'HAS' },
      type: TypeToast.WARNING,
      message: 'notifications.analysis.warning',
      attributes: ['fileName:referenceName'],
      actions: {
        name: 'notifications.firstAction',
        type: 'REDIRECT',
        attributes: {
          to: '/bees-credit-management/upload/progress/:id/:referenceName',
          params: ['id', 'referenceName'],
        },
      },
    },
  ],
  [FileStatus.PROCESSING]: [
    {
      type: TypeToast.INFO,
      message: 'notifications.update.info',
      attributes: ['pocAmount:validate'],
    },
  ],
  [FileStatus.DONE]: [
    {
      type: TypeToast.SUCCESS,
      message: 'notifications.update.success',
      attributes: ['pocAmount:processed', 'fileName:referenceName'],
    },
  ],
  [FileStatus.FAILED]: [
    {
      condition: { key: 'processing', type: 'HAS_NOT' },
      type: TypeToast.ERROR,
      message: 'notifications.analysis.failed',
      attributes: ['fileName:referenceName'],
      actions: {
        name: 'notifications.firstAction',
        type: 'REDIRECT',
        attributes: {
          to: '/bees-credit-management/upload/progress/:id/:referenceName',
          params: ['id', 'referenceName'],
        },
      },
    },
    {
      condition: { key: 'processing', type: 'HAS' },
      type: TypeToast.ERROR,
      message: 'notifications.update.failed',
      attributes: ['fileName:referenceName'],
      actions: {
        name: 'notifications.firstAction',
        type: 'REDIRECT',
        attributes: {
          to: '/bees-credit-management/upload/progress/:id/:referenceName',
          params: ['id', 'referenceName'],
        },
      },
    },
  ],
};
