import { DatePicker, TextLink } from '@hexa-ui/components';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { DateAttributes } from './Date.types';
import { DateInputContainer } from './styles';

export const DateInput: React.FC<DateAttributes> = ({
  id,
  size,
  mode,
  value,
  customLocale,
  width = '100%',
  height = 'auto',
  label = '',
  placeholder = '',
  optionalText = '',
  formatted = 'short',
  onChange,
  onClear,
  style,
  disabled,
}) => {
  const { formatMessage } = useIntl();
  const { datetime } = useGetUserPreferences();

  const onValueChange = useCallback(
    (value: Date[]) => {
      onChange({
        id,
        value,
        formatted: {
          simple: value[0].toISOString().split('T')[0],
          completed: datetime.format(value[0].toISOString(), formatted),
        },
      });
    },
    [id, datetime, formatted]
  );

  const onClearDate = useCallback(() => {
    onChange({ id, value: [], formatted: { simple: '', completed: '' } });

    if (onClear) onClear(id);
  }, []);

  const today = new Date();
  today.setHours(23, 59, 59, 999);

  return (
    <DateInputContainer>
      <DatePicker
        id={id}
        mode={mode}
        size={size}
        width={width}
        height={height}
        customLocale={customLocale}
        optionalText={optionalText}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onValueChange}
        style={style}
        disabled={disabled}
        toDate={today}
      />

      {value.length > 0 && (
        <TextLink size="small" colorOption="blue" hasUnderline={false} onClick={onClearDate}>
          {formatMessage({ id: 'tables.filters.buttons.clearDate' })}
        </TextLink>
      )}
    </DateInputContainer>
  );
};
