import { useEffect } from 'react';
import { UseWebSocket } from '../interfaces';
import { useNotification } from './useNotification';

export const useWebSocket = ({ path, data, params }: UseWebSocket) => {
  const { notify } = useNotification();

  const { REACT_APP_BFF_URL } = process.env;

  const getParams = (): string =>
    new URLSearchParams({ data: btoa(JSON.stringify(data)), ...params }).toString();

  useEffect(() => {
    const newSocket = new WebSocket(
      `wss://${window.location.hostname}${REACT_APP_BFF_URL}${path}/?${getParams()}`
    );

    newSocket.onopen = () => {
      console.info('WebSocket connection established');
    };

    newSocket.onclose = () => {
      console.info('WebSocket connection closed');
    };

    newSocket.onerror = () => {
      console.error('WebSocket connection error');
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      notify(data?.origin, data);
    };

    return () => {
      newSocket.close();
    };
  }, [path]);
};
