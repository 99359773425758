import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Tables } from '../../../interfaces';
import { AssessmentsTable } from '../../organisms/Tables';
import { CreditProvider } from '../Credit/Context/CreditContext';
import { Page } from '../Page/Page';
import { Content, RecommendationsContainer, Root } from './styles';
import { SummaryV2 } from './SummaryV2/SummaryV2';

export const RecommendationsPage: React.FC = () => {
  const { permissions } = useGetUserPreferences();

  if (!permissions.has('pages.recommendation')) {
    return (
      <Page.Error
        type="custom"
        title={'errors.custom.something-went-wrong.title'}
        description={'errors.custom.something-went-wrong.description'}
        button={{ has: true, text: 'errors.custom.buttons.reload' }}
      />
    );
  }

  return (
    <CreditProvider>
      <RecommendationsContainer data-testid="recommendations-page">
        <Root defaultValue={Tables.PENDING} value={Tables.PENDING}>
          <SummaryV2 />
          <Content key={`tabs-content-${Tables.PENDING}`} value={Tables.PENDING}>
            <AssessmentsTable type={Tables.PENDING} />
          </Content>
        </Root>
      </RecommendationsContainer>
    </CreditProvider>
  );
};
