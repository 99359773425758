import { Button, Paragraph } from '@hexa-ui/components';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { BlockCreditLineModal } from '../BlockCreditLineModal/BlockCreditLineModal';
import { CancelCreditLineModal } from '../CancelCreditLineModal/CancelCreditLineModal';
import { CreditLineProps } from './CreditLine.types';
import { CreditLineButtonsContainer } from './styles';

export const CreditLineButtons: React.FC<CreditLineProps> = ({ mode, button, selected }) => {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState({
    cancelCreditLine: false,
    blockCreditLine: false,
  });

  const handleOpenModal = (poc: any, modal: string) => {
    setOpenModal((state) => {
      return { ...state, [modal]: true };
    });
  };

  const closeAllModals = () => {
    setOpenModal({
      cancelCreditLine: false,
      blockCreditLine: false,
    });
  };
  const data: any = selected;
  return (
    <>
      <CreditLineButtonsContainer data-testid="credit-lines-buttons-container">
        <Button
          id="cancel-button"
          data-testid="cancel-button"
          size={button.size}
          variant="secondary"
          css={{}}
          onClick={() => handleOpenModal(selected, 'cancelCreditLine')}
        >
          <Paragraph size="small">
            {formatMessage({ id: `cancelCreditLine.triggerMultiSelectButton` })}
          </Paragraph>
        </Button>
        <Button
          id="block-button"
          data-testid="block-button"
          variant="secondary"
          size={button.size}
          css={{}}
          onClick={() => handleOpenModal(selected, 'blockCreditLine')}
        >
          <Paragraph size="small">
            {formatMessage({ id: `blockCredit.triggerMultiSelectButton` })}
          </Paragraph>
        </Button>
      </CreditLineButtonsContainer>
      {openModal.cancelCreditLine && (
        <CancelCreditLineModal
          data={data}
          data-testid="modal-credit-line-cancel"
          mode="multiple"
          onClose={closeAllModals}
        />
      )}
      {openModal.blockCreditLine && (
        <BlockCreditLineModal creditLineData={data} onClose={closeAllModals} mode="multiple" />
      )}
    </>
  );
};
