import { Button, Divider, Heading, Paragraph, Select } from '@hexa-ui/components';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRequester } from '../../../hooks/useRequester';
import { useToast } from '../../../hooks/useToast';
import { ManagementItem } from '../../../interfaces';
import { cancelCreditLine } from '../../../services';
import { AlertStyled } from '../BlockCreditLineModal/styles';
import { Modal } from '../Modal/Modal';
import { ModalContent } from './styles';

interface CancelCreditLineModalProps {
  data: Pick<ManagementItem, 'accountId' | 'pocName' | 'id'>;
  mode: 'single' | 'multiple';
  onClose: () => void;
}

enum CancelReasonsEnum {
  INACTIVE = 'inactive',
  LATE = 'late',
  LEGAL = 'legal',
  LOW = 'low',
  OTHER = 'other',
}

export const CancelCreditLineModal: React.FC<CancelCreditLineModalProps> = ({
  mode,
  data,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const isMultiple = mode === 'multiple';
  const [selectedReason, setSelectedReason] = useState();
  const { post } = useRequester();
  const { toast } = useToast();

  const onClear = () => {
    setSelectedReason(undefined);
    onClose();
  };

  const handleCancelCreditLine = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const response = await cancelCreditLine({
      api: { post },
      params: {
        id: data.id,
        cancelReason: selectedReason,
      },
    });

    if (!response?.success) {
      toast.error({
        message: 'cancelCreditLine.toasts.error',
        attributes: { pocName: data.pocName },
      });
      onClear();
      return;
    }

    toast.success({
      message: 'cancelCreditLine.toasts.success',
      attributes: { pocName: data.pocName },
    });
    onClear();
  };

  return (
    <Modal
      open={{ value: true, setter: () => {} }}
      portal={{ has: true, container: document.body }}
      title={
        <Heading size="H2">
          {formatMessage({ id: `cancelCreditLine.title` }, { plural: isMultiple ? 's' : '' })}
        </Heading>
      }
      onInteractOutside={onClear}
      onClose={onClear}
      headerHeight="4.5rem"
      actions={{
        buttons: {
          cancel: (
            <Button
              id="on-back"
              size="large"
              disabled={!selectedReason}
              onClick={handleCancelCreditLine}
              variant="destructive"
            >
              {formatMessage(
                { id: 'cancelCreditLine.buttons.action' },
                { plural: isMultiple ? 's' : '' }
              )}
            </Button>
          ),
          confirm: (
            <Button id="on-back" onClick={onClear} size="large" variant="secondary">
              {formatMessage({ id: 'cancelCreditLine.buttons.cancel' })}
            </Button>
          ),
        },
      }}
    >
      <ModalContent data-testid="modal-content">
        <Heading size="H4" css={{ marginTop: '8px', whiteSpace: 'pre' }}>
          {isMultiple
            ? formatMessage({ id: 'cancelCreditLine.subtitle' }, { counter: '9' })
            : `${data?.pocName}  |  ID: ${data.accountId}`}
        </Heading>

        <Paragraph size="basis">
          {formatMessage({ id: 'cancelCreditLine.description' }, { plural: isMultiple ? 's' : '' })}
        </Paragraph>

        <Divider />

        <div>
          <Select.Root
            label={formatMessage({ id: 'cancelCreditLine.select.label' })}
            hint={formatMessage(
              { id: 'cancelCreditLine.select.hint' },
              { plural: isMultiple ? 'these' : 'this' }
            )}
            data-testid="cancel-reason-select"
            onChange={(value) => setSelectedReason(value)}
            value={selectedReason}
            placeholder={formatMessage({ id: 'cancelCreditLine.select.placeholder' })}
            width="296px"
          >
            {Object.values(CancelReasonsEnum).map((reason) => (
              <Select.Option key={reason} value={reason}>
                {formatMessage({ id: `cancelCreditLine.reasons.${reason}` })}
              </Select.Option>
            ))}
          </Select.Root>
        </div>

        <AlertStyled type="info" message={formatMessage({ id: 'cancelCreditLine.alert' })} />
      </ModalContent>
    </Modal>
  );
};
