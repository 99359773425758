import React from 'react';
import { useIntl } from 'react-intl';
import { status as StatusMapping } from '../../../../constants/tables';
import { useFormatting } from '../../../../hooks/useFormatting';
import { ActionsTypes, Tables, TextDateParams, TextProps, TextType } from '../../../../interfaces';
import { StatusColumn } from '../../../atoms/StatusColumn';
import { Active, Files, PendingNotRecommended, PendingRecommended } from './Actions';
import { ActionsProps } from './Actions/Actions.types';
import { Blocked } from './Actions/Blocked/Blocked';
import { Container, CustomParagraph } from './styles';

export const Text: React.FC<TextProps<TextType>> = React.memo(({ type, params, hasEllipsis }) => {
  const { formatting } = useFormatting();
  const { value, sizing } = params;

  const { formatted } = formatting({
    type,
    value: value || '-',
    config: { datetimeType: (params as TextDateParams).type },
  });

  return (
    <CustomParagraph title={formatted.completed} $size={sizing.size} $hasellipsis={hasEllipsis}>
      {formatted.completed}
    </CustomParagraph>
  );
});

interface StatusProps {
  table: Tables;
  status: string;
}

export const Status: React.FC<StatusProps> = React.memo(({ table, status }) => {
  const { formatMessage } = useIntl();

  const { fontColor, borderColor, text } = Object.entries(StatusMapping[table].status).reduce(
    (acc, [key, value]) => {
      if (value.includes(status)) {
        const [fontColor, borderColor, text] = key.split(':');

        acc = { fontColor, borderColor, text };
      }

      return acc;
    },
    { fontColor: '', borderColor: '', text: '' }
  );

  return (
    <StatusColumn
      fontColor={fontColor}
      borderColor={borderColor}
      text={formatMessage({ id: text })}
    />
  );
});

export const Actions: React.FC<ActionsProps> = ({ type, data }) => {
  return (
    <Container data-testid="actions-container">
      {(() => {
        switch (type) {
          case ActionsTypes.PENDING_RECOMMENDED:
            return <PendingRecommended selected={data.selected} callbackFn={data.callbackFn} />;
          case ActionsTypes.PENDING_NOT_RECOMMENDED:
            return <PendingNotRecommended selected={data.selected} callbackFn={data.callbackFn} />;
          case ActionsTypes.FILES:
            return (
              <Files
                fileId={data.fileId}
                fileStatus={data.fileStatus}
                filesConfig={data.filesConfig}
              />
            );
          case ActionsTypes.ACTIVE:
            return <Active selected={data.selected} onOpenActionModals={data.onOpenActionModals} />;
          case ActionsTypes.BLOCKED:
            return (
              <Blocked selected={data.selected} onOpenActionModals={data.onOpenActionModals} />
            );
        }
      })()}
    </Container>
  );
};
