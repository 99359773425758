import { Notifications, NotificationsOrigin } from '../../interfaces';
import { notifications as fileNotifications } from './files/files.notifications';
import { notifications as massApprovalNotifications } from './mass-approval/mass-approval.notifications';

export const maxLength = 20;

export const notifications: Notifications = {
  [NotificationsOrigin.BU]: fileNotifications,
  [NotificationsOrigin.ASYNC_UPDATE]: massApprovalNotifications,
};
