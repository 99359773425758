import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  gap: 12px;
  padding: 16px 14px;
  border-radius: 8px;
  height: 48px;
  p {
    font-size: 16px;
    white-space: nowrap;
  }

  &:hover {
    background: rgba(20, 20, 20, 0.04);
  }
`;

export const DisabledWrapper = styled.div<{ $disabled: boolean; $isopen: boolean }>`
  svg {
    path: {
      ${({ $disabled, $isopen }) => {
        if ($disabled) {
          return css`
            fill: rgba(20, 20, 20, 0.08);
          `;
        }

        if ($isopen) {
          return css`
            fill: rgba(20, 20, 20, 0.16);
          `;
        }

        return css`
          fill: rgba(20, 20, 20, 1);
        `;
      }}
    }
  }

  button {
    background-color: rgba(20, 20, 20, 0.08);
  }
`;
