import { Alert, Button, Divider, Heading, Paragraph, Radio } from '@hexa-ui/components';
import { Check2 } from '@hexa-ui/icons';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { keys } from '../../../constants';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Status } from '../../../interfaces';
import SegmentService from '../../../services/segment';
import { ApproveRecommendationProps } from './ApproveRecommendation.types';
import {
  ButtonContainer,
  Close,
  Container,
  ModalContent,
  RadioContainer,
  Root,
  TextContainer,
} from './styles';
import { useGetSources } from '../../../hooks/useGetSources';
import { set } from 'msw/lib/types/context';

export const ApproveRecommendation: React.FC<ApproveRecommendationProps> = ({
  mode,
  data,
  trigger,
  onApprove,
}) => {
  const { formatMessage } = useIntl();

  const { selected, toggles } = useGetUserPreferences();

  const { onboardingRule } = useGetSources();

  const [onboardingByPass, setOnboardingByPass] = useState<boolean>(undefined);

  const onRadio = (value: string) => setOnboardingByPass(value === 'doNotRequest');

  const onClear = () => setOnboardingByPass(undefined);

  useEffect(() => {
    if (!onboardingRule) {
      setOnboardingByPass(true);
    }
  }, []);

  const onClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onClear();
  };

  const eventName = onboardingByPass
    ? 'Credit Management Bypass Onboarding'
    : 'Credit Management Request Onboarding';

  const buttonClicked = (eventName: string) => {
    SegmentService.paymentsButtonClicked(
      'Yes, approve',
      'Approve Recommendation Button',
      selected?.vendorId,
      eventName,
      'CREDIT_MANAGEMENT_EDIT_RECOMMENDATION_MODAL'
    );
  };

  return (
    <Container data-testid="approve-container" onClick={(e) => e.stopPropagation()}>
      <Root
        contentCss={{
          maxWidth: '720px',
          minHeight: '350px',
        }}
        trigger={trigger}
        onClose={onClose}
        onInteractOutside={onClear}
        actions={
          <ButtonContainer>
            <Close>
              <Button id="on-back" size="medium" variant="secondary" onClick={onClose}>
                {formatMessage({ id: 'approveRecommendation.buttons.back' })}
              </Button>
            </Close>

            <Close>
              <Button
                id="on-approve"
                leading={mode === 'single'}
                icon={Check2}
                size="medium"
                variant="primary"
                onClick={() => {
                  onApprove(Status.APPROVED, onboardingByPass);
                  buttonClicked(eventName);
                }}
                disabled={onboardingRule ? onboardingByPass === undefined : false}
              >
                {formatMessage({ id: 'approveRecommendation.buttons.approve' })}
              </Button>
            </Close>
          </ButtonContainer>
        }
      >
        <ModalContent data-testid="modal-content" onClick={(e) => e.stopPropagation()}>
          <Heading size="H2" css={{ fontSize: '32px' }}>
            {toggles.has(keys.cm_has_async_approval) && mode === 'multiple'
              ? formatMessage({ id: 'approveRecommendationV2.title' }, { counter: data.counter })
              : formatMessage({ id: 'approveRecommendation.title' })}
          </Heading>
          <Paragraph size="basis">
            {toggles.has(keys.cm_has_async_approval) && mode === 'multiple'
              ? formatMessage(
                  { id: 'approveRecommendationV2.description' },
                  { counter: data.counter }
                )
              : formatMessage({ id: `approveRecommendation.description.${mode}` })}
          </Paragraph>

          {onboardingRule && (
            <>
              <Divider orientation="horizontal" />
              {!toggles.has(keys.cm_has_async_approval) ? (
                <Heading size="H4">
                  {mode === 'single'
                    ? `${data.pocName} - ${data.accountId}`
                    : formatMessage(
                        { id: 'approveRecommendation.counter' },
                        { counter: data.counter }
                      )}
                </Heading>
              ) : mode === 'single' ? (
                <Heading size="H4">
                  {data.pocName} - {data.accountId}
                </Heading>
              ) : null}
              <TextContainer data-testid="text-container">
                <Heading size="H5">
                  {formatMessage({ id: 'approveRecommendation.info.title' })}
                </Heading>

                <Paragraph size="small" style={{ color: '#757575' }}>
                  {formatMessage({ id: 'approveRecommendation.info.description' })}
                </Paragraph>
              </TextContainer>

              <Radio.Root onValueChange={onRadio}>
                <RadioContainer>
                  <Radio.Item
                    id="request"
                    value="request"
                    label={formatMessage({ id: 'approveRecommendation.radios.request' })}
                  />

                  <Radio.Item
                    id="doNotRequest"
                    value="doNotRequest"
                    label={formatMessage({ id: 'approveRecommendation.radios.doNotRequest' })}
                  />
                </RadioContainer>
              </Radio.Root>

              {onboardingByPass && (
                <Alert
                  type="warning"
                  message={formatMessage({ id: 'approveRecommendation.warning' })}
                  css={{ width: '100%' }}
                />
              )}
            </>
          )}
        </ModalContent>
      </Root>
    </Container>
  );
};
