import { Chip, Tooltip } from '@hexa-ui/components';
import { useEffect, useRef, useState } from 'react';
import { ChipTooltipProps } from './ChipTooltip.types';
import { ChipRoot } from './styles';

export const ChipTooltip: React.FC<ChipTooltipProps> = ({ description, onDelete }) => {
  const chipRef = useRef<HTMLDivElement>(null);

  const [hasTooltip, setHasTooltip] = useState<boolean>(false);

  const formatted = (): string => `${description.key}: ${description.value}`;

  useEffect(() => {
    if (chipRef.current) {
      setHasTooltip(
        chipRef.current.offsetWidth < chipRef.current.scrollWidth || description.value.includes(',')
      );
    }
  }, [description]);

  return (
    <ChipRoot>
      {hasTooltip ? (
        <Tooltip text={description.tooltip} placement="bottom">
          <Chip.Label ref={chipRef} css={{ 'max-width': '300px' }}>
            {formatted()}
          </Chip.Label>
        </Tooltip>
      ) : (
        <Chip.Label ref={chipRef} css={{ 'max-width': '300px' }}>
          {formatted()}
        </Chip.Label>
      )}

      <Chip.DeleteIcon onDelete={onDelete} />
    </ChipRoot>
  );
};
