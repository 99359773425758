import { PayloadAction } from '@reduxjs/toolkit';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useGetUserPreferences } from '../../../../../hooks/useGetUserPreferences';
import { useSerializer } from '../../../../../hooks/useSerializer';
import SegmentService from '../../../../../services/segment';
import { useTableStore } from '../../../../../store/hooks/tablesHook';
import {
  ApplyFn,
  ClearFnType,
  ContextProps,
  FilterChips,
  FilterContextProps,
  FilterFnType,
  FilterPayload,
  FilterState,
} from './FilterContext.types';

const FilterContext = createContext<FilterContextProps | null>(null);

export const FilterProvider: React.FC<ContextProps> = ({ table, children }) => {
  const reducer = useCallback(
    (state: FilterState, { type, payload }: PayloadAction<FilterPayload | null>) => {
      if (type === 'SETTER' && payload.state.value && payload.state.value !== '00') {
        return { ...state, [payload.id]: payload.state };
      }

      if (type === 'SETTER' && (!payload.state.value || payload.state.value === '00')) {
        const { [payload.id]: _, ...newState } = state;

        return newState;
      }

      if (type == 'SET_ALL') {
        return { ...payload.state };
      }

      if (type === 'DELETE') {
        const deletedState = Object.keys(state)
          .filter((identifier) => !identifier.startsWith(payload.id))
          .reduce((obj, key) => {
            obj[key] = state[key];

            return obj;
          }, {});

        payload.callback?.(payload.id, deletedState);
        return deletedState;
      }

      return {};
    },
    []
  );

  const [state, dispatch] = useReducer(reducer, {});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [chips, setChips] = useState<FilterChips>({});
  const { selected } = useGetUserPreferences();
  const { serializer } = useSerializer();
  const { filters: storedFilters, search: storedSearch } = useTableStore(table);
  const [previousState, setPreviousState] = useState({});
  const [totalCounter, setTotalCounter] = useState(0);

  const onFilterRef = useRef<FilterFnType | null>(null);
  const onClearRef = useRef<ClearFnType | null>(null);

  const totalInputsFilled = Object.keys(state).reduce((acc, key) => {
    const [category, _, element] = key.split(':');
    const value = state[key]?.value;

    if (!acc[category]) acc[category] = 0;

    if (element !== 'checkbox' && value && value !== '00') {
      acc[category] = 1;
    } else {
      acc[category] += 1;
    }

    return acc;
  }, {});

  const countInputsByCategory = (state: FilterState) => {
    const array = Object.keys(state).map((value) => value.split(':')[0]);
    const filters = new Set(array).size;

    return filters;
  };

  const onFiltering = useCallback((id, state) => {
    dispatch({ type: 'SETTER', payload: { id, state } });
  }, []);

  const onClear = useCallback(
    (filter?: boolean) => {
      if (onFilterRef.current && Object.keys(chips).length > 0 && !filter) {
        onFilterRef.current('', storedSearch.value);
      }

      dispatch({ type: 'CLEAR', payload: null });

      setChips({});
      storedFilters.setter({});
      setTotalCounter(countInputsByCategory({}));

      if (onClearRef.current) onClearRef.current(1);
    },
    [chips, storedSearch]
  );

  const onClearDate = useCallback((id) => {
    dispatch({ type: 'DELETE', payload: { id, state: null } });
  }, []);

  const onDeleteCallback = (id: string, state: FilterState) => {
    setChips((prev) => {
      const { [id]: _, ...newChips } = prev;

      return newChips;
    });

    if (onFilterRef.current && onClearRef.current) {
      onApply({ state, onFilterFn: onFilterRef.current, onClearFn: onClearRef.current });
    }
  };

  const onDelete = (id) => {
    dispatch({ type: 'DELETE', payload: { id, state: null, callback: onDeleteCallback } });
  };

  const onCancel = useCallback(() => {
    dispatch({ type: 'SET_ALL', payload: { state: previousState } });
    setIsOpen(false);
  }, [previousState]);

  const onApply = ({ state, onFilterFn, onClearFn }: ApplyFn) => {
    onFilterRef.current = onFilterFn;
    onClearRef.current = onClearFn;

    const { chips, filters } = serializer(table, state);

    setChips(chips);
    onFilterFn(filters);

    storedFilters.setter(state);
    setTotalCounter(countInputsByCategory(state));
    setIsOpen(false);
  };

  const onOpen = useCallback(
    (value: boolean) => {
      SegmentService.paymentsButtonClicked(
        'Filter',
        'Filter',
        selected?.vendorId,
        'Credit Management Filter',
        `CREDIT_MANAGEMENT_${table.toUpperCase()}_TAB`
      );

      if (value) setPreviousState(state);
      else dispatch({ type: 'SET_ALL', payload: { state: previousState } });
      setIsOpen(value);
    },
    [state, previousState]
  );

  const value = useMemo(
    () => ({
      table,
      open: { value: isOpen, setter: onOpen },
      chips,
      counter: totalCounter,
      filtered: { counter: totalInputsFilled, value: state as FilterState, setter: onFiltering },
      onClear,
      onClearDate,
      onDelete,
      onCancel,
      onApply,
    }),
    [isOpen, chips, state, table, onClear]
  );

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useFilter = () => useContext(FilterContext);
