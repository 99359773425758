import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  row-gap: 24px;
  max-width: 624px;
`;
