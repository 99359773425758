import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterState } from '../../components/atoms/Table/Filter/Context/FilterContext.types';
import { FilterStore } from '../../interfaces';

const initialState: FilterStore = {
  filter: { pending: {}, inprogress: {}, completed: {} },
  search: { pending: '', inprogress: '', completed: '' },
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setPendingFilterSelected: (state: FilterStore, action: PayloadAction<FilterState>) => {
      state.filter.pending = action.payload;
    },
    setInProgressFilterSelected: (state: FilterStore, action: PayloadAction<FilterState>) => {
      state.filter.inprogress = action.payload;
    },
    setCompletedFilterSelected: (state: FilterStore, action: PayloadAction<FilterState>) => {
      state.filter.completed = action.payload;
    },
    setPendingSearchValue: (state: FilterStore, action: PayloadAction<string>) => {
      state.search.pending = action.payload;
    },
    setInProgressSearchValue: (state: FilterStore, action: PayloadAction<string>) => {
      state.search.inprogress = action.payload;
    },
    setCompletedSearchValue: (state: FilterStore, action: PayloadAction<string>) => {
      state.search.completed = action.payload;
    },
  },
});

export const {
  setPendingFilterSelected,
  setInProgressFilterSelected,
  setCompletedFilterSelected,
  setCompletedSearchValue,
  setPendingSearchValue,
  setInProgressSearchValue,
} = filterSlice.actions;

export default filterSlice.reducer;
